import React, { useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import { SliderComponentsData } from '../data/SliderComponentsData';
import { Link } from 'react-router-dom';
import { allServicesApi, fetchastrocallData, fetchastrochatData, fetchvideoData, sliderApi } from '../data/AllapiData';
import { options, Chatastrologer, talkndslider, testimonialsld, sliderrigtside } from '../data/Owlcarouseloptions';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { astroserviceApi, HealingserviceApi, SliderHerobanner, SliderHerobannerMobile, TestimonialsApi } from '../AllApi/AllApi';

const Home = () => {

  //chat api==============
  const [chatdata,setchatdata] = useState();
  const [chatbaseurl,setchatbaseurl] = useState();
  const [slider,setSlider]=useState()
  console.log(slider)
  async function fetchSlider(){
     const res= await sliderApi()
     if(res?.success==true){
       setSlider(res?.data)
     }
   }
  //  useEffect(()=>{
  //    fetchSlider()
  //  },[])
   
  useEffect(()=>{
    const chatFetchdata = async() =>{
  try {
    const res = await fetchastrochatData();
      if(res?.success===true){
      setchatdata(res?.data);
      setchatbaseurl(res?.url);
    }
    
  } catch (error) {
    console.log(error);
  }
    }
    
    chatFetchdata();
  },[]);

  // end chat api

 // call api============== start
 const [calldata,setcalldata] = useState();
 const [callbaseurl,setcallbaseurl] = useState();
  useEffect(() =>{
    const callfetchData = async() =>{
      try {
        const res = await fetchastrocallData();
        
          if(res?.success===true){
          setcalldata(res?.data);
          setcallbaseurl(res?.url);
        }
        
      } catch (error) {
        console.log(error);
      }
        }
    
        callfetchData();
      },[]);

  // end call api

  // video api start
const [videoData, setvideoData] =  useState();
const [videobaseurl,setvideobaseurl] = useState();
useEffect(()=>{
  const videofetechdata = async()=>{
  try {
    const res = await fetchvideoData();
    if(res?.success===true){
      setvideoData(res?.data);
      setvideobaseurl(res?.url);
    }
  } catch (error) {
    console.log(error);
  }
}
videofetechdata();
},[]);
  // video api end


// astrologer services api start
const [astroserviceData, setastroserviceData] = useState();
const [astroserviceDataUrl, setastroserviceDataUrl] = useState();
useEffect(() =>{
  const serviceAstrodata =  async () =>{
    try {
      const res = await allServicesApi ();
  
      if(res?.success===true){
        setastroserviceData(res?.data);
        setastroserviceDataUrl(res?.url);
      }
    } catch (error) {
      console.log(error);
    }
  }
  serviceAstrodata();
},[])
// astrologer services api end

 
// Heroslider  api start
const [SliderHeroData, setSliderHeroData] = useState();
const [SliderHeroDataUrl, setSliderHerorl] = useState();
useEffect(() =>{
  const SliderHero =  async () =>{
    try {
      const res = await SliderHerobanner ();
  
      if(res?.success===true){
        setSliderHeroData(res?.data);
        setSliderHerorl(res?.url);
      }
    } catch (error) {
      console.log(error);
    }
  }
  SliderHero();
},[])
// Heroslider  api end


// Heroslider  api start
const [SliderHeroMobileData, setSliderHeroMobileData] = useState();
const [SliderHeroMobileDataUrl, setSliderHeroMobilerl] = useState();
useEffect(() =>{
  const SliderHeroMobile =  async () =>{
    try {
      const res = await SliderHerobannerMobile ();
  
      if(res?.success===true){
        setSliderHeroMobileData(res?.data);
        setSliderHeroMobilerl(res?.url);
      }
    } catch (error) {
      console.log(error);
    }
  }
  SliderHeroMobile();
},[])
// Heroslider  api end


// Testimonials  api start
const [TestimonialsData, setTestimonialsData] = useState();
const [TestimonialsDataUrl, setTestimonialsurl] = useState();
useEffect(() =>{
  const TestimonialsDataslider =  async () =>{
    try {
      const res = await TestimonialsApi ();
  console.table(res?.data);
      if(res?.success===true){
        setTestimonialsData(res?.data);
        setTestimonialsurl(res?.url);
      }
    } catch (error) {
      console.log(error);
    }
  }
  TestimonialsDataslider();
},[])
// Testimonials  api end


// Healingservice  api start
const [HealingserviceData, setHealingserviceData] = useState();
const [HealingserviceDataUrl, setHealingserviceurl] = useState();
useEffect(() =>{
  const HealingserviceDataslider =  async () =>{
    try {
      const res = await HealingserviceApi ();
  console.table(res?.data);
      if(res?.success===true){
        setHealingserviceData(res?.data);
        setHealingserviceurl(res?.url);
      }
    } catch (error) {
      console.log(error);
    }
  }
  HealingserviceDataslider();
},[])
// Healingservice  api end


// astrologyservice  api start
const [astrologyserviceData, setastrologyserviceData] = useState();
const [astrologyserviceDataUrl, setastrologyserviceurl] = useState();
useEffect(() =>{
  const astrologyserviceDataslider =  async () =>{
    try {
      const res = await astroserviceApi ();
  console.table(res?.data);
      if(res?.success===true){
        setastrologyserviceData(res?.data);
        setastrologyserviceurl(res?.url);
      }
    } catch (error) {
      console.log(error);
    }
  }
  astrologyserviceDataslider();
},[])
// astrologyservice  api end

// Kundli slider========================
const filterKundlislider = SliderComponentsData.filter(item => item.slidername == 'bestindiakundli');
// Desktop slider========================
// Mobile slider========================
const filterheroslidermobile = SliderComponentsData.filter(item => item.slidername == 'heroslidermobile');
// Astrologer india best========================
const filterheroAstrologerbset = SliderComponentsData.filter(item => item.bestindiaAstroname == 'bestindiaAstrologer');

//stpe easy ========================
const filterstepeasy = SliderComponentsData.filter(item => item.stepprocess == 'easystepprofour');


//VERIFIED ASTRO  ========================
const verifiedfilter = SliderComponentsData.filter(item => item.verfiednm == 'verifiesastro');

return (
<>
   {/* ========= */}
 <Header/>
    {/* ====== */}
{/* wrapper start */}
<div className="main_wrapper">
{/* container start */}
<div className="container">
{/* row start */}
<div className="row">
{/* ====================col start left side 9 col====================  */}
<div className="col-lg-9 col-md-9 col-sm-12 col-12">
{/* ==================India's Best Astrologers slider mobile view section start============ */}
<section className="kundli_astro_mobile_slider_sec">
<div className="kundli_astro_main_div_mobile_sld">
<OwlCarousel className="owl-theme" {...options}>
{/* item div start */}
{ filterKundlislider.map((item, index) => (
<div className="item" key={index}>
<Link to={item.link}>
<div className="kundli_astrokundli_main_div">
<div className="kundli_astro_img_box">
<img src={item.image} alt="" className='img-fluid'/>
</div>
<h4>{item.kundliname}</h4>
</div>
</Link>
</div>
)) }
{/* item div end */}

</OwlCarousel>
</div>
</section>
{/* ==================India's Best Astrologers slider mobile view  section start============ */}

{/* ============================== hero slider desktop sectin start ====================================== */}
<section className="heroslider_section_desktop">
  <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel" data-bs-interval={3000}>
    <div className="carousel-indicators">
      <button type="button" data-bs-target="#carouselExampleControls" data-bs-slide-to={0} className="active" aria-current="true" aria-label="Slide 0" /> 
      <button type="button" data-bs-target="#carouselExampleControls" data-bs-slide-to={1} className aria-current="true" aria-label="Slide 1" /> 
      <button type="button" data-bs-target="#carouselExampleControls" data-bs-slide-to={2} className aria-current="true" aria-label="Slide 2" /> 
      <button type="button" data-bs-target="#carouselExampleControls" data-bs-slide-to={3} className aria-current="true" aria-label="Slide 3" /> 
    </div>
    <div className="carousel-inner">
      {SliderHeroData?.map((item, index) =>(
        <div className="carousel-item active" key={index}>
        <Link to="/"><img src={`${SliderHeroDataUrl}${item?.image}`} className="d-block w-100" alt="..." /></Link>
      </div>
      ))}
    
    </div>
  </div>
</section>

{/* ============================== hero slider desktop sectin end ====================================== */}


{/* ============================== hero slider mobile sectin start ====================================== */}
<section className="heroslider_section_mobile">
  <div id="carouselExampleControl" className="carousel slide" data-bs-ride="carousel" data-bs-interval={3000}>
    <div className="carousel-indicators">
      <button type="button" data-bs-target="#carouselExampleControl" data-bs-slide-to={0} className="active" aria-current="true" aria-label="Slide 0" /> 
      <button type="button" data-bs-target="#carouselExampleControl" data-bs-slide-to={1} className aria-current="true" aria-label="Slide 1" /> 
      <button type="button" data-bs-target="#carouselExampleControl" data-bs-slide-to={2} className aria-current="true" aria-label="Slide 2" /> 
      <button type="button" data-bs-target="#carouselExampleControl" data-bs-slide-to={3} className aria-current="true" aria-label="Slide 3" /> 
    </div>
    <div className="carousel-inner">
     {SliderHeroMobileData?.map((item, index) =>(
        <div className="carousel-item active" key={index}>
        <Link to="/"><img src={`${SliderHeroMobileDataUrl}${item?.image}`} className="d-block w-100" alt="..." /></Link>
      </div>
      ))}
    
    </div>
  </div>
</section>

{/* ============================== hero slider mobile sectin end ====================================== */}

{/* ============================== India's Best Astrologers sectin start ====================================== */}
<section className="india_best_astrologer_section">
  <div className="container">
    {/* india_astrologer title start  */}
    <div className="india_bestastrologer_title">
      <h2>India's Best Astrologers</h2>
    </div>
    {/* india_astrologer title end */}
    <div className="row">
    {/* col start */}

{ filterheroAstrologerbset.map((item, index) => {
  return(
    <div className="col-lg-2 col-md-4 col-sm-6 col-6" key={index}>
    <Link to={item?.link}>
    <div className="india_bestastrologer_main_div">
      <div className="india_best_astrologer_imgbox">
      <img src={item?.image} alt="" className='img-fluid'/>
      </div>
      <h2>{item?.astrologerbest}</h2>
    </div>
    </Link>
  </div>
  )
}) }
    {/* col end */}
    </div>
  </div>
</section>
{/* ============================== India's Best Astrologers sectin end ====================================== */}


{/* ============================== Chat with Astrologers section start ====================================== */}
<section className="chat_with_astrologer_section">
  <div className="container">
    {/* Chat title with astrolger start */}
    <div class="chat_with_astrologer_title">
          <h2>Chat With Astrologer</h2>
          <Link to="/chat">View All</Link>
      </div>
    {/* Chat title with astrolger end */}
    <div className="row">
      {/* col start */}
      <div className="col-lg-12 col-md-12 col-sm-12col-12">
       <div className="chat_withastrologer_main_div">
       <OwlCarousel className="owl-theme" {...Chatastrologer}>

{/* item div start */}

{chatdata?.map((ele) => {
const truncateText = (text, maxLength) => {
  return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
};
  return(

<div className="item" key={ele?.id}>
<Link to={`/astrologersDetails/${ele?.astrologer_id}`}>
<div className="chat_withastrologer_media_main_div">
<div className="d-flex align-items-center">
  <div className="flex-shrink-0">
   <div className="chatastro_img_box">
   {ele.profile==''?  <img src="https://healfate.com/astro_docs/No-image-found.jpg" alt="..." className='img-fluid' /> :
  <img src={`${chatbaseurl}${ele.profile}`} alt="..." className='img-fluid' /> 
  
  }
   </div>
  </div>
  <div className="flex-grow-1 ms-2">
    <div className="chat_astroname_div">
      <h3>{ele.name}</h3>
      {/* chat rating */}
    <div className="chatastro_rating">
  <i className="fa fa-star cheked" />
  <i className="fa fa-star cheked" />
  <i className="fa fa-star cheked" />
  <i className="fa fa-star cheked" />
  <i className="fa fa-star-o" />
</div>
{/* chat rating */}
<h4>{truncateText (ele?.expertise,15)}</h4>
<h5 class="chatastro_price"><span>{ele?.rate}</span> <strong>{ele?.disc_rate}</strong> </h5>

    </div>
  </div>
</div>
{/* over lay best offer  */}
<div class="chatastro_bestoffer"> Best Offer</div>
{/* over lay best offer  */}
</div>
</Link>
</div>
  )
})}

{/* item div end */}

</OwlCarousel>
       </div>
      </div>
      {/* col end */}
    </div>
  </div>
</section>
{/* ============================== Chat with Astrologers section end ====================================== */}


{/* ============================== call with Astrologers section start ====================================== */}
<section className="chat_with_astrologer_section">
  <div className="container">
    {/* Chat title with astrolger start */}
    <div class="chat_with_astrologer_title">
          <h2>Call With Astrologer</h2>
          <Link to="/call">View All</Link>
      </div>
    {/* Chat title with astrolger end */}
    <div className="row">
      {/* col start */}
      <div className="col-lg-12 col-md-12 col-sm-12col-12">
       <div className="chat_withastrologer_main_div">
       <OwlCarousel className="owl-theme" {...Chatastrologer}>

{/* item div start */}

{calldata?.map((ele) => {
  const truncatecallText = (text, maxLength) => {
    return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
  };

  return(

<div className="item" key={ele?.id}>
<Link to={`/astrologersDetails/${ele?.astrologer_id}`}>
<div className="chat_withastrologer_media_main_div">
<div className="d-flex align-items-center">
  <div className="flex-shrink-0">
   <div className="chatastro_img_box">
   {ele?.profile==''?  <img src="https://healfate.com/astro_docs/No-image-found.jpg" alt="..." className='img-fluid' /> :
  <img src={`${callbaseurl}${ele?.profile}`} alt="..." className='img-fluid' /> 
  
  }
   </div>
  </div>
  <div className="flex-grow-1 ms-2">
    <div className="chat_astroname_div">
      <h3>{ele?.name}</h3>
      {/* chat rating */}
    <div className="chatastro_rating">
  <i className="fa fa-star cheked" />
  <i className="fa fa-star cheked" />
  <i className="fa fa-star cheked" />
  <i className="fa fa-star cheked" />
  <i className="fa fa-star-o" />
</div>
{/* chat rating */}
<h4>{truncatecallText(ele?.expertise, 20)}</h4>
<h5 class="chatastro_price"><span>{ele?.rate}</span> <strong>{ele?.disc_rate}</strong> </h5>

    </div>
  </div>
</div>
{/* over lay best offer  */}
<div class="chatastro_bestoffer"> Best Offer</div>
{/* over lay best offer  */}
</div>
</Link>
</div>
  )
})}

{/* item div end */}

</OwlCarousel>
       </div>
      </div>
      {/* col end */}
    </div>
  </div>
</section>
{/* ============================== call with Astrologers section end ====================================== */}

{/* ============================== video with Astrologers section start ====================================== */}
<section className="chat_with_astrologer_section">
  <div className="container">
    {/* Chat title with astrolger start */}
    <div class="chat_with_astrologer_title">
          <h2>Video Call With Astrologer</h2>
          <Link to="/video">View All</Link>
      </div>
    {/* Chat title with astrolger end */}
    <div className="row">
      {/* col start */}
      <div className="col-lg-12 col-md-12 col-sm-12col-12">
       <div className="chat_withastrologer_main_div">
       <OwlCarousel className="owl-theme" {...Chatastrologer}>

{/* item div start */}

{videoData?.map((ele) => {
   const truncatecallText = (text, maxLength) => {
    return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
  };
  return(

<div className="item" key={ele?.id}>
<Link to={`/astrologersDetails/${ele?.astrologer_id}`}>
<div className="chat_withastrologer_media_main_div">
<div className="d-flex align-items-center">
  <div className="flex-shrink-0">
   <div className="chatastro_img_box">
   {ele?.profile==''?  <img src="https://healfate.com/astro_docs/No-image-found.jpg" alt="..." className='img-fluid' /> :
  <img src={`${videobaseurl}${ele?.profile}`} alt="..." className='img-fluid' /> 
  
  }
   </div>
  </div>
  <div className="flex-grow-1 ms-2">
    <div className="chat_astroname_div">
      <h3>{ele?.name}</h3>
      {/* chat rating */}
    <div className="chatastro_rating">
  <i className="fa fa-star cheked" />
  <i className="fa fa-star cheked" />
  <i className="fa fa-star cheked" />
  <i className="fa fa-star cheked" />
  <i className="fa fa-star-o" />
</div>
{/* chat rating */}
<h4>{truncatecallText(ele?.expertise,20)}</h4>
<h5 class="chatastro_price"><span>{ele?.rate}</span> <strong>{ele?.disc_rate}</strong> </h5>

    </div>
  </div>
</div>
{/* over lay best offer  */}
<div class="chatastro_bestoffer"> Best Offer</div>
{/* over lay best offer  */}
</div>
</Link>
</div>
  )
})}

{/* item div end */}

</OwlCarousel>
       </div>
      </div>
      {/* col end */}
    </div>
  </div>
</section>
{/* =================== video with Astrologers section end ================== */}

{/* ====================  Astrologers services section end ============ */}
<section className="astrologer_services_section">
  {/* Healing container start */}
  <div className="container">
    {/* Healing service title */}
    <div class="astrologer_services_title">
        <h2>Astrology Services</h2>
    </div>
    {/*  */}
    <div className="row">
{/* col start  */}

{astrologyserviceData?.map((item, index) =>{
  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
  };
  return(
<>
<div className="col-lg-3 col-md-6 col-sm-12 col-12">
  <Link to={`/services/${item?.name.replace(/\s+/g, '-')}`}>
  <div className="astroservice_main_div" key={index}>
    <div className="astroservice_img_box">
      <img src={`${astrologyserviceDataUrl}${item?.icon}`} alt="" className='img-fluid'/>
    </div>
    <h3>{item?.name}</h3>
    <p>{truncateText (item?.description,70)} <span>Read More</span></p>
  </div>
  </Link>
</div>
</>
  )
})}

{/* col end */}
    </div>
  </div>
  {/* astro container end */}

  {/* Healing container start */}
  <div className="container">
    {/* Healing service title */}
    <div class="astrologer_services_title">
        <h2>Healing Services</h2>
    </div>
    {/*  */}
    <div className="row">
{/* col start  */}

{HealingserviceData?.map((item, index) =>{
  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
  };
  return(
<>
<div className="col-lg-3 col-md-6 col-sm-12 col-12">
  <Link to={`/services/${item?.name.replace(/\s+/g, '-')}`}>
  <div className="astroservice_main_div" key={index}>
    <div className="astroservice_img_box">
      <img src={`${HealingserviceDataUrl}${item?.icon}`} alt="" className='img-fluid'/>
    </div>
    <h3>{item?.name}</h3>
    <p>{truncateText (item?.description,70)} <span>Read More</span></p>
  </div>
  </Link>
</div>
</>
  )
})}

{/* col end */}
    </div>
  </div>
  {/* Healing container end */}


</section>
{/* ==============================  Astrologers services section end ====================================== */}

{/*===========Easy 4 Steps Process start ==============================  */}
<section className="easy_four_section">
  <div className="container">
    {/*  */}
    <div class="four_step_title">
      <h2>Easy 4 Steps Process</h2>
  </div>
    {/*  */}
    <div className="row">
     {/* col start  */}
     <div className="col-lg-12 col-md-12 col-sm-12 col-12">
     <div className="four_stp_flx_main_div">


  {/*  */}
  {filterstepeasy?.map((item, index) =>{
    return(
     
      <div className="stp_box_m">
         <Link to={item?.link}>
    <div className="content_stp">
      <div className="icon_stp">
        <img src={item?.image} alt />
      </div>
      <h2>{item.step_name}</h2>
      <p>{item.stepdesc}</p>
      <div className="step_over">
        <b>{item?.stepcount}</b>
      </div> 
      <div className="step_over2">
        <b>{item?.stepcount}</b>
      </div>
    </div>
    </Link>
  </div> 
 
    )
  })}
  
  {/*  */}
</div>

     </div>
     {/* col end  */}
    </div>
  </div>
</section>

{/*===========Easy 4 Steps Process end ==============================  */}

{/*===========Easy 4 Steps Process end ==============================  */}
<section className="talkndheal_section">
  <div className="container">
    {/* talknd heal title */}
    <div className="talknd_heal_title">
    <h2>TalkndHeal</h2>
    <p>With a gigantic pool of 20000+ Vedic astrologers, Tarot readers, Numerologists, Vastu experts and more, Heal Fate lets you connect with these experts over call and chat.</p>
    </div>
    {/* talknd heal title */}
  </div>
  {/* row start  */}
  <div className="row">
    {/* col start */}
    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
       <div className="talknd_slidermain_div">
       <OwlCarousel className="owl-theme" {...talkndslider}>
{/* item div start */}

{astroserviceData?.map((item, index) =>{
   const truncateText = (text, maxLength) => {
    return text?.length > maxLength ? text?.slice(0, maxLength) + "..." : text;
  };
  return(
    
<div className="item" key={index}>
  {/* item row start */}
  <div className="row">
    {/* item col start  */}
    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
      <div className="talknd_heal_img_div_left">
        <img src={`${chatbaseurl}${item?.image}`} alt="" className='img-fluid'/>
      </div>
    </div>
    {/* item col end */}

    {/* item col start  */}
    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
      <div className="tlknd_txt_div_right">
        <h3>{item?.name}</h3>
      <p>{truncateText (item?.description)}  <Link to={`/services/${item?.name.replace(/\s+/g, '-')}`} class="readMore">Read More</Link></p>
      
      </div>
    </div>
    {/* item col end */}
  </div>
  {/* item row end */}
</div>
  )
})}

{/* item div end */}

</OwlCarousel>
       </div>
    </div>
    {/* col end */}
  </div>
  {/* row end */}
</section>
{/*===========Easy 4 Steps Process end ==============================  */}

{/*===========testimonials section start ==============================  */}
<section className="testimonial_section">
  <div className="container">
    {/* testimonial title */}
 <div className="testimonilas_title">
  <h2>Testimonials</h2>
 </div>
    {/* end  */}
    {/* row start */}
    <div className="row">
      {/* col start */}
      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
      <div className="testimonials_slider_maindiv">
      <OwlCarousel className="owl-theme" {...testimonialsld}>
{/* item div start */}

{TestimonialsData?.map((item, index) =>{
  return(

<div className="item" key={index}>
  <div className="testimonials_txtitem_main_box">
   <div className="testimonials_img_b">
    <img src="../images/testiicon01.png" alt="" className='img-fluid'/>
   </div>
   <h3>{item?.name}</h3>
   <p>{item?.message}</p>
  </div>
  {/* overlay quote */}
  <div class="fa fa-quote-right overlay_quote"></div>
  {/* end */}
</div>
  )
})}

{/* item div end */}

</OwlCarousel>
      </div>
      </div>
      {/* col end */}
    </div>
    {/* row end */}
  </div>
</section>
{/*===========testimonials section end ==============================  */}

{/*===========Find Best Astrologers / Experts section start ===========  */}
<section className="experts_atro_sec">
  <div className="container-fluid">
    <div className="experts_title">
      <h2>Find Best Astrologers / Experts</h2>
    </div>
    <div className="row">
      {/* col start */}
      <div className="col-lg-4 col-md-6 col-sm-12 col-12">
        <div className="experts_astro_main_div">
          <h3> <img src="../images/001.png" alt className="img-fluid" /> Consult Astrologers</h3>
          <div className="experts_list_div" style={{height: 200}}>
            <ul>
              <li><Link to="/chat">Chat with Astrologer</Link></li>
              <li><Link to="/call">Call with Astrologer</Link></li>
              <li><Link to="/video">Video call with Astrologers</Link></li>
            </ul>
          </div>
        </div>
      </div>
      {/* col end */}
      {/* col start */}
      <div className="col-lg-4 col-md-6 col-sm-12 col-12">
        <div className="experts_astro_main_div">
          <h3> <img src="../images/004.png" alt className="img-fluid" />Consult Experts</h3>
          <div className="experts_list_div">
            <ul>
              <li><Link to="/services/Tarot">Tarot</Link></li>
              <li><Link to="/services/Psychologist">Psychologist</Link></li>
              <li><Link to="/services/Pranayama">Pranayama</Link></li>
              <li><Link to="/services/Yoga-mudras">Yoga mudras</Link></li>
              <li><Link to="/services/Dietician">Dietician</Link></li>
              <li><Link to="/services/Sun-Rays-Healing">Sun Rays Healing</Link></li>
              <li><Link to="/services/Sound-and-Words-Healing">Sound and Words Healing</Link></li>
              <li><Link to="/services/Vastu">Vastu</Link></li>
              <li><Link to="/services/Magnet-Healing">Magnet Healing</Link></li>
              <li><Link to="/services/Nature-Healing">Nature Healing</Link></li>
              <li><Link to="/services/Palmistry">Palmistry</Link></li>
              <li><Link to="/services/Acupressure-Healing">Acupressure Healing</Link></li>
              <li><Link to="/services/Metal-Healing">Metal Healing</Link></li>
              <li><Link to="/services/Crystal-Healing">Crystal Healing</Link></li>
              <li><Link to="/services/KP-System">KP System</Link></li>
              <li><Link to="/services/Lal-Kitab">Lal Kitab</Link></li>
              <li><Link to="/services/Vedic">Vedic</Link></li>
              <li><Link to="/services/Astrology">Astrology</Link></li>
            </ul>
          </div>
        </div>
      </div>
      {/* col end */}
      {/* col start */}
      <div className="col-lg-4 col-md-6 col-sm-12 col-12">
        <div className="experts_astro_main_div">
          <h3> <img src="../images/002.png" alt className="img-fluid" /> Languages</h3>
          <div className="experts_list_div">
            <ul>
              <li><Link to="/chat/Assamese">Astrologers in Assamese</Link></li>
              <li><Link to="/chat/Bengali">Astrologers in Bengali</Link></li>
              <li><Link to="/chat/English">Astrologers in English</Link></li>
              <li><Link to="/chat/Gujarati">Astrologers in Gujarati</Link></li>
              <li><Link to="/chat/Hindi">Astrologers in Hindi</Link></li>
              <li><Link to="/chat/Kannada">Astrologers in Kannada</Link></li>
              <li><Link to="/chat/Kashmiri">Astrologers in Kashmiri</Link></li>
              <li><Link to="/chat/Malayalam">Astrologers in Malayalam</Link></li>
              <li><Link to="/chat/Marathi">Astrologers in Marathi</Link></li>
              <li><Link to="/chat/Marwadi">Astrologers in Marwadi</Link></li>
              <li><Link to="/chat/Odia">Astrologers in Odia</Link></li>
              <li><Link to="/chat/Punjabi">Astrologers in Punjabi</Link></li>
              <li><Link to="/chat/Tamil">Astrologers in Tamil</Link></li>
              <li><Link to="/chat/Telugu">Astrologers in Telugu</Link></li>
            </ul>
          </div>
        </div>
      </div>
     
    </div>
  </div>
</section>

{/*===========Find Best Astrologers / Experts section end ============  */}

</div>
{/* ===================col end left side 9 col========================  */}

{/* ===================col start right side 3 col========================  */}
<div className="col-lg-3 col-md-3 col-sm-12 col-12">
  <div className="right_side_main_div_sticky">

    {/* slider start */}
    <div class="sidebar_main_div_sld">
  <OwlCarousel className="owl-theme" {...sliderrigtside}>
{/* item div start */}
<div className="item">
<div class="sideadd_img_box">
        <Link to=""><img src="../images/sld01.png" alt=""/></Link> 
    </div> 
</div>
{/* item div end */}
</OwlCarousel>
</div>
  {/* slider end */}

  {/* slider start */}
  <div class="sidebar_main_div_sld2">
  <OwlCarousel className="owl-theme" {...sliderrigtside}>
{/* item div start */}
<div className="item">
<div class="sideadd_img_box">
        <Link to=""><img src="../images/gplay.png" alt=""/></Link> 
    </div> 
</div>
{/* item div end */}

{/* item div start */}
<div className="item">
<div class="sideadd_img_box">
        <Link to=""><img src="../images/apple01.png" alt=""/></Link> 
    </div> 
</div>
{/* item div end */}
</OwlCarousel>
</div>
  {/* slider end */}

  
  {/* app div */}
  <div className="app_download">
    <h2>TalkndHeal on Mobile</h2>
    <a href>Download Now</a>
  </div>
  {/*  */}
  {/* left icons start */}
  <div className="left_icons_so">
    <a target="_blank" href=""><img src="../images/android.png" alt="Android App" /></a>
    <a target="_blank" href=""><img src="../images/apple.png" alt="iOS App" /></a>
    <a target="_blank" href="https://www.facebook.com/talkndhealofficial/" className="fa fa-facebook"><img src="../images/fb.png" alt="Facebook" /></a>
    <a target="_blank" href="https://www.instagram.com/talkndhealdotcom/"><img src="../images/insta.png" alt="Instagram" /></a>
    <a target="_blank" href="https://x.com/TalkndHeal"><img src="../images/twitter.png" alt="Twitter" /></a>
    <a target="_blank" href="https://www.linkedin.com/company/talk-nd-heal/"><img src="../images/linkedin.png" alt="LinkedIn" /></a>
    <a target="_blank" href="https://www.youtube.com/@TalkndHeal"><img src="../images/yt.png" alt="Youtube" /></a>
  </div>
  {/* left icons end */}
  <div className="right_side_img_div">
    <a href=""><img src="../images/gene01.png" alt="" className='img-fluid'/></a>
  </div>
  {/* news letter div start  */}
  <div className="newlatter_div_left">
    <h2>TalkndHeal  Newsletter</h2>
    <form action>
      <div className="input-group input-group-sm mb-2">
        <input type="text" className="form-control" placeholder="Enter Your Email Address" aria-label="Recipient's username" aria-describedby="button-addon2" />
        <button className="btn" type="button" id="button-addon2">SUBSCRIBE</button>
      </div>
    </form>
  </div>
  {/* news letter div end  */}
  <div className="right_side_img_div">
    <a href=""><img src="../images/gene02.png" alt="" className='img-fluid'/></a>
  </div>



  </div>
</div>
{/* ===================col end right side 3 col========================  */}
</div>
{/* row end */}
</div>
{/* container end */}


{/* ===================== Verified Astrologers & Secure Payments start===================== */}
<section className="verified_astrologer_section">
  <div className="container">
    <div className="astrologer_title verified_title phone_heading_none">
      <h2>Verified Astrologers &amp; Secure Payments</h2>
    </div>
    <div className="row ">
    <div className="col-lg-12 col-md-12 col-12">
  <div className="main_div_flx_box">
    {verifiedfilter?.map((item, index) => (
      <Link to={item?.link} key={index}>
        <div className="sec-assurace-box">
          <div className='verified_img_box'>
            <img src={item.image} className='img-fluid' alt={item.verifiedti_name} />
          </div>
          <h3>{item.verifiedti_name}</h3>
          <p className="phone_txt_none">{item.verifiedddesc}</p>
        </div>
      </Link>
    ))}
  </div>
</div>

    </div>
  </div>
</section>
{/* ===================== Verified Astrologers & Secure Payments end===================== */}


</div>
{/* wrapper end */}
  {/* footer componets start */}
  <Footer/>
      {/*footer componets end */}
</>
)
}

export default Home;
