import React, { useEffect, useState } from 'react';
import { privacyPolicyApi } from '../data/AllapiData';
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../CssFiles/Refundcancellation.css';


const Privacypolicy = () => {
  const [terms, setTerms] = useState('');
  const [error, setError] = useState(null);

  async function fetchPrivacy() {
    const res = await privacyPolicyApi();
    console.log(res); // Log the response
    if (res.error) {
      setError(res.error);
    } else {
      setTerms(res.data); // Assuming the terms are in res.data
    }
  }

  useEffect(() => {
    fetchPrivacy();
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
<>
<Header/>
<div className='privacy_policy_txt' style={{ paddingLeft: '10px', paddingRight: '10px' }}>
    
      <div  className='privacy_policy_txt' dangerouslySetInnerHTML={{ __html: terms }} />
      {console.log(terms)}
  
    </div>
    <Footer/>
</>
  );
};

export default Privacypolicy;
