import React from 'react'
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../CssFiles/contactus.css';

const Contactus = () => {
  return (
    <>
     {/* ========= */}
  <Header/>
    {/* ====== */}
    {/* ===================contact us page section start ============== */}
<section className="contact_us_page_section">
  <div className="container">
    {/* contact us page title start */}
    <div className="contact_page_title_div">
      <h2>Get In Touch With <span>Talk ND Heal</span></h2>
    </div>
    {/* contact us page title end */}
    <div className="row">
      {/* col start  */}
      <div className="col-lg-5 col-md-6 col-sm-12 col-12">
        {/* main div contact page start*/}
        <div className="main_div_contactus_page">
          {/* contact us flex div main start*/}
          <div className="contact_us_flx_div_main">
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                <i className="bx bxs-phone-call" />
              </div>
              <div className="flex-grow-1 ms-3">
                <div className="contact_page_num_div">
                  <b>Mobile No.:</b>
                  <a href="tel:+91 9876543210">  +91 9876543210</a> 
                </div>
              </div>
            </div>
          </div>
          {/* contact us flex div main end*/}
          {/* contact us flex div main start*/}
          <div className="contact_us_flx_div_main">
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                <i className="bx bx-mail-send" />
              </div>
              <div className="flex-grow-1 ms-3">
                <div className="contact_page_num_div">
                  <b>E-Mail ID:</b>
                  <a href="mailto:helpdesk@talkndheal.com">helpdesk@talkndheal.com</a>
                </div>
              </div>
            </div>
          </div>
          {/* contact us flex div main end*/}
          {/* contact us flex div main start*/}
          <div className="contact_us_flx_div_main">
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                <i className="bx bxs-map-pin" />
              </div>
              <div className="flex-grow-1 ms-3">
                <div className="contact_page_num_div">
                  <b>Address:</b>
                  <p>308, Tower B, Pioneer Urban Square, Golf Course Extn., Sector - 62, Gurugram, Haryana - 122002 (INDIA)</p>
                </div>
              </div>
            </div>
          </div>
          {/* contact us flex div main end*/}
        </div>
        {/* main div contact page end*/}
      </div>
      {/* col end */}
      {/* col start */}
      <div className="col-lg-7 col-md-6 col-sm-12 col-12">
        <div className="contact_page_form">
          <form method="POST" className="contact-form" autoComplete="off">
            {/* row nested start */}
            <div className="row">
              {/* col nested start */}
              <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="input-group mb-3">
                  <span className="input-group-text fa fa-user" />
                  <input type="text" name="name" className="form-control" placeholder="Name*" required minLength={3} oninput="this.value = this.value.replace(/[^a-zA-Z]/g, '').replace(/(\..*?)\..*/g, '$1')" />
                </div>
              </div>
              {/* col nested end */}
              {/* col nested start */}
              <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="input-group mb-3">
                  <span className="input-group-text fa fa-user" />
                  <input type="text" name="last" className="form-control" placeholder="Surname*" required minLength={3} oninput="this.value = this.value.replace(/[^a-zA-Z]/g, '').replace(/(\..*?)\..*/g, '$1')" />
                </div>
              </div>
              {/* col nested end */}
              {/* col nested start */}
              <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="input-group mb-3">
                  <span className="input-group-text fa fa-envelope" />
                  <input type="email" className="form-control" name="email" placeholder="Email Id*" minLength={11} required />
                </div>
              </div>
              {/* col nested end */}

              {/* col nested start */}
              <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="input-group mb-3">
                  <span className="input-group-text fa fa-phone" />
                  <input type="tel" className="form-control"  minLength={13} maxLength={13} name="phone" placeholder="Mobile No." required />
                </div>

              </div>
              {/* col nested end */}

                 {/* col nested start */}
                 <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="input-group mb-3">
                  <span className="input-group-text fa fa-pencil-square-o" />
                  <input type="text" className="form-control"  placeholder="Subject" required />
                </div>

              </div>
              {/* col nested end */}

              {/* col nested start */}
              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="input-group">
                  <span className="input-group-text fa fa-commenting" />
                  <textarea name="message" className="form-control" placeholder="Message*"/>
                </div>
              </div>
              {/* col nested end */}

              {/* col nested start */}
              <div className="col-lg-12 col-md-12 col-sm-12 col-12 mt-2">
               <div className="conatct_page_btn_div">
               <button type='submit' className='btn btn-md'>Send Message</button>
               </div>
              </div>
            </div>
            {/* col nested end */}
          </form></div>
        {/* row nested end */}
      </div>
    </div>
    {/* col end */}
    <br />
    <div className="col-lg-12">
      <p><iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d448193.9510431336!2d76.76356386805335!3d28.644287350488735!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfd5b347eb62d%3A0x37205b715389640!2sDelhi!5e0!3m2!1sen!2sin!4v1699532155520!5m2!1sen!2sin" height="300px" width="100%" style={{border: 0}} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
</p>
    </div>
  </div>
</section>
{/* ===================contact us page section end ============== */}
<Footer/>
    </>
  )
}

export default Contactus
