import React, { useContext, useEffect, useState } from "react";
import Header2 from "../components/Header2";
import { Link, useNavigate, useParams } from "react-router-dom";
import Allpopups from "../data/Allpopups";
import Footer from "../components/Footer";
import MyContext from "../Context/MyContext";
import Cookies from "js-cookie";
import { decrypt } from "../Utils/Utils";
import {
  alreadyReviewApi,
  BlockAstrologers,
  chatAssistantRequestSend,
  fetchAllSimillarAstrologers,
  fetchAstrologerReviewApi,
  fetchChatStatusChange,
  FollowUnfollowApi,
  getSingleAstrologerApi,
  postAstrologerReviewApi,
  unblockAstrologers,
  waitListRequestSend,
  weeklyScheduleGetApi,
} from "../AllApi/AllApi";
import GiftPage from "../components/GiftPage/GiftPage";
import { toast, ToastContainer } from "react-toastify";
import { SlUserFollow } from "react-icons/sl";
import { FaChevronRight, FaGift } from "react-icons/fa";
import {
  cookieDomain,
  decryptData,
  isAuthenticated,
} from "../components/Url/Url";
import "../CssFiles/astrologerDt.css";
import { Similarastrologerdetails } from "../data/Owlcarouseloptions";
import OwlCarousel from "react-owl-carousel";
import { BiPhoneCall, BiSupport } from "react-icons/bi";
import Login from "../components/Login/Login";
import TalkSuggetionModal from "./TalkSuggetionModal";



const AstrologersDetails = () => {
  //use navigateback to get the current location

  const { updateIntake } = useContext(MyContext);
  const { updateData } = useContext(MyContext);
  const [reviewUrl, setReviewUrl] = useState();
  const { id } = useParams();
  const mainIId = id;

  const navigate = useNavigate();

  const [singleAstrologerDataShow, setSingleAstrologerDataShow] = useState({});
  const [talkModalOpen, setTalkModalOpen] = useState(false);
  console.warn(singleAstrologerDataShow);

  // Chat request work start
  const [url, setUrl] = useState();

  // Audio
  const [authenticatedData,setAuthenticatedData]=useState();
  console.warn(authenticatedData)
  const [showDownloadSuggetion, setShowDownloadSuggetion] = useState(false);
  const [showCallDownloadSuggetion, setShowCallDownloadSuggetion] =
    useState(false);
  const [showVideoDownloadSuggetion, setShowVideoDownloadSuggetion] =
    useState(false);

  const userid = decrypt(Cookies?.get("user"));
  const [showEmergencyVideoCallSuggetion, setShowEmergencyVideoCallSuggetion] =
    useState(false);
  const [blockedStatus, setBlockedStatus] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [weeklySheduleDetails, setWeeklySheduleDetails] = useState([]);

  const [showEmergencyAudioCallSuggetion, setShowEmergencyAudioCallSuggetion] =
    useState(false);
  const [intervalId, setIntervalId] = useState(null);

  const [idSave, setIdSave] = useState(0);
  const [refressStatus, setStatusRefress] = useState({});

  async function fetchStatusApi(id) {
    const res = await fetchChatStatusChange(id);
    if (res.success == true) {
      setStatusRefress(res);
      updateData(res);
    }
  }
  const handleEmergencyCall = () => {
    // Handle emergency call logic here
  };

  function toggleDownloadSuggetion(objids) {
    setShowDownloadSuggetion(!showDownloadSuggetion);
  }
  function toggleEmergencyAudioCallSuggetion(objids) {
    setShowEmergencyAudioCallSuggetion(!showEmergencyAudioCallSuggetion);
  }
  function toggleEmergencyVideoCallSuggetion() {
    setShowEmergencyVideoCallSuggetion(!showEmergencyVideoCallSuggetion);
  }
  function toggleCallDownloadSuggetion() {
    setShowCallDownloadSuggetion(!showCallDownloadSuggetion);
  }

  function toggleVideoDownloadSuggetion(objids) {
    setShowVideoDownloadSuggetion(!showVideoDownloadSuggetion);
  }
  
  function toggleTalkModelOpen(data) {
    if (isAuthenticated) {
      setAuthenticatedData(data)
      if(data!=null)
      {setTalkModalOpen(prevState => !prevState);}
    } else {
      setShowLoginModal(prevState => !prevState);
    }
  }
  
  // progress bar logic
  // Calculate the width based on the review score for each star rating
  let reviewPercentage1 = singleAstrologerDataShow?.[1] * 10;
  let reviewPercentage2 = singleAstrologerDataShow?.[2] * 10;
  let reviewPercentage3 = singleAstrologerDataShow?.[3] * 10;
  let reviewPercentage4 = singleAstrologerDataShow?.[4] * 10;
  let reviewPercentage5 = singleAstrologerDataShow?.[5] * 10;
  // progress bar logic
  const contextData = useContext(MyContext);

  const userNameDetails = contextData?.userDetails?.data?.name;
  const userwalletDetails = contextData?.userDetails?.data?.wallet;
  const userDetails = contextData?.userDetails;
  const userProfileUrl = userDetails?.profile_url;
  const user_profile_image = userDetails?.data?.profile;

  const userloginStatus =
    contextData?.userDetails?.loginstatus == "undefined"
      ? 0
      : contextData?.userDetails?.loginstatus;

  const defaultIntakeValue = contextData?.intakeChange;

  const [changeIntake, setChangeIntake] = useState(0);

  const [showLoginModal, setShowLoginModal] = useState(false);
  useEffect(() => {
    setChangeIntake(defaultIntakeValue); // Log changeIntake whenever it changes
  }, [defaultIntakeValue]);

  function toggleSendGift() {}
  function toggleLoginModal() {
    setShowLoginModal(!showLoginModal);
  }

  const [showIntakeModal, setShowIntakeModal] = useState(false);

  function toggleIntakeModal() {
    setShowIntakeModal(!showIntakeModal);
  }
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const [showChatRequestModal, setShowChatRequestModal] = useState(false);
  const [showEmergencyCallRequestModal, setShowEmergencyCallRequestModal] =
    useState(false);
  const [lessBalanceModel, setLessBalanceModel] = useState(false);
  const [lessEmergencyBalanceModel, setLessEmergencyBalanceModel] =
    useState(false);
  const [openLessMinBalanceModel, setLessMinBalanceModel] = useState(false);
  const [isRunning, setIsRunning] = useState(true);

  const openEmergencyCallRequestModal = () => {
    setShowEmergencyCallRequestModal(!showEmergencyCallRequestModal);
  };
  const closeEmergencyCallRequestModal = () => {
    setShowEmergencyCallRequestModal(false);
  };

  const renderStars = (rating) => {
    const roundedRating = Math.round(parseFloat(rating));
    const halfStar = parseFloat(rating) !== roundedRating;
    const stars = [];

    for (let i = 1; i <= 5; i++) {
      if (i <= roundedRating) {
        stars.push(<i key={i} className="fa fa-star" />);
      } else if (halfStar && i === roundedRating + 1) {
        stars.push(<i key={i} className="fa fa-star-half-o " />);
      } else {
        stars.push(
          <i
            key={i}
            className="fa fa-star text-transparent"
            style={{ color: "#CCC" }}
          />
        );
      }
    }

    return stars;
  };

  const toggleModal = (id) => {
    setShowModal(!showModal);
  };

  // All Functionality varta

  // wallet fetch

  const [ratingReview, setRatingReview] = useState();

  const [followingUpdate, setFollowingUpdate] = useState(false);
  const [blockOpen, setBlockOpen] = useState(false);
  const [unfollowid, setUnfollowid] = useState(0);
  const [refressPage, setRefressPage] = useState(false);
  // refress status api

  function funBlockOpenModal(id) {
    setUnfollowid(id);
    setBlockOpen(true);
  }
  function funBlockCloseModal() {
    setBlockOpen(false);
  }

  const [followOpenModal, setFollowOpenModal] = useState(false);

  function toggleUnfollowModal() {
    setFollowOpenModal(!followOpenModal);
  }

  async function FollowUnfollowCall(idpass) {
    const astroid = idpass;
    const userid = decrypt(Cookies?.get("user"));

    const dataSend = { userid, astroid };

    const res = await FollowUnfollowApi(dataSend);

    if (res.success == true) {
      const resp = toast.success(res.message);
      if (resp) {
        setIsButtonDisabled(true);
        setRefressPage(!refressPage);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    }
  }

  const [requestUniqueData, setRequestUniqueData] = useState(
    () => parseInt(Cookies.get("requestUniqueData")) || 0
  );
  const [requestChatData, setRequestChatData] = useState(
    () => parseInt(Cookies.get("requestChatData")) || 0
  );
  const [requestMainId, setRequestMainId] = useState(
    () => parseInt(Cookies.get("requestMainId")) || 0
  );
  const [requestAstroidData, setRequestAstroidData] = useState(
    () => parseInt(Cookies.get("requestAstroidData")) || 0
  );
  const [requestAstroAmountData, setRequestAstroAmountData] = useState(
    () => parseInt(Cookies.get("requestAstroAmountData")) || 0
  );

  useEffect(() => {
    // Sync state with cookies in case they change outside of this component
    setRequestUniqueData(parseInt(Cookies.get("requestUniqueData")) || 0);
    setRequestChatData(parseInt(Cookies.get("requestChatData")) || 0);
    setRequestMainId(parseInt(Cookies.get("requestMainId")) || 0);
    setRequestAstroidData(parseInt(Cookies.get("requestAstroidData")) || 0);
    setRequestAstroAmountData(
      parseInt(Cookies.get("requestAstroAmountData")) || 0
    );
  }, []);

  async function fetchReviewApi(id) {
    const res = await fetchAstrologerReviewApi(id);

    if (res.success == true) setReviewUrl(res?.url);
    setRatingReview(res?.data);
  }

  const [reviewData, setReviewData] = useState({});

  function reviewChange(e) {
    setReviewData((reviewData) => ({
      ...reviewData,
      [e.target.name]: e.target.value,
    }));
  }

  async function postReviewApi(event, unfollowid) {
    if (event && typeof event.preventDefault === "function") {
      event.preventDefault();
    }
    const useridh = userid;
    const astroId = unfollowid;

    const dataSend = { useridh, astroId, ...reviewData };

    const res = await postAstrologerReviewApi(dataSend);
    if (res.success == true) {
      setIsButtonDisabled(true);
      setReviewData(res);

      toast.success(res.message);
      const fet = fetchReviewApi(id);
      if (fet) {
        const fetchRevies = fetchReviewApi(astroId);
        if (fetchRevies) {
          // setReviewData('')
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      }
    } else {
      toast.error(res.message);
    }
  }

  useEffect(() => {
    fetchReviewApi(singleAstrologerDataShow?.id);
  }, [singleAstrologerDataShow]);

  useEffect(() => {}, [refressPage]);

  async function singleAstrologerShowApi(id) {
    const userid = decrypt(Cookies?.get("user"));
    const reSend = { id, userid };

    const res = await getSingleAstrologerApi(reSend);
    if (res.success == true) {
      setUrl(res?.url);
      setSingleAstrologerDataShow(res.data);
      setIdSave(res.data.id);
      alreadyReviewfetch(res.data.id);
      weeklyScheduleGet(res.data.id);
      if (refressStatus.newstatus != 1) {
        // loadingDeclined(res.data.id);
      }
      if (singleAstrologerDataShow != null) {
        setLoading(false);
      }
    }
  }
  useEffect(() => {
    singleAstrologerShowApi(id);
  }, []);
  useEffect(() => {
    singleAstrologerShowApi(id);
  }, [followingUpdate, blockedStatus]);

  const [blockMessageData, setBlockMessageData] = useState({});
  function changeMessageData(e) {
    setBlockMessageData({
      ...blockMessageData,
      [e.target.name]: e.target.value,
    });
  }

  async function postUnblockAstrologer(unfollowid) {
    const useridh = userid;
    const astroId = unfollowid;

    const dataSend = { useridh, astroId };
    const res = await unblockAstrologers(dataSend);
    if (res.success == true) {
      singleAstrologerShowApi(id);
      toast.success(res.message);
    }
  }
  async function postBlockApi(event, unfollowid) {
    if (event && typeof event.preventDefault === "function") {
      event.preventDefault();
    }
    const useridh = userid;
    const astroId = unfollowid;

    const dataSend = { useridh, astroId, ...blockMessageData };

    const res = await BlockAstrologers(dataSend);

    if (res.success == true) {
      setBlockedStatus(true);
      funBlockCloseModal(false);
    }
    const jj = setTimeout(() => {
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    });
    if (jj) {
      toast.success(res.message);
    }
  }

  async function alreadyReviewfetch(id) {
    const userid = decrypt(Cookies?.get("user"));

    const astroid = id;

    const dataSend = {
      userid,
      astroid,
    };

    const res = await alreadyReviewApi(dataSend);
    if (res.success == true) {
      setReviewData(res.data);
    }
  }

  useEffect(() => {
    singleAstrologerShowApi();
  }, []);

  async function startChatWithAssistant(id) {
    const userId = decryptData(Cookies?.get("user"));
    const astroId = parseFloat(id);
    const token = isAuthenticated;
    const reqSend = { userId, astroId, token };

    const res = await chatAssistantRequestSend(reqSend);

    if (res.success == true) {
      toast.success(res?.message);

      const chatId = res?.chatid;

      if (chatId && astroId) {
        navigate(`/chatassistant`);
      }
    } else {
      toast.error(res?.message);
    }
  }

  useEffect(() => {
    const savingId = requestUniqueData;
    fetchStatusApi(savingId);

    const intervalId = setInterval(() => {
      fetchStatusApi(savingId); // Pass the savingId to fetchStatusApi
    }, 3000);

    return () => {
      clearInterval(intervalId); // Cleanup on unmount
    };
  }, [requestUniqueData]); // Include the id in the dependency array

  // refress status api
  function openLessBalanceModel() {
    setLessBalanceModel(true);
  }
  function closeBalanceModel() {
    setLessBalanceModel(false);
  }
  function openLessEmergencyBalanceModel() {
    setLessEmergencyBalanceModel(true);
  }
  function closeEmegencyBalanceModel() {
    setLessEmergencyBalanceModel(false);
  }

  async function weeklyScheduleGet(astroid) {
    const res = await weeklyScheduleGetApi(astroid);
    if (res.success == true) {
      setWeeklySheduleDetails(res?.data);
    }
  }

  const [loading, setLoading] = useState(true);

  // Simulate an asynchronous operation

  const [showRead, setShowRead] = useState(true);

  function getShowRead() {
    setShowRead(!showRead);
  }

  const [showChatWaiting, setShowChatWaiting] = useState(false);
  const [busyRequest, setBusyRequest] = useState();
  const handleChatWaitingClose = () => setShowChatWaiting(false);

  async function BusyRequestSendChat(data, serviceType) {
    const astroId = data?.id;
    const userId = decrypt(Cookies?.get("user"));
    const rqstSend = "1";
    const service = serviceType;
    const reqSend = { astroId, userId, rqstSend, service };

    const res = await waitListRequestSend(reqSend);

    if (res?.success === true) {
      setBusyRequest(res?.data);
      Cookies?.set("openWaitingModalCall", 500, { domain: cookieDomain });
      Cookies?.set("setAstroIdForWaiting", data?.id, { domain: cookieDomain });
      Cookies?.set("setWaitingUserId", userId, { domain: cookieDomain });
      window.location.reload();
    }
  }

  function handleYesButtonClick() {
    if (busyRequest) {
      const { ele, serviceType } = busyRequest;
      BusyRequestSendChat(ele, serviceType);
      setShowChatWaiting(false);
    }
  }

  // Similar  api start
  const [SimilarData, setSimilarData] = useState();
  const [SimilarDataUrl, setSimilarurl] = useState();
  useEffect(() => {
    const SimilarDataslider = async (data) => {
      try {
        const res = await fetchAllSimillarAstrologers(data);
        console.log(res);
        if (res?.success === true) {
          setSimilarData(res?.data);
          setSimilarurl(res?.url);
        }
      } catch (error) {
        console.log(error);
      }
    };
    SimilarDataslider(mainIId);
  }, []);
  // Similar  api end

  return (
    <>
      {/* ========= */}
      <Header2 />
      {/* ====== */}

      {/* astrologes chat details section start */}
      <section className="astrologer_chat_details">
        <div className="container">
          {/* row start */}
          <div className="row justify-content-center">
            {/* col start */}
            <div className="col-lg-9 col-md-10 col-sm-12 col-12">
              {/* flex start */}
              <div className="main_div_flex_astrochat">
                {/* astro chat div start */}
                <div className="astrologer_chat_left_div_main">
                  {/* profile img start */}
                  <div className="astrololger_profile_main_div">
                    <div className="astrologer_profile_img_box">
                      <img
                        src={
                          singleAstrologerDataShow?.profile == null
                            ? `https://healfate.com/astro_docs/No-image-found.jpg`
                            : `${url}${singleAstrologerDataShow?.profile}`
                        }
                        alt=""
                        className="img-fluid"
                      />
                      {/* overlay astro chat dt div */}
                      <div className="astrologer_chat_overlay">
                        <span className="active_chatprofile_dt"></span>
                      </div>
                      {/* overlay astro chat dt div end */}
                    </div>
                  </div>
                  {/* profile img end */}

                  <h3>Total Orders: 5</h3>
                  <div className="follow_us_div">
                    <div className="mb-md-0 mb-2">
                      {isAuthenticated != null ? (
                        <div className="d-flex align-items-center justify-content-center gap-2 newMarginNameAstrologerDetails">
                          {singleAstrologerDataShow?.user_following != 0 ? (
                            <button
                              type="button"
                              className=" btn  fontFollowing outline-0 outline-none   w-60 "
                              onClick={() =>
                                toggleUnfollowModal(
                                  singleAstrologerDataShow?.id
                                )
                              }
                            >
                              <div className="d-flex align-items-center justify-content-center gap-2">
                                <div>Following</div>
                                <div>
                                  <SlUserFollow />
                                </div>
                              </div>
                            </button>
                          ) : (
                            <button
                              type="button"
                              className=" btn  fontFollowing    w-60 "
                              onClick={() =>
                                FollowUnfollowCall(singleAstrologerDataShow?.id)
                              }
                              //   disabled={isButtonDisabled}
                            >
                              <div className="d-flex align-items-center justify-content-center gap-2">
                                <div>Follow</div>
                                <div>
                                  <SlUserFollow />
                                </div>
                              </div>
                            </button>
                          )}

                          <button
                            type="button"
                            className="btn fontFollowing  w-60 "
                            onClick={() =>
                              toggleModal(singleAstrologerDataShow?.id)
                            }
                          >
                            <div className=" d-flex align-items-center justify-content-center gap-2">
                              Gift
                              <FaGift />
                            </div>
                          </button>
                        </div>
                      ) : (
                        <div className="d-flex align-items-center justify-content-center gap-2 newMarginNameAstrologerDetails">
                          {singleAstrologerDataShow?.user_following != 0 ? (
                            <button
                              type="button"
                              className=" btn  fontFollowing outline-0 outline-none 
                               fs-5  py-0  w-60 "
                              onClick={() =>
                                toggleUnfollowModal(
                                  singleAstrologerDataShow?.id
                                )
                              }
                            >
                              <div className="d-flex align-items-center justify-content-center gap-2">
                                <div>Following</div>
                                <div>
                                  <SlUserFollow />
                                </div>
                              </div>
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="btn  fontFollowing outline-0 outline-none   w-60 "
                              onClick={toggleLoginModal}
                              disabled={isButtonDisabled}
                            >
                              <div className="d-flex align-items-center justify-content-center gap-2 ">
                                <div>Follow</div>
                                <div>
                                  <SlUserFollow />
                                </div>
                              </div>
                            </button>
                          )}
                           <button
                            type="button"
                            className="btn   fontFollowing  w-60 "
                            onClick={() =>
                              toggleLoginModal(singleAstrologerDataShow?.id)
                            }
                          >
                            <div className=" d-flex align-items-center justify-content-center gap-2"
                             onClick={() =>
                              toggleLoginModal(singleAstrologerDataShow?.id)
                            }>

                              Gift
                              <FaGift />
                            </div>
                          </button>
                        </div>
                      )}
                    </div>
                    {/* <Link onClick={toggleModal}>Gift <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M190.5 68.8L225.3 128H224 152c-22.1 0-40-17.9-40-40s17.9-40 40-40h2.2c14.9 0 28.8 7.9 36.3 20.8zM64 88c0 14.4 3.5 28 9.6 40H32c-17.7 0-32 14.3-32 32v64c0 17.7 14.3 32 32 32H480c17.7 0 32-14.3 32-32V160c0-17.7-14.3-32-32-32H438.4c6.1-12 9.6-25.6 9.6-40c0-48.6-39.4-88-88-88h-2.2c-31.9 0-61.5 16.9-77.7 44.4L256 85.5l-24.1-41C215.7 16.9 186.1 0 154.2 0H152C103.4 0 64 39.4 64 88zm336 0c0 22.1-17.9 40-40 40H288h-1.3l34.8-59.2C329.1 55.9 342.9 48 357.8 48H360c22.1 0 40 17.9 40 40zM32 288V464c0 26.5 21.5 48 48 48H224V288H32zM288 512H432c26.5 0 48-21.5 48-48V288H288V512z"></path></svg></Link> */}
                  </div>
                </div>
                {/* astro chat div end */}
                {/* right side amin div start */}
                <div className="astrloger_chat_div_main_rightside">
                  <div className="astrloger_chat_details_div">
                    <div className="astrologer_heading_title">
                      <div className="astologer_title_flx">
                        <h2> {singleAstrologerDataShow?.name}</h2>
                        <img
                          src="../images/tic.png"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                      <div className="pro_file_dots">
                        <Link to="https://varta.healfate.com/astrologersDetails/10010"></Link>
                        <li className="dropdown">
                          <Link to="https://varta.healfate.com/astrologersDetails/10010"></Link>
                          <Link
                            className="fa fa-ellipsis-v"
                            to="#"
                            data-bs-toggle="dropdown"
                          />
                          <ul className="dropdown-menu">
                            <li>
                              <Link
                                className="dropdown-item"
                                to="#reportastrologerTablePopUp"
                                data-bs-toggle="modal"
                              >
                                <li>
                                  <a className="dropdown-item" href="#">
                                    {Cookies?.get("user") != null ? (
                                      <button
                                        className="text-center "
                                        onClick={() =>
                                          funBlockOpenModal(
                                            singleAstrologerDataShow?.id
                                          )
                                        }
                                      >
                                        Report
                                      </button>
                                    ) : (
                                      <button
                                        className="text-center"
                                        onClick={toggleLoginModal}
                                      >
                                        Report{" "}
                                      </button>
                                    )}
                                  </a>
                                </li>
                              </Link>
                            </li>
                          </ul>
                        </li>
                      </div>
                    </div>

                    <p>
                      <b>₹ {singleAstrologerDataShow?.rate}/min</b>{" "}
                      <span>Free</span>
                    </p>
                  </div>
                  {/* experties chat dt box start  */}
                  <div className="astrologer_chat_dt_box">
                    <div className="chat_dtexperties_heading_flex">
                      <h4>
                        <i className="fa fa-book"></i> Expertise :
                      </h4>
                    </div>
                    <h5> {singleAstrologerDataShow?.expertise?.join(", ")}</h5>
                  </div>
                  {/* experties chat dt box end  */}

                  {/* experties chat dt box start  */}
                  <div className="astrologer_chat_dt_box">
                    <div className="chat_dtexperties_heading_flex">
                      <h4>
                        <i className="fa fa-language"></i> Languages :
                      </h4>
                    </div>
                    <h5> {singleAstrologerDataShow?.language?.join(", ")}</h5>
                  </div>
                  {/* experties chat dt box end  */}

                  {/* experties chat dt box start  */}
                  <div className="astrologer_chat_dt_box">
                    <div className="chat_dtexperties_heading_flex">
                      <h4>
                        <i className="fa fa-calendar"></i> Experience :
                      </h4>
                    </div>
                    <h5>{singleAstrologerDataShow?.experience} Years</h5>
                  </div>
                  {/* experties chat dt box end  */}

                  {/* experties chat dt box start  */}
                  <div className="astrologer_chat_dt_box">
                    <div className="chat_dtexperties_heading_flex">
                      <h4>
                        <i className="fa fa-book"></i> Total followers :
                      </h4>
                    </div>
                    <h5> {singleAstrologerDataShow?.total_followers} </h5>
                  </div>
                  {/* experties chat dt box end  */}

                  {/* astrochat dt call vd div */}
                  <div className="astrologer_chat_call_vd_btn">
                    <Link onClick={()=>toggleTalkModelOpen('Chat')}>
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 512 512"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M398 81.84A227.4 227.4 0 0 0 255.82 32C194.9 32 138 55.47 95.46 98.09 54.35 139.33 31.82 193.78 32 251.37a215.66 215.66 0 0 0 35.65 118.76l.19.27c.28.41.57.82.86 1.22s.65.92.73 1.05l.22.4c1.13 2 2 4.44 1.23 6.9l-18.42 66.66a29.13 29.13 0 0 0-1.2 7.63A25.69 25.69 0 0 0 76.83 480a29.44 29.44 0 0 0 10.45-2.29l67.49-24.36.85-.33a14.75 14.75 0 0 1 5.8-1.15 15.12 15.12 0 0 1 5.37 1c1.62.63 16.33 6.26 31.85 10.6 12.9 3.6 39.74 9 60.77 9 59.65 0 115.35-23.1 156.83-65.06C457.36 365.77 480 310.42 480 251.49a213.5 213.5 0 0 0-4.78-45c-10.34-48.62-37.76-92.9-77.22-124.65zM87.48 380zM160 288a32 32 0 1 1 32-32 32 32 0 0 1-32 32zm96 0a32 32 0 1 1 32-32 32 32 0 0 1-32 32zm96 0a32 32 0 1 1 32-32 32 32 0 0 1-32 32z"></path>
                      </svg>{" "}
                      Chat
                    </Link>

                    <Link  onClick={()=>toggleTalkModelOpen('Call')}>
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 512 512"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M391 480c-19.52 0-46.94-7.06-88-30-49.93-28-88.55-53.85-138.21-103.38C116.91 298.77 93.61 267.79 61 208.45c-36.84-67-30.56-102.12-23.54-117.13C45.82 73.38 58.16 62.65 74.11 52a176.3 176.3 0 0 1 28.64-15.2c1-.43 1.93-.84 2.76-1.21 4.95-2.23 12.45-5.6 21.95-2 6.34 2.38 12 7.25 20.86 16 18.17 17.92 43 57.83 52.16 77.43 6.15 13.21 10.22 21.93 10.23 31.71 0 11.45-5.76 20.28-12.75 29.81-1.31 1.79-2.61 3.5-3.87 5.16-7.61 10-9.28 12.89-8.18 18.05 2.23 10.37 18.86 41.24 46.19 68.51s57.31 42.85 67.72 45.07c5.38 1.15 8.33-.59 18.65-8.47 1.48-1.13 3-2.3 4.59-3.47 10.66-7.93 19.08-13.54 30.26-13.54h.06c9.73 0 18.06 4.22 31.86 11.18 18 9.08 59.11 33.59 77.14 51.78 8.77 8.84 13.66 14.48 16.05 20.81 3.6 9.53.21 17-2 22-.37.83-.78 1.74-1.21 2.75a176.49 176.49 0 0 1-15.29 28.58c-10.63 15.9-21.4 28.21-39.38 36.58A67.42 67.42 0 0 1 391 480z"></path>
                      </svg>{" "}
                      Call 
                    </Link>
                    <Link onClick={()=>toggleTalkModelOpen('Video')}>
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 512 512"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M464 384.39a32 32 0 0 1-13-2.77 15.77 15.77 0 0 1-2.71-1.54l-82.71-58.22A32 32 0 0 1 352 295.7v-79.4a32 32 0 0 1 13.58-26.16l82.71-58.22a15.77 15.77 0 0 1 2.71-1.54 32 32 0 0 1 45 29.24v192.76a32 32 0 0 1-32 32zM268 400H84a68.07 68.07 0 0 1-68-68V180a68.07 68.07 0 0 1 68-68h184.48A67.6 67.6 0 0 1 336 179.52V332a68.07 68.07 0 0 1-68 68z"></path>
                      </svg>{" "}
                      Video Call
                    </Link>
                  </div>
                  {/* astrochat dt call vd div emd */}
                </div>
                {/* right side amin div end */}
              </div>
              {/* main div end flex chat astrologer */}
              <div className="astloger_chat_details_div">
                <h3>{singleAstrologerDataShow?.name}</h3>
                <p> {singleAstrologerDataShow?.bio}</p>
              </div>
              {/* neted row */}
              <div class="row">
                <div class="col-lg-6">
                  <div class="experience-card">
                    <h3
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      class="collapsed"
                      aria-expanded="false"
                    >
                      Education <i class="fa fa-chevron-down"></i>
                    </h3>
                    <div class="collapse" id="collapseOne">
                      {/* <div class="card card-body">Data Not Found</div> */}
                      <div className="card card-body">
                        {singleAstrologerDataShow.education == null
                          ? "Data Not Found"
                          : singleAstrologerDataShow.education?.map(
                              (ele, index) => (
                                <>
                                  <h4>
                                    {index + 1}. {ele?.course} | {ele?.year}
                                  </h4>
                                  <span>{ele?.college}</span>
                                  {ele?.description}
                                </>
                              )
                            )}
                      </div>
                    </div>
                  </div>
                  <div class="experience-card">
                    <h3
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      class="collapsed"
                      aria-expanded="false"
                    >
                      Work Experience <i class="fa fa-chevron-down"></i>
                    </h3>
                    <div class="collapse" id="collapseTwo">
                      <div className="card card-body">
                        {singleAstrologerDataShow?.experience == null
                          ? "Data Not Found"
                          : singleAstrologerDataShow.experience?.map(
                              (ele, index) => (
                                <>
                                  <h4>
                                    {index + 1}. {ele?.designation} |{" "}
                                    {ele?.year}
                                  </h4>
                                  <span>{ele?.company}</span>
                                  {ele?.description}
                                </>
                              )
                            )}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="experience-card">
                    <h3
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      class="collapsed"
                      aria-expanded="false"
                    >
                      Weekly Schedule <i class="fa fa-chevron-down"></i>
                    </h3>
                    <div class="collapse" id="collapseFour">
                      <div class="card card-body">
                        <div class="tableWrapper">
                          <table class="table table-bordered table-striped weeklySchedule">
                            <tbody>
                              <tr>
                                <th>Week Day</th>
                                <th>Available time</th>
                              </tr>
                              {weeklySheduleDetails?.map((ele) => (
                                <tr>
                                  <td>{ele?.date}</td>
                                  <td>{ele?.slottime}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* col start */}
                <div className="col-lg-5 col-md-8 col-sm-12">
                  <div className="pointer-container">
                    <div
                      className="inner-container"
                      onClick={() =>
                        startChatWithAssistant(singleAstrologerDataShow?.id)
                      }
                    >
                      <div
                        className="flex-container d-flex"
                        style={{ cursor: "pointer" }}
                      >
                        <div className="flex-item d-flex">
                          <BiSupport className="fs-3" />
                          <p className="text">Chat with Assistant</p>
                        </div>
                        <FaChevronRight className="fs-6" />
                      </div>
                    </div>
                  </div>

                  {
                    <div className="pointer-container margin-top">
                      <div className="inner-container">
                        <div className="flex-container ">
                          <div className="flex-item">
                            <BiPhoneCall className="fs-3" />
                            <p className="text">Emergency Call</p>
                          </div>
                          <p className="text-details">
                            We considers you as a VIP customer. You can connect
                            with astrologer even when they are offline. Enjoy
                            priority access and seamless communication with our
                            top astrologers.
                          </p>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <p className="text-details text-black">
                              ₹ {singleAstrologerDataShow?.e_rate} per minute
                            </p>

                            {singleAstrologerDataShow?.emergency_status != 0 ? (
                              <button
                                className="btn  "
                                onClick={openEmergencyCallRequestModal}
                              >
                                Connect
                              </button>
                            ) : (
                              <button className="btn  " disabled={true}>
                                Connect
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                </div>
                {/* col end */}
              </div>
              {/* end nested row */}
            </div>
            {/* col end */}
          </div>
          {/* row end */}
        </div>
      </section>
      {/* astrologes chat details end */}

      {/* ============================== Chat with Astrologers section start ====================================== */}
      <section className="chat_with_astrologer_section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9 col-md-10 col-sm-12 col-12">
              {/* Chat title with astrolger start */}
              <div class="chat_with_astrologer_title">
                <h2>Similar Astrologers</h2>
                <Link to="/chat">View All</Link>
              </div>
            </div>
            {/* Chat title with astrolger end */}
            {/* col start */}
            <div className="col-lg-9 col-md-10 col-sm-12 col-12">
              <div className="chat_withastrologer_main_div">
                <OwlCarousel
                  className="owl-theme"
                  {...Similarastrologerdetails}
                >
                  {SimilarData?.map((item, index) => {
                    const truncateText = (text, maxLength) => {
                      return text.length > maxLength
                        ? text.slice(0, maxLength) + "..."
                        : text;
                    };
                    return (
                      <>
                        {/* item div start */}
                        <div className="item" key={index}>
                          <div className="chat_astrologer_main_div">
                            <a href={`/astrologersDetails/${item?.astrologer_id}`}>
                              {/* flx profile astro main div start */}
                              <div className="profile_astro_flexdiv">
                                {/* profile div */}

                                <div className="profile_img_box">
                                  <div className="profile_prarent_div">
                                    {item?.profile == "" ? (
                                      <img
                                        src="../images/No-image-found.jpg"
                                        alt=""
                                        className="img-fluid"
                                      />
                                    ) : (
                                      <img
                                        src={`${SimilarDataUrl}${item?.profile}`}
                                        alt=""
                                      />
                                    )}

                                    {/* overlay div img */}
                                    <div className="overlay_img_tic">
                                      <img
                                        src="../images/tic.png"
                                        alt=""
                                        className="img-fluid"
                                      />
                                    </div>
                                    {/* end overlay */}
                                  </div>

                                  <div className="profile_astroname">
                                    <h4>
                                      <span className="inactive_div"> </span>{" "}
                                      {item?.name}
                                    </h4>
                                    <div className="rating_profile_div">
                                      <span class="text-start">5.0</span>
                                      <i class="fa fa-star fontCard"></i>
                                      <i class="fa fa-star fontCard"></i>
                                      <i class="fa fa-star fontCard"></i>
                                      <i class="fa fa-star fontCard"></i>
                                      <i class="fa fa-star fontCard"></i>
                                    </div>
                                  </div>
                                </div>

                                {/* end profile div */}

                                {/* <div className="profileastro_button_div">
                                  <span>Chat</span>
                                </div> */}
                              </div>
                              {/* flx profile astro main div end */}

                              {/* experties div start */}
                              <div className="main_div_experties">
                                <div className="experties_heading_flex">
                                  <h4>
                                    <i className="fa fa-book"></i> Expertise
                                  </h4>
                                </div>
                                <h5>{truncateText(item?.expertise, 15)}</h5>
                              </div>
                              {/* experties div end */}

                              {/* experties div start */}
                              <div className="main_div_experties">
                                <div className="experties_heading_flex">
                                  <h4>
                                    <i className="fa fa-language"></i> Language
                                  </h4>
                                </div>
                                <h5>{truncateText(item?.language, 15)}</h5>
                              </div>
                              {/* experties div end */}

                              {/* experties div start */}
                              <div className="main_div_experties">
                                <div className="experties_heading_flex">
                                  <h4>
                                    <i className="fa fa-calendar"></i>{" "}
                                    Experience
                                  </h4>
                                </div>
                                <h5>{item?.total_exp}</h5>
                              </div>
                              {/* experties div end */}

                              {/* exp price div start */}
                              <div className="price_div_exper_chat">
                                <span>Free</span>
                              </div>
                              {/* exp price div end */}
                            </a>
                          </div>
                          {/*==============Chat============= col end */}
                        </div>
                        {/* item div end */}
                      </>
                    );
                  })}
                </OwlCarousel>
              </div>
            </div>
            {/* col end */}
          </div>
        </div>
      </section>
      {/* ============================== Chat with Astrologers section end ====================================== */}

      {/* all poups start */}
      <Allpopups />
      {/* all poups end */}
      {/* footer componets start */}
      <Footer />
      {/*footer componets end */}

      <div>
        {showModal && (
          <GiftPage
            closeModal={toggleModal}
            id={singleAstrologerDataShow?.id}
          />
        )}
        <ToastContainer style={{ zIndex: "9999999999999999999999" }} />
      </div>
      
      { showLoginModal && <Login closeModal={toggleLoginModal} />}
      { talkModalOpen && <TalkSuggetionModal closeModal={toggleTalkModelOpen} data={authenticatedData}/>}
    </>
   
  );
};

export default AstrologersDetails;
