import React from 'react'
import Header from '../components/Header';
import Footer from '../components/Footer';
const Blockedastro = () => {
  return (
    <>
     {/* ========= */}
  <Header/>
    {/* ====== */}
        {/* Reported Astrologers section start */}
        <section className="reported_astrologers_section">
  <div className="container">
    {/*  */}
    <div className="reported_astrologers_title_div">
      <h2>Reported Astrologers </h2>
    </div>
   
  </div>
</section>
<Footer/>
      {/* ticket section end */}
    </>
  )
}

export default Blockedastro
