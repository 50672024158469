import React, { useEffect } from 'react'
import OwlCarousel from 'react-owl-carousel';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { sliderrigtside } from '../data/Owlcarouseloptions';
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../CssFiles/Horoscope.css';

const Horoscope = () => {

  const { id } = useParams();
  const navigate = useNavigate();
  const convertHyphenatedNameToSpace = (name) => {
    if (typeof name !== 'string') {
        throw new Error('Input must be a string');
    }
    
    return name.replace(/-/g, ' ');
};
  useEffect(() => {

      // Check if the id matches the expected values
      if (id !== 'today-horoscope' && id !== 'tomorrow-horoscope' && id !== 'monthly-horoscope') {
          // Navigate to the homepage if id is not valid
          navigate('/');
      }
      // Dependency array includes id so useEffect runs when id changes
  }, [id, navigate]);


  return (
    <>
     {/* ========= */}
  <Header/>
    {/* ====== */}
      {/* Today's Horoscope section start */}
      <section className="today_horoscope_section">
        <div className="container">
            {/* title */}
<div className="today_horoscope_tittle_div">
<h1>{convertHyphenatedNameToSpace(id)}</h1>
<hr />
</div>
{/* title end */}
            {/* row start */}
            <div className="row">
                {/* col start */}
                <div className="col-lg-12">
                <div className="today_horoscope_txt_div mb-2">
                  <p>Unlock the secrets of your day by delving into today's horoscope. Whether seeking guidance or simply curious about the cosmic influences, our personalized insights offer a glimpse into the energies shaping your experiences.</p>
                </div>
                </div>
                {/* col end */}

                {/* col start 9 =========== */}
                <div className="col-lg-9 col-md-9 col-sm-12 col-12">
                    {/* nested row start */}
                    <div className="main_div_nested">
  
        <div className="row">
        {/* col start */}
      <div className="col-lg-3 col-md-4 col-sm-6 col-6">
        <Link to="aries">
          <div className="today_horoscope_main_div">
            <div className='today_horoscope_img_box'>
              <img src="../images/1.png" />
            </div>
              <h3>Aries</h3>
          </div>
        </Link>
      </div>
       {/* col end */}

        {/* col start */}
      <div className="col-lg-3 col-md-4 col-sm-6 col-6">
        <Link to="taurus">
          <div className="today_horoscope_main_div">
            <div className='today_horoscope_img_box'>
              <img src="../images/2.png" />
            </div>
              <h3>Taurus</h3>
          </div>
        </Link>
      </div>
       {/* col end */}

        {/* col start */}
      <div className="col-lg-3 col-md-4 col-sm-6 col-6">
        <Link to="gemini">
          <div className="today_horoscope_main_div">
            <div className='today_horoscope_img_box'>
              <img src="../images/3.png" />
            </div>
              <h3>Gemini</h3>
          </div>
        </Link>
      </div>
       {/* col end */}

        {/* col start */}
      <div className="col-lg-3 col-md-4 col-sm-6 col-6">
        <Link to="cancer">
          <div className="today_horoscope_main_div">
            <div className='today_horoscope_img_box'>
              <img src="../images/4.png" />
            </div>
              <h3>Cancer</h3>
          </div>
        </Link>
      </div>
       {/* col end */}

        {/* col start */}
      <div className="col-lg-3 col-md-4 col-sm-6 col-6">
        <Link to="leo">
          <div className="today_horoscope_main_div">
            <div className='today_horoscope_img_box'>
              <img src="../images/5.png" />
            </div>
              <h3>Leo</h3>
          </div>
        </Link>
      </div>
       {/* col end */}

        {/* col start */}
      <div className="col-lg-3 col-md-4 col-sm-6 col-6">
        <Link to="virgo">
          <div className="today_horoscope_main_div">
            <div className='today_horoscope_img_box'>
              <img src="../images/6.png" />
            </div>
              <h3>Virgo</h3>
          </div>
        </Link>
      </div>
       {/* col end */}

        {/* col start */}
      <div className="col-lg-3 col-md-4 col-sm-6 col-6">
        <Link to="libra">
          <div className="today_horoscope_main_div">
            <div className='today_horoscope_img_box'>
              <img src="../images/7.png" />
            </div>
              <h3>Libra</h3>
          </div>
        </Link>
      </div>
       {/* col end */}

        {/* col start */}
      <div className="col-lg-3 col-md-4 col-sm-6 col-6">
        <Link to="scorpio">
          <div className="today_horoscope_main_div">
            <div className='today_horoscope_img_box'>
              <img src="../images/8.png" />
            </div>
              <h3>Scorpio</h3>
          </div>
        </Link>
      </div>
       {/* col end */}

        {/* col start */}
      <div className="col-lg-3 col-md-4 col-sm-6 col-6">
        <Link to="sagittarius">
          <div className="today_horoscope_main_div">
            <div className='today_horoscope_img_box'>
              <img src="../images/9.png" />
            </div>
              <h3>Sagittarius</h3>
          </div>
        </Link>
      </div>
       {/* col end */}

        {/* col start */}
      <div className="col-lg-3 col-md-4 col-sm-6 col-6">
        <Link to="capricorn">
          <div className="today_horoscope_main_div">
            <div className='today_horoscope_img_box'>
              <img src="../images/10.png" />
            </div>
              <h3>Capricorn</h3>
          </div>
        </Link>
      </div>
       {/* col end */}

        {/* col start */}
      <div className="col-lg-3 col-md-4 col-sm-6 col-6">
        <Link to="aquarius">
          <div className="today_horoscope_main_div">
            <div className='today_horoscope_img_box'>
              <img src="../images/11.png" />
            </div>
              <h3>Aquarius</h3>
          </div>
        </Link>
      </div>
       {/* col end */}

        {/* col start */}
      <div className="col-lg-3 col-md-4 col-sm-6 col-6">
        <Link to="pisces">
          <div className="today_horoscope_main_div">
            <div className='today_horoscope_img_box'>
              <img src="../images/12.png" />
            </div>
              <h3>Pisces</h3>
          </div>
        </Link>
      </div>
       {/* col end */}
      
    </div>
     {/* nested row start */}
     </div>
  </div>
    {/* ========================col end 9 ========= */}


{/* ===================col start right side 3 col========================  */}
<div className="col-lg-3 col-md-3 col-sm-12 col-12">
  <div className="right_side_main_div_sticky mb-2">

    {/* slider start */}
    <div class="sidebar_main_div_sld">
  <OwlCarousel className="owl-theme" {...sliderrigtside}>
{/* item div start */}
<div className="item">
<div class="sideadd_img_box">
        <Link to=""><img src="../images/sld01.png" alt=""/></Link> 
    </div> 
</div>
{/* item div end */}
</OwlCarousel>
</div>
  {/* slider end */}

  {/* slider start */}
  <div class="sidebar_main_div_sld2">
  <OwlCarousel className="owl-theme" {...sliderrigtside}>
{/* item div start */}
<div className="item">
<div class="sideadd_img_box">
        <Link to=""><img src="../images/gplay.png" alt=""/></Link> 
    </div> 
</div>
{/* item div end */}

{/* item div start */}
<div className="item">
<div class="sideadd_img_box">
        <Link to=""><img src="../images/apple01.png" alt=""/></Link> 
    </div> 
</div>
{/* item div end */}
</OwlCarousel>
</div>
  {/* slider end */}

  
  {/* app div */}
  <div className="app_download">
    <h2>TalkndHeal on Mobile</h2>
    <Link to="">Download Now</Link>
  </div>
  {/*  */}
  {/* left icons start */}
  <div className="left_icons_so">
    <Link target="_blank" to=""><img src="../images/android.png" alt="Android App" /></Link>
    <Link target="_blank" to=""><img src="../images/apple.png" alt="iOS App" /></Link>
    <Link target="_blank" to=""><img src="../images/fb.png" alt="Facebook" /></Link>
    <Link target="_blank" to=""><img src="../images/insta.png" alt="Instagram" /></Link>
    <Link target="_blank" to=""><img src="../images/twitter.png" alt="Twitter" /></Link>
    <Link target="_blank" to=""><img src="../images/linkedin.png" alt="LinkedIn" /></Link>
    <Link target="_blank" to=""><img src="../images/yt.png" alt="Youtube" /></Link>
  </div>
  {/* left icons end */}

 
  </div>
</div>
{/* ===================col end right side 3 col========================  */}

            </div>
            {/* row end */}
        </div>
      </section>
      {/* Today's Horoscope section end */}
<Footer/>
    </>
  )
}

export default Horoscope
