import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
LiveChatHistoryInsertApi,
astrologerExpertiseApis,
astrologerLanguageApis,
chatAudioRequestSend,
chatRequestSend,
chatVideoRequestSend,
declinedAudioByUserApi,
declinedByUserApi,
declinedVideoByUserApi,
fetchAllLiveAstrologers,
fetchAstroCategory,
fetchChatStatusChange,
fetchWaitListRequestSend,
filterAstrologerApi,
filterAstrologerAudioApi,
filterAstrologerVideoApi,
getAllAstrologerCallApi,
getAllAstrologerChatApi,
getAllAstrologerVideoCallApi,
searchAstrologerApi,
waitListRequestSend,
} from "../../AllApi/AllApi";
import MyContext from "../../Context/MyContext";
import { Button, Tab, Tabs } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { IoTimer } from "react-icons/io5";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";
import Cookies from "js-cookie";
import Login2 from "../Authentication/Login2";
import LoadingComponent from "../LoadingComponent/LoadingComponent";
import LoadingComponentForChat from "../LoadingComponentForChat/LoadingComponentForChat";
// import { cookieDomain, domainName } from "../../Url/Url";
import NotFound from "../NotFound/NotFound";
import { cookieDomain, domainName } from "../Url/Url";
const AstrologersSimpleCards = () => {
const [allLiveAstrologersData,setAllLiveAstrologersData]=useState([])
console.log(allLiveAstrologersData)
const { types, idChy } = useParams();
const [categoriesItem, setCategoriesItem] = useState();
const [allDrops, setAlldrops] = useState(false);
const [showChatWaiting, setShowChatWaiting] = useState(false);
const [busyRequest, setBusyRequest] = useState();
async function BusyRequestSendChat(data, serviceType) {
const astroId = data?.id;
const userId = Cookies?.get("user");
const rqstSend = "1";
const service = serviceType;
const reqSend = { astroId, userId, rqstSend, service };
const res = await waitListRequestSend(reqSend);
if (res?.success === true) {
setBusyRequest(res?.data);
Cookies?.set("openWaitingModalCall", 500, { domain: cookieDomain });
Cookies?.set("setAstroIdForWaiting", data?.id, { domain: cookieDomain });
Cookies?.set("setWaitingUserId", userId, { domain: cookieDomain });
window.location.reload();
}
}
const currentData = window.location.pathname;
const modifiedCurrentData = currentData?.split('/');
console.warn(modifiedCurrentData[2]);
const navigateToSingleLiveAstrologer = (data) => {
const formattedData = data?.split(' ')?.join('-');
console.warn(formattedData, modifiedCurrentData[2]);
navigate(`/live/${formattedData}`);
// Reload the page after a short delay
setTimeout(() => {
window.location.reload();
}, 100); // Adjust the delay as needed
};
function closeLiveSession(){
window.location.reload()
setTimeout(()=>{
navigate('/live')
},10)
}
async function handleBusyRequestChatClick(ele, serviceType) {
const userId = Cookies?.get("user");
const waitlistStatus = await fetchWaitListRequestSend(userId);
if (waitlistStatus?.success == false) {
setShowChatWaiting(true);
setBusyRequest({ ele, serviceType });
}
}
function handleYesButtonClick() {
if (busyRequest) {
const { ele, serviceType } = busyRequest;
BusyRequestSendChat(ele, serviceType);
setShowChatWaiting(false);
}
}
const [expDrops, setExpdrops] = useState(false);
function toggleAllDrop() {
setAlldrops(!allDrops);
}
const [checkboxes, setCheckboxes] = useState({ value: idChy });
const [checkboxes2, setCheckboxes2] = useState({ value: idChy });
const [experienceCheckbox, setExperienceCheckbox] = useState({});
const [expertiesCheckbox, setExpertiesCheckbox] = useState({});
const [ratingCheckbox, setRatingCheckbox] = useState({});
const [pricingCheckbox, setPricingCheckbox] = useState({});
async function categoriesItemFun(id) {
if (id) {
const datSend = {
pricing: pricingCheckbox.value,
experience: experienceCheckbox.value,
rating: ratingCheckbox.value,
language: checkboxes.value,
experties: expertiesCheckbox?.value,
category: id,
};
const res = await filterAstrologerApi(datSend);
if (res.success == true) {
setAstrologerData(res.data);
setAstrologerDataAudio(res?.data);
setAstrologerDataVideo(res?.data);
} else {
setAstrologerData(res.data);
setAstrologerDataAudio(res?.data);
setAstrologerDataVideo(res?.data);
}
// Perform additional actions here
}
}
useEffect(() => {
categoriesItemFun(categoriesItem);
}, [categoriesItem]);
function toggleExpDrop() {
setExpdrops(!expDrops);
}
const [astrologerLanguageApi, setAstrologerLanguageApi] = useState([]);
const [astrologerExpertiesApi, setAstrologerExpertiesApi] = useState([]);
const [astrologerData, setAstrologerData] = useState([]);
const [astrologerDataAudio, setAstrologerDataAudio] = useState([]);
const [astrologerDataVideo, setAstrologerDataVideo] = useState([]);
const [lessBalanceModel, setLessBalanceModel] = useState(false);
const [idSetModal, setidSetModal] = useState({});
const [requestSent, setRequestSent] = useState(false);
const [minuteBalance, setlessMinuteBalance] = useState();
const cookieAstrologerId = Cookies.get('astrologerId');
let initialAstrologerData = {};
if (cookieAstrologerId) {
try {
initialAstrologerData = JSON.parse(cookieAstrologerId);
} catch (e) {
}
}
const [singleAstrologerDataShow, setSingleAstrologerDataShow] = useState(initialAstrologerData);
const [url, setUrl] = useState();
// req send
const [seconds, setSeconds] = useState(Number(sessionStorage.getItem("timerModalSeconds")) || 180);
const [audioSeconds, setAudioSeconds] = useState(Number(sessionStorage.getItem("timerAudioModalSeconds")) || 0);
const [videoSeconds, setVideoSeconds] = useState(Number(sessionStorage.getItem("timerVideoModalSeconds")) || 0);
const [startTheChat, setStartTheChat] = useState(false);
const [requestUniqueData, setRequestUniqueData] = useState(() => parseInt(Cookies.get("requestUniqueData")) || 0);
const [requestChatData, setRequestChatData] = useState(() => parseInt(Cookies.get("requestChatData")) || 0);
const [requestMainId, setRequestMainId] = useState(() => parseInt(Cookies.get("requestMainId")) || 0);
const [requestAstroidData, setRequestAstroidData] = useState(() => parseInt(Cookies.get("requestAstroidData")) || 0);
const [requestAstroAmountData, setRequestAstroAmountData] = useState(() => parseInt(Cookies.get("requestAstroAmountData")) || 0);
useEffect(() => {
// Sync state with cookies in case they change outside of this component
setRequestUniqueData(parseInt(Cookies.get("requestUniqueData")) || 0);
setRequestChatData(parseInt(Cookies.get("requestChatData")) || 0);
setRequestMainId(parseInt(Cookies.get("requestMainId")) || 0);
setRequestAstroidData(parseInt(Cookies.get("requestAstroidData")) || 0);
setRequestAstroAmountData(parseInt(Cookies.get("requestAstroAmountData")) || 0);
}, []);
const [selectedForm, setSelectedForm] = useState(
types === undefined ? "Chat" : types === "video" ? "Video" : types === "call" ? "Call" : "Chat"
);
// req send
const [loading, setLoading] = useState(true);
const contextData = useContext(MyContext);
const userloginStatus =
contextData?.userDetails?.loginstatus == "undefined" ? 0 : contextData?.userDetails?.loginstatus;
const userwalletDetails = contextData?.userDetails?.data?.wallet;
const [showDownloadSuggetion, setShowDownloadSuggetion] = useState(false);
const [showCallDownloadSuggetion, setShowCallDownloadSuggetion] = useState(false);
const [showVideoDownloadSuggetion, setShowVideoDownloadSuggetion] = useState(false);
const [intervalId, setIntervalId] = useState(null);
const [showModal, setShowModal] = useState(false);
const defaultIntakeValue = contextData?.intakeChange; // Set your default value here
const [showLoginModal, setShowLoginModal] = useState();
function toggleLoginModal() {
setShowLoginModal(!showLoginModal);
}
const [showIntakeModal, setShowIntakeModal] = useState(false);
const [idSave, setIdSave] = useState();
function toggleDownloadSuggetion(objids) {
setSingleAstrologerDataShow(objids)
setShowDownloadSuggetion(!showDownloadSuggetion)
}
function toggleCallDownloadSuggetion(objids) {
setSingleAstrologerDataShow(objids)
setShowCallDownloadSuggetion(!showCallDownloadSuggetion)
}
function toggleVideoDownloadSuggetion(objids) {
setSingleAstrologerDataShow(objids)
setShowVideoDownloadSuggetion(!showVideoDownloadSuggetion)
}
function toggleIntakeModal(objids) {
setShowIntakeModal(!showIntakeModal)
if (objids) {
setSingleAstrologerDataShow(objids);
const stringifiedObjIds = JSON.stringify(objids);
Cookies.set("astrologerId", stringifiedObjIds, { domain: cookieDomain });
}
}
const location = useLocation();
const removeLastSegment = (url) => {
const urlParts = url.split("/");
urlParts.pop(); // Remove the last segment
return urlParts.join("/");
};
const navigate = useNavigate();
async function astrologersShow() {
const datSend = {
pricing: pricingCheckbox.value,
experience: experienceCheckbox.value,
rating: ratingCheckbox.value,
language: checkboxes?.value,
experties: expertiesCheckbox.value,
category: categoriesItem,
};
const res = await filterAstrologerApi(datSend);
if (res.success == true) {
setUrl(res?.url);
setAstrologerData(res.data);
setTimeout(() => {
setLoading(false);
}, 1000);
}
}
const [showChatRequestModal, setShowChatRequestModal] = useState(false);
const [showVideoIntakeModal, setShowVideoIntakeModal] = useState(false);
// Chat request work start
// Audio
const [showAudioIntakeModal, setShowAudioIntakeModal] = useState(false);
// Audio
const toggleVideoIntakeModal = (objids) => {
setShowVideoIntakeModal(!showVideoIntakeModal)
if (objids) {
setSingleAstrologerDataShow(objids);
// reqVideoSend(objids?.id);
const stringifiedObjIds = JSON.stringify(objids);
Cookies.set("astrologerId", stringifiedObjIds, { domain: cookieDomain });
}
};
function toggleAudioIntakeModal(objids) {
setShowAudioIntakeModal(!showAudioIntakeModal)
if (objids) {
setSingleAstrologerDataShow(objids);
// reqAudioSend(objids?.id);
const stringifiedObjIds = JSON.stringify(objids);
Cookies.set("astrologerId", stringifiedObjIds, { domain: cookieDomain });
}
}
// refress status api
function closeLessBalanceModel() {
setLessBalanceModel(false);
}
// Low To High filter
// Low To high Filter
const [activeItem, setActiveItem] = useState("All");
const handleItemClick = (item) => {
setActiveItem(item === activeItem ? "All" : item);
setCategoriesItem(item);
};
async function allLiveAstrologers(){
const res=  await fetchAllLiveAstrologers()
if(res?.success==true){
setAllLiveAstrologersData(res?.data)
setTimeout(() => {
setLoading(false);
}, 1000);
}
}
useEffect(()=>{
allLiveAstrologers()
},[])
return (
<div>
   {/* chat2  */}

{/*  */}
<section className="simplecard_section">
   <div className="container">
      {/* title div start */}
      <div className="simple_card_title_div">
         <h2>OTHER LIVE ASTROLOGERS</h2>
      </div>
      {/* title div end */}
      {/* row start */}
      <div className="row justify-content-center">
         {/* col start  */}
         <div className="col-lg-8 col-md-8 col-sm-12 col-12">
             {/* nested row start */}
      <div className="row">
         {/* nested col start  */}
         {loading == false ? (
                           <>
                           <div className="d-flex chat_items_div d-block d-sm-block d-xs-block d-md-none d-lg-none d-xl-none" style={{ overflow: "auto" }}>                         
                        </div>
                        <>
                        {" "}
                        {astrologerData != null
                        ? allLiveAstrologersData?.map((ele, index) => (
                        <>
         <div className="col-lg-3 col-md-4 col-sm-6 col-6">
            <div className="main_div_astrocard_box" onClick={()=>
                              navigateToSingleLiveAstrologer(ele?.name)}>
               <div className="astro_bigimg_box">
                  <img src={`https://healfate.com/astro_docs/${ele?.profile}`} alt="" className="img-fluid"/>
               </div>
            {/* overlay samll img box start */}
                 <div className="overlay_small_img_box">
                  <div className="live_relative_div">
                 <img src={`https://healfate.com/astro_docs/${ele?.profile}`} alt="" className="img-fluid"/>
                 <div className="live_overlay_div">
                 <img src={`${domainName}/images/ic_online.gif`} alt="Live Indicator" /> <b>Live</b>
                 </div>
                 </div>
                 </div>
            {/* overlay samll img box start */}
           {/* text name asytrologer start */}
           <div className="astro_name_overlay">
            <h3>{ele?.name}</h3>
           </div>
           {/* text name asytrologer end */}
            </div>
         </div>
         </>
                  ))
                  : 
                  <NotFound />
                  }
                  </>
                  </>

               
                  ) : (
                  <LoadingComponentForChat />
                  )}
         {/* nested col end */}
      </div>
      {/* nested row end */}
         </div>
         {/* col end */}
      </div>
      {/* row end */}
   </div>
</section>
{/*  */}


{/* Chat Request Send  */}
{showDownloadSuggetion && (
<div className="modal fade show" id="login" tabIndex={-1} style={{ display: "block" }}>
<div className="modal-dialog">
   <div className="modal-content">
      <div className="modal-header">
         <h5 className="modal-title" style={{ fontSize: "16px" }}>
         Chat Request
         </h5>
         <button type="button" className="fa fa-close"  onClick={toggleDownloadSuggetion} />
      </div>
      <div className="modal-body">
      <div className="d-flex gap-5 align-items-center justify-content-center  mb-2">
      <div className="">
      <div className="d-flex align-items-center justify-content-center">
      <img
      src={
      singleAstrologerDataShow?.profile == null
      ? `${url}/No-image-found.jpg`
      : `${url}/${singleAstrologerDataShow?.profile}`
      }
      style={{ width: "95px", height: "95px", borderRadius: "50%" }}
      ></img>
      </div>
      <label className="text-start" style={{fontSize:"18px"}}>{singleAstrologerDataShow.name}</label>
      </div>
      </div>
      <h3  className='text-center' style={{fontSize:"15px"}}>Connect and Chat with Astrologers Anytime, Anywhere. Download Our App Now!</h3>
      <div className='d-flex gap-2 align-items-center justify-content-center'>
      <a href="https://play.google.com/store/apps/details?id=com.talkndheal.com&hl=en" target="_blank" rel="noopener noreferrer">
      <img src="https://healfate.com/assets/img/android01.png" style={{width: "140px", height: "40px"}} alt="Download from Play Store" />
      </a>
      <a href="https://apps.apple.com/us/app/immortal-varta/id6468341765" target="_blank" rel="noopener noreferrer">
      <img src="https://healfate.com/assets/img/appstore.png" style={{width:"140px",height:"40px"}} alt="Download from App Store" />
      </a>
      </div>
      </div>
   </div>
</div>
</div>
)}
{/* Chat Request Send  */}
{/* Audio Call Request Send  */}
{showCallDownloadSuggetion && (
<div className="modal fade show" id="login" tabIndex={-1} style={{ display: "block" }}>
<div className="modal-dialog">
<div className="modal-content">
<div className="modal-header">
<h5 className="modal-title" style={{ fontSize: "16px" }}>
Call Request
</h5>
<button type="button" className="fa fa-close"  onClick={toggleCallDownloadSuggetion} />
</div>
<div className="modal-body">
<div className="d-flex gap-5 align-items-center justify-content-center  mb-2">
<div className="">
<div className="d-flex align-items-center justify-content-center">
<img
src={
singleAstrologerDataShow?.profile == null
? `${url}/No-image-found.jpg`
: `${url}/${singleAstrologerDataShow?.profile}`
}
style={{ width: "95px", height: "95px", borderRadius: "50%" }}
></img>
</div>
<label className="text-start text-black text-center" style={{fontSize:"18px"}}>{singleAstrologerDataShow.name}</label>
</div>
</div>
<h3  className='text-center' style={{fontSize:"15px"}}>Connect and Call with Astrologers Anytime, Anywhere. Download Our App Now!</h3>
<div className='d-flex gap-2 align-items-center justify-content-center'>
<a href="https://play.google.com/store/apps/details?id=com.talkndheal.com&hl=en" target="_blank" rel="noopener noreferrer">
<img src="https://healfate.com/assets/img/android01.png" style={{width: "140px", height: "40px"}} alt="Download from Play Store" />
</a>
<a href="https://apps.apple.com/us/app/immortal-varta/id6468341765" target="_blank" rel="noopener noreferrer">
<img src="https://healfate.com/assets/img/appstore.png" style={{width:"140px",height:"40px"}} alt="Download from App Store" />
</a>
</div>
</div>
</div>
</div>
</div>
)}
{/* Audio Call Request Send  */}
{/* Video Call Request Send  */}
<div>
<div className="main_div_sidebar">
<div
   className="offcanvas offcanvas-start"
   tabIndex={-1}
   id="filterForMobile"
   aria-labelledby="filterWithBothOptionsLabel"
   >
</div>
{/*============= mobile menu end================= */}
</div>
</div>
{/* Audio  */}
{showLoginModal && <Login2 closeModal={toggleLoginModal} />}
<div style={{ zIndex: "9999999999999999999999" }}>
<ToastContainer />
</div>
<Modal show={lessBalanceModel} onHide={closeLessBalanceModel}>
<Modal.Header closeButton style={{ backgroundColor: "#f8f0d3" }} className="text-danger">
{/* <Modal.Title>Modal heading</Modal.Title> */}
</Modal.Header>
{  userloginStatus == 0  ? <Modal.Body className="text-black" style={{ backgroundColor: "white", border: "unset", borderRadius: "0px" }}>
Your first chat will be free. You'll need to recharge with minimum amount to initiate chat. The wallet amount will not be deducted for this chat.
</Modal.Body>:
<Modal.Body className="text-black" style={{ backgroundColor: "white", border: "unset", borderRadius: "0px" }}>
Your Wallet Balance is ₹ {userwalletDetails}.0. Minimum balance required ₹
{parseFloat(singleAstrologerDataShow?.disc_rate) * 5} to Connect With
<strong style={{ fontStyle: "bold" }}> {singleAstrologerDataShow?.name} </strong>Recharge now With
following balance.
</Modal.Body>}
<Modal.Footer style={{ backgroundColor: "#f8f0d3" }}>
<div className=" buttons "></div>
{/* <Button variant="secondary" onClick={closeLessBalanceModel}>
Cancel
</Button> */}
<Link to="https://healfate.com/wallet.php">
<Button variant="outline-danger" onClick={closeLessBalanceModel}>
Recharge
</Button>
</Link>
</Modal.Footer>
</Modal>
{/* filter in mobile  */}

</div>
);
};
export default AstrologersSimpleCards;