import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import { Link } from 'react-router-dom';
import { sliderrigtside } from '../data/Owlcarouseloptions';
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../CssFiles/Wallet.css';

const Wallet = () => {
  return (
    <>
     {/* ========= */}
  <Header/>
    {/* ====== */}
      {/* wallet section start */}
      <section className="wallet_section">
        <div className="container">
            {/* row start */}
            <div className="row">
{/* ===================col start right side 3 col========================  */}
<div className="col-lg-3 col-md-3 col-sm-12 col-12">
  <div className="right_side_main_div_sticky mb-2">

    {/* slider start */}
    <div class="sidebar_main_div_sld">
  <OwlCarousel className="owl-theme" {...sliderrigtside}>
{/* item div start */}
<div className="item">
<div class="sideadd_img_box">
        <Link to=""><img src="../images/sld01.png" alt=""/></Link> 
    </div> 
</div>
{/* item div end */}
</OwlCarousel>
</div>
  {/* slider end */}

  {/* slider start */}
  <div class="sidebar_main_div_sld2">
  <OwlCarousel className="owl-theme" {...sliderrigtside}>
{/* item div start */}
<div className="item">
<div class="sideadd_img_box">
        <Link to=""><img src="../images/gplay.png" alt=""/></Link> 
    </div> 
</div>
{/* item div end */}

{/* item div start */}
<div className="item">
<div class="sideadd_img_box">
        <Link to=""><img src="../images/apple01.png" alt=""/></Link> 
    </div> 
</div>
{/* item div end */}
</OwlCarousel>
</div>
  {/* slider end */}

  
  {/* app div */}
  <div className="app_download">
    <h2>TalkndHeal on Mobile</h2>
    <Link to="">Download Now</Link>
  </div>
  {/*  */}
  {/* left icons start */}
  <div className="left_icons_so">
    <Link target="_blank" to=""><img src="../images/android.png" alt="Android App" /></Link>
    <Link target="_blank" to=""><img src="../images/apple.png" alt="iOS App" /></Link>
    <Link target="_blank" to=""><img src="../images/fb.png" alt="Facebook" /></Link>
    <Link target="_blank" to=""><img src="../images/insta.png" alt="Instagram" /></Link>
    <Link target="_blank" to=""><img src="../images/twitter.png" alt="Twitter" /></Link>
    <Link target="_blank" to=""><img src="../images/linkedin.png" alt="LinkedIn" /></Link>
    <Link target="_blank" to=""><img src="../images/yt.png" alt="Youtube" /></Link>
  </div>
  {/* left icons end */}

 
  </div>
</div>
{/* ===================col end right side 3 col========================  */}


               {/*=====================col start right side col end 9 =============================*/}
               <div className="col-lg-9 col-md-9 col-sm-12 col-12">
                 {/* nested row start */}
                 <div className="row">
                    {/* netsed col start */}
                    <div className="col-lg-12 col-md-5">
                        <div className="wallet_head">
                          <i className="fas fa-wallet"/>
                          <h3>Available Balance</h3>
                          <h2>₹50,015.50</h2>
                        <Link to="">Consutation History</Link>
                   </div>  
                  </div>
                    {/* netsed col end */}

                     {/* netsed col start */}
                     <div className="col-lg-12 col-md-5">
                        <div className="wallet_title">
                        <h2>Add Money To Wallet</h2>   
                        <h3>Choose from the available recharge pack</h3>
                   </div>  
                  </div>
                    {/* netsed col end */}

                    {/* netsed start end */}
                    <div className="col-lg-4 col-md-6 col-sm-4 col-4">
                 <div className="recharge_box_main_div">
                    <Link to="/paymentDetails" >
                          <h3>₹ 25</h3>
                         <h4>Pay Now</h4>
                        <span>No Extra</span>   
                    </Link>
                       </div>
                  </div>
                    {/* netsed col end */}

                    {/* netsed start end */}
                    <div className="col-lg-4 col-md-6 col-sm-4 col-4">
                 <div className="recharge_box_main_div">
                    <Link to="" >
                          <h3>₹ 25</h3>
                         <h4>Pay Now</h4>
                        <span>No Extra</span>   
                    </Link>
                       </div>
                  </div>
                    {/* netsed col end */}


                    {/* netsed start end */}
                    <div className="col-lg-4 col-md-6 col-sm-4 col-4">
                 <div className="recharge_box_main_div">
                    <Link to="" >
                          <h3>₹ 25</h3>
                         <h4>Pay Now</h4>
                        <span>3% Extra</span>   
                    </Link>
                       </div>
                  </div>
                    {/* netsed col end */}


                    {/* netsed start end */}
                    <div className="col-lg-4 col-md-6 col-sm-4 col-4">
                 <div className="recharge_box_main_div">
                    <Link to="" >
                          <h3>₹ 25</h3>
                         <h4>Pay Now</h4>
                        <span>10% Extra</span>   
                    </Link>
                       </div>
                  </div>
                    {/* netsed col end */}


                    {/* netsed start end */}
                    <div className="col-lg-4 col-md-6 col-sm-4 col-4">
                 <div className="recharge_box_main_div">
                    <Link to="" >
                          <h3>₹ 25</h3>
                         <h4>Pay Now</h4>
                        <span>15% Extra</span>   
                    </Link>
                       </div>
                  </div>
                    {/* netsed col end */}


                    {/* netsed start end */}
                    <div className="col-lg-4 col-md-6 col-sm-4 col-4">
                 <div className="recharge_box_main_div">
                    <Link to="" >
                          <h3>₹ 25</h3>
                         <h4>Pay Now</h4>
                        <span>15% Extra</span>   
                    </Link>
                       </div>
                  </div>
                    {/* netsed col end */}


                    {/* netsed start end */}
                    <div className="col-lg-4 col-md-6 col-sm-4 col-4">
                 <div className="recharge_box_main_div">
                    <Link to="" >
                          <h3>₹ 25</h3>
                         <h4>Pay Now</h4>
                        <span>15% Extra</span>   
                    </Link>
                       </div>
                  </div>
                    {/* netsed col end */}

                    {/* netsed start end */}
                    <div className="col-lg-4 col-md-6 col-sm-4 col-4">
                 <div className="recharge_box_main_div">
                    <Link to="" >
                          <h3>₹ 25</h3>
                         <h4>Pay Now</h4>
                        <span>15% Extra</span>   
                    </Link>
                       </div>
                  </div>
                    {/* netsed col end */}

                    {/* netsed start end */}
                    <div className="col-lg-4 col-md-6 col-sm-4 col-4">
                 <div className="recharge_box_main_div">
                    <Link to="" >
                          <h3>₹ 25</h3>
                         <h4>Pay Now</h4>
                        <span>15% Extra</span>   
                    </Link>
                       </div>
                  </div>
                    {/* netsed col end */}

                    {/* netsed start end */}
                    <div className="col-lg-4 col-md-6 col-sm-4 col-4">
                 <div className="recharge_box_main_div">
                    <Link to="" >
                          <h3>₹ 25</h3>
                         <h4>Pay Now</h4>
                        <span>15% Extra</span>   
                    </Link>
                       </div>
                  </div>
                    {/* netsed col end */}


                    {/* netsed start end */}
                    <div className="col-lg-4 col-md-6 col-sm-4 col-4">
                 <div className="recharge_box_main_div">
                    <Link to="" >
                          <h3>₹ 25</h3>
                         <h4>Pay Now</h4>
                        <span>15% Extra</span>   
                    </Link>
                       </div>
                  </div>
                    {/* netsed col end */}


                    {/* netsed start end */}
                    <div className="col-lg-4 col-md-6 col-sm-4 col-4">
                 <div className="recharge_box_main_div">
                    <Link to="" >
                          <h3>₹ 25</h3>
                         <h4>Pay Now</h4>
                        <span>15% Extra</span>   
                    </Link>
                       </div>
                  </div>
                    {/* netsed col end */}

                 </div>
                 {/* nested row end */}
               </div>
               {/*=====================col start right side col end 9 =============================*/}

               </div>
            {/* row end */}
        </div>
      </section>
<Footer/>
      {/* wallet section end */}
    </>
  )
}

export default Wallet
