import React from 'react';
import { Link } from 'react-router-dom';

const Sidenavbar = () => {
  return (
    <>
    {/*============= mobile menu================= */}
<div className="main_div_sidebar">
  <div className="offcanvas offcanvas-start" tabIndex={-1} id="offcanvasWithBothOptions" aria-labelledby="offcanvasWithBothOptionsLabel">
    <div className="offcanvas-header"> 
      <h5 className="offcanvas-title" id="offcanvasWithBothOptionsLabel"><a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#login"><img src="../images/a1.png" alt /> SIGN IN/SIGN UP <i className="fa  fa-sign-in" /></a></h5>
      <button type="button" className="fa fa-times" data-bs-dismiss="offcanvas" aria-label="Close" />
    </div>
    <div className="offcanvas-body offcanvas_scroll">
      {/* Example single danger button */}
      <div className="mobile_navbar">
        <ul className="main_ul">
          {/* Horoscopes li */}
          <li><a href="/"> <img src="../images/Home.svg" alt /> Home </a> <span data-bs-toggle="collapse" data-bs-target="#products-menu2" className="fa fa-angle-down" /></li>
          <div className="collapse" id="products-menu2">
            <div className="card card-body profile_card_body">
              <ul className="profile_ul">    
             <li> <a href="/services/astrology">Astrology</a></li>
<li><a href="/services/tarot">Tarot</a></li>
<li><a href="/services/psychologist">Psychologist</a></li>
<li><a href="/services/kp-system">KP System</a></li>
<li><a href="/services/lalKitab">Lal Kitab</a></li>
<li><a href="/services/palmistry">Palmistry</a></li>
<li><a href="/services/vastu">Vastu</a></li>
<li><a href="/services/dietician">Dietician</a></li>
<li><a href="/services/pranayama">Pranayama</a></li>
<li><a href="/services/yogamudras">Yoga mudras</a></li>
<li><a href="/services/sun-rays-healing">Sun Rays Healing</a></li>
<li><a href="/services/sound-and-words-healing">Sound and Words Healing</a></li>
<li><a href="/services/crystal-healing">Crystal Healing</a></li>
<li><a href="/services/acupressure-healing">Acupressure Healing</a></li>
<li><a href="/services/magnet-healing">Magnet Healing</a></li>
<li><a href="/services/nature-healing">Nature Healing</a></li>
<li><a href="/services/metal-healing">Metal Healing</a></li>
              </ul>
            </div>
          </div>
          {/*Horoscopes drop li end */}
          <li><a href="/freeKundali"> <img src="../images/Kundli.svg" alt /> Kundli</a></li>
          <li><a href="/kundliMatching"> <img src="../images/lovely.svg" alt /> Kundli Matching</a></li>
          {/* Horoscopes li */}
          <li><a href="javascript:void(0);"> <img src="../images/Home.svg" alt /> 
              Horoscopes </a> <span data-bs-toggle="collapse" data-bs-target="#products-menu" className="fa fa-angle-down" /></li>
          <div className="collapse" id="products-menu">
            <div className="card card-body profile_card_body">
              <ul className="profile_ul">
                <li><a href="/horoscope/today-horoscope">Today's Horoscope</a></li>
                <li><a href="/horoscope/tomorrow-horoscope">Tomorrow's Horoscope</a></li>
                <li><a href="/horoscope/monthly-horoscope">Monthly Horoscope</a></li>
              </ul>
            </div>
          </div>
          {/*Horoscopes drop li end */}
          <li><a href="/freeKundali"> <img src="../images/gemini.svg" alt /> Prashna Kundli</a></li>
          <li><a href="/panchang"> <img src="../images/moon.svg" alt /> Panchang</a></li>
          <li><a href="/Samuhikpuja"> <img src="../images/Pooja.svg" alt /> Samuhik Puja</a></li>
          {/*<li><a href="blogs"> <img src="../images/Newspaper.png" alt=""> Current Affairs</a></li>*/}
          <li><a href="/JoinAsastrologer"> <img src="../images/Group76425.svg" alt /> Join As An Astrologer</a></li>
          <li><a href="javascript:void(0);"> <img src="../images/noti.png" alt /> Policies</a>
            <span data-bs-toggle="collapse" data-bs-target="#policies-menu" className="fa fa-angle-down" /></li>
          <div className="collapse" id="policies-menu">
            <div className="card card-body profile_card_body">
              <ul className="profile_ul">
                <li><a href="/disclaimer">Disclaimer</a></li>
                <li><a href="/termsandconditions">Terms &amp; Conditions</a></li>
                <li><a href="/refundcancellation">Refund &amp; Cancellation</a></li>
                <li><a href="/privacypolicy">Privacy Policy</a></li>
                <li><a href="/pricingpolicy">Pricing Policy</a></li>
              </ul>
            </div>
          </div>
         
        </ul>
        <ul className="left_icons_so_mobile">
          <li><a target="_blank" href=""><img src="../images/android.png" alt="Android App" /></a></li>
          <li><a target="_blank" href=""><img src="../images/apple.png" alt="iOS App" /></a></li>
          <li><a target="_blank" href="https://www.facebook.com/talkndhealofficial/"><img src="../images/fb.png" alt="Facebook" /></a></li>
          <li><a target="_blank" href="https://www.instagram.com/talkndhealdotcom/"><img src="../images/insta.png" alt="Instagram" /></a></li>
          <li><a target="_blank" href="https://x.com/TalkndHeal"><img src="../images/twitter.png" alt="Twitter" /></a></li>
          <li><a target="_blank" href="https://in.linkedin.com/company/talkndheal"><img src="../images/linkedin.png" alt="LinkedIn" /></a></li>
          <li><a target="_blank" href="https://www.youtube.com/@TalkndHeal"><img src="../images/yt.png" alt="Youtube" /></a></li>
        </ul>
      </div>
    </div>
  </div>
  {/*============= mobile menu end================= */}
  {/* mobile healing navbar section */}
  <div className="main_healing_nav_div">
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          {/*  */}
          <div className="mobile_healing_nav menu-wrapper">
            <div className="menu-item">
           
<a href="/services/astrology">Astrology</a>
<a href="/services/tarot">Tarot</a>
<a href="/services/psychologist">Psychologist</a>
<a href="/services/kp-system">KP System</a>
<a href="/services/lalKitab">Lal Kitab</a>
<a href="/services/palmistry">Palmistry</a>
<a href="/services/vastu">Vastu</a>
<a href="/services/dietician">Dietician</a>
<a href="/services/pranayama">Pranayama</a>
<a href="/services/yogamudras">Yoga mudras</a>
<a href="/services/sun-rays-healing">Sun Rays Healing</a>
<a href="/services/sound-and-words-healing">Sound and Words Healing</a>
<a href="/services/crystal-healing">Crystal Healing</a>
<a href="/services/acupressure-healing">Acupressure Healing</a>
<a href="/services/magnet-healing">Magnet Healing</a>
<a href="/services/nature-healing">Nature Healing</a>
<a href="/services/metal-healing">Metal Healing</a>
            </div>
            {/* <span class="pointer left-pointer dis">
    <i class="fa fa-chevron-left"></i>
  </span>
  <span class="pointer right-pointer">
    <i class="fa fa-chevron-right"></i>
  </span> */}
          </div>
          {/*  */}
        </div>
      </div>
    </div>
  </div>
  {/* end========= */}
</div>

    </>
  );
};

export default Sidenavbar;
