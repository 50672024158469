import React from 'react'

const Allpopups = () => {
  return (
    <>
      {/* HORA TABLE POP UP*/}
<div className="hora_deatils_popup_div_main">
  <div className="modal fade" id="horaTablePopUp" tabIndex={-1}>
    <div className="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title" id="staticBackdropLabel">Hora Details</h1>
        <button type="button" className="fa fa-times" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
        <div className="modal-body">
          <div className="hora_detals_table_name">
            <h3>Current Hora</h3>
            <p>12:00 - 13:00 ( Mercury )</p>
          </div>
          <div className="hora_detals_table_main_div">
            <table className="table table-bordered table-sm">
              <tbody>
                <tr>
                  <th>Planet</th>
                  <th>Start Time</th>
                  <th>End Time</th>
                </tr>
                <tr>
                  <td>Moon</td>
                  <td>6:0</td>
                  <td>7:0</td>
                </tr>
                <tr>
                  <td>Saturn</td>
                  <td>7:0</td>
                  <td>8:0</td>
                </tr>
                <tr>
                  <td>Jupiter</td>
                  <td>8:0</td>
                  <td>9:0</td>
                </tr>
                <tr>
                  <td>Mars</td>
                  <td>9:0</td>
                  <td>10:0</td>
                </tr>
                <tr>
                  <td>Sun</td>
                  <td>10:0</td>
                  <td>11:0</td>
                </tr>
                <tr>
                  <td>Venus</td>
                  <td>11:0</td>
                  <td>12:0</td>
                </tr>
                <tr>
                  <td>Mercury</td>
                  <td>12:0</td>
                  <td>13:0</td>
                </tr>
                <tr>
                  <td>Moon</td>
                  <td>13:0</td>
                  <td>14:0</td>
                </tr>
                <tr>
                  <td>Saturn</td>
                  <td>14:0</td>
                  <td>15:0</td>
                </tr>
                <tr>
                  <td>Jupiter</td>
                  <td>15:0</td>
                  <td>16:0</td>
                </tr>
                <tr>
                  <td>Mars</td>
                  <td>16:0</td>
                  <td>17:0</td>
                </tr>
                <tr>
                  <td>Sun</td>
                  <td>17:0</td>
                  <td>18:0</td>
                </tr>
                <tr>
                  <td>Venus</td>
                  <td>18:0</td>
                  <td>19:0</td>
                </tr>
                <tr>
                  <td>Mercury</td>
                  <td>19:0</td>
                  <td>20:0</td>
                </tr>
                <tr>
                  <td>Moon</td>
                  <td>20:0</td>
                  <td>21:0</td>
                </tr>
                <tr>
                  <td>Saturn</td>
                  <td>21:0</td>
                  <td>22:0</td>
                </tr>
                <tr>
                  <td>Jupiter</td>
                  <td>22:0</td>
                  <td>23:0</td>
                </tr>
                <tr>
                  <td>Mars</td>
                  <td>23:0</td>
                  <td>0:0</td>
                </tr>
                <tr>
                  <td>Sun</td>
                  <td>0:0</td>
                  <td>1:0</td>
                </tr>
                <tr>
                  <td>Venus</td>
                  <td>1:0</td>
                  <td>2:0</td>
                </tr>
                <tr>
                  <td>Mercury</td>
                  <td>2:0</td>
                  <td>3:0</td>
                </tr>
                <tr>
                  <td>Moon</td>
                  <td>3:0</td>
                  <td>4:0</td>
                </tr>
                <tr>
                  <td>Saturn</td>
                  <td>4:0</td>
                  <td>5:0</td>
                </tr>
                <tr>
                  <td>Jupiter</td>
                  <td>5:0</td>
                  <td>6:0</td>
                </tr>
              </tbody></table>    
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
{/*  */}


  {/* HORA TABLE POP UP*/}
  <div className="hora_deatils_popup_div_main">
  <div className="modal fade" id="chaughadiyaTablePopUp" tabIndex={-1}>
    <div className="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title" id="staticBackdropLabel">Hora Details</h1>
        <button type="button" className="fa fa-times" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
        <div className="modal-body">
          <div className="hora_detals_table_name">
            <h3>Current Hora</h3>
            <p>12:00 - 13:00 ( Mercury )</p>
          </div>
          <div className="hora_detals_table_main_div">
            <table className="table table-bordered table-sm">
              <tbody>
                <tr>
                  <th>Planet</th>
                  <th>Start Time</th>
                  <th>End Time</th>
                </tr>
                <tr>
                  <td>Moon</td>
                  <td>6:0</td>
                  <td>7:0</td>
                </tr>
                <tr>
                  <td>Saturn</td>
                  <td>7:0</td>
                  <td>8:0</td>
                </tr>
                <tr>
                  <td>Jupiter</td>
                  <td>8:0</td>
                  <td>9:0</td>
                </tr>
                <tr>
                  <td>Mars</td>
                  <td>9:0</td>
                  <td>10:0</td>
                </tr>
                <tr>
                  <td>Sun</td>
                  <td>10:0</td>
                  <td>11:0</td>
                </tr>
                <tr>
                  <td>Venus</td>
                  <td>11:0</td>
                  <td>12:0</td>
                </tr>
                <tr>
                  <td>Mercury</td>
                  <td>12:0</td>
                  <td>13:0</td>
                </tr>
                <tr>
                  <td>Moon</td>
                  <td>13:0</td>
                  <td>14:0</td>
                </tr>
                <tr>
                  <td>Saturn</td>
                  <td>14:0</td>
                  <td>15:0</td>
                </tr>
                <tr>
                  <td>Jupiter</td>
                  <td>15:0</td>
                  <td>16:0</td>
                </tr>
                <tr>
                  <td>Mars</td>
                  <td>16:0</td>
                  <td>17:0</td>
                </tr>
                <tr>
                  <td>Sun</td>
                  <td>17:0</td>
                  <td>18:0</td>
                </tr>
                <tr>
                  <td>Venus</td>
                  <td>18:0</td>
                  <td>19:0</td>
                </tr>
                <tr>
                  <td>Mercury</td>
                  <td>19:0</td>
                  <td>20:0</td>
                </tr>
                <tr>
                  <td>Moon</td>
                  <td>20:0</td>
                  <td>21:0</td>
                </tr>
                <tr>
                  <td>Saturn</td>
                  <td>21:0</td>
                  <td>22:0</td>
                </tr>
                <tr>
                  <td>Jupiter</td>
                  <td>22:0</td>
                  <td>23:0</td>
                </tr>
                <tr>
                  <td>Mars</td>
                  <td>23:0</td>
                  <td>0:0</td>
                </tr>
                <tr>
                  <td>Sun</td>
                  <td>0:0</td>
                  <td>1:0</td>
                </tr>
                <tr>
                  <td>Venus</td>
                  <td>1:0</td>
                  <td>2:0</td>
                </tr>
                <tr>
                  <td>Mercury</td>
                  <td>2:0</td>
                  <td>3:0</td>
                </tr>
                <tr>
                  <td>Moon</td>
                  <td>3:0</td>
                  <td>4:0</td>
                </tr>
                <tr>
                  <td>Saturn</td>
                  <td>4:0</td>
                  <td>5:0</td>
                </tr>
                <tr>
                  <td>Jupiter</td>
                  <td>5:0</td>
                  <td>6:0</td>
                </tr>
              </tbody></table>    
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
{/*  */}
    </>
  )
}

export default Allpopups
