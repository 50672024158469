import React, { useContext, useEffect, useState } from "react";
import Cookies from "js-cookie";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NotFound from "../NotFound/NotFound";
import { fetchAllLiveAstrologers } from "../../AllApi/AllApi";
import { domainName } from "../Url/Url";
import { useNavigate } from "react-router-dom";

const LiveSimillarAstrologers = (props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [allLiveAstrologersData, setAllLiveAstrologersData] = useState([]);
  const navigateToSingleLiveAstrologer = (data) => {
    const formattedData = data?.split(" ")?.join("-");

    navigate(`/live/${formattedData}`);

    // Reload the page after a short delay
    setTimeout(() => {
      window.location.reload();
    }, 100); // Adjust the delay as needed
  };

  function toggleSimillarLoginModal() {
    props?.closeModal();
    // window.location.reload();
  }

  async function allLiveAstrologers() {
    const res = await fetchAllLiveAstrologers();
    if (res?.success == true) {
      setAllLiveAstrologersData(res?.data);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }
  useEffect(() => {
    allLiveAstrologers();
  }, []);
  return (
    <>
      <ToastContainer />
      <div className="simillarlive_astrloger_popup_div_main">
      <div
        className="modal fade show"
        id="login"
        tabIndex={-1}
        style={{ display: "block" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                <i className="fa fa-users " />
                Simillar Live Astrologers
              </h5>
              <button
                type="button"
                className="fa fa-close"
                onClick={toggleSimillarLoginModal}
              />
            </div>
            <div className="modal-body p-0 m-0">
            

              <div className="container">
                <div className="row">
                {" "}
                {allLiveAstrologersData != null ? (

                    
                  allLiveAstrologersData?.map((ele, index) => (
                    <>
                      <div className="col-xl-4 col-xxl-4 col-lg-3 col-md-4 col-sm-12 col-12 mb-1">
                        <div
                          className="chat-call-card border-0 py-1"
                          onClick={() =>
                            navigateToSingleLiveAstrologer(ele?.name)
                          }
                          style={{
                            position: "relative",
                            // backgroundImage: `url('https://healfate.com/astro_docs/9549portrait-of-a-30-year-old-astrologer-gaze-fixed-on-a-star-map-spread-out-in-the-dark-subtle-glow-o-107700922.jpeg')`,
                          }}
                        >
                          <a
                            href="#"
                            className="position-relative"
                            style={{ zIndex: 1 }}
                          >
                            <div className="d-flex align-items-center justify-content-center">
                              <div
                                style={{
                                  height: "61px",
                                  width: "63px",
                                  borderRadius: "50%",
                                  // border: "1px solid #804C35",
                                  overflow: "hidden",
                                  position: "relative",
                                  zIndex: 2,
                                  backgroundColor: "#fff",
                                }}
                                className="d-flex align-items-center justify-content-center"
                              >
                                <img
                                  src={`https://healfate.com/astro_docs/${ele?.profile}`}
                                  alt="Cover Image"
                                  style={{
                                    height: "100%",
                                    width: "100%",
                                    objectFit: "cover",
                                    filter: "brightness(1.2) contrast(1.2)",
                                    transition: "transform 0.3s ease",
                                  }}
                                  onMouseEnter={(e) =>
                                    (e.currentTarget.style.transform =
                                      "scale(1.1)")
                                  }
                                  onMouseLeave={(e) =>
                                    (e.currentTarget.style.transform =
                                      "scale(1)")
                                  }
                                />
                                <div
                                  className="d-flex align-items-center justify-content-center"
                                  style={{
                                    position: "absolute",
                                    bottom: "6px",
                                    color: "white",
                                    fontSize: "10px",
                                    fontWeight: "bold",
                                    textShadow: "1px 1px 2px black",
                                  }}
                                >
                                  <img
                                    src={`${domainName}/images/ic_online.gif`}
                                    height={5}
                                    width={5}
                                    alt="Live Indicator"
                                  />
                                  Live
                                </div>
                              </div>
                            </div>
                          </a>
                          <h1 className="text-black text-center  text-black" style={{fontSize:"11px",fontWeight:"800"}}>
                            {ele?.name}
                          </h1>
                        </div>
                      </div>
                    </>
                  ))
                ) : (
                  <NotFound />
                )}
                 </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default LiveSimillarAstrologers;
