import React from 'react'
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../CssFiles/Payment.css';

const Payment = () => {
  return (
    <>
     {/* ========= */}
 <Header/>
    {/* ====== */}
      <section className="payment_logs_section">
  <div className="container">
    <div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
        {/**/}
        <div className="payment_tab_main_div">
          <ul className="nav nav-pills mb-2" id="pills-tab" role="tablist">
            <li className="nav-item" role="presentation">
              <button className="nav-link active" id="pills-wallet-tab" data-bs-toggle="pill" data-bs-target="#pills-wallet" type="button" role="tab" aria-controls="pills-wallet" aria-selected="true">Wallet Transactions</button>
            </li>
            <li className="nav-item" role="presentation">
              <button className="nav-link" id="pills-paylog-tab" data-bs-toggle="pill" data-bs-target="#pills-paylog" type="button" role="tab" aria-controls="pills-paylog" aria-selected="false">Payment Logs</button>
            </li>
          </ul>
          {/**/}
          <div className="tab-content" id="pills-tabContent">
            <div className="tab-pane fade show active" id="pills-wallet" role="tabpanel" aria-labelledby="pills-wallet-tab">
              <div className="payment_log_table">
                <h3 style={{fontSize: 20, color: '#df2f2d'}}>Available balance: ₹ 50,015.50 <a href="wallet.php" style={{margin: '0px 10px', border: '1px solid #df2f2d', padding: '6px 20px 6px 20px', borderRadius: 100, fontSize: 17, backgroundColor: '#df2f2d', color: '#fff'}}>Recharge</a></h3>
                <div className="payment_log_table_scroll">
                  <table className="table table-bordered table-striped table-sm mt-3" style={{textAlign: 'center'}}>
                    <tbody><tr>
                        <th colSpan={2}>Description	</th> 
                        <th>Transaction Amount	</th>
                        <th>Datetime	</th>
                        {/*<th>Actions</th>*/}
                      </tr>
                      <tr>
                        <td>Gift send  to Akesh Yadav</td>
                        <td>                    </td>
                        <td style={{color: 'red'}}>-501.00</td>
                        <td>25 June 2024 16:44 PM</td>
                        {/* <td>*/}
                        {/*    <a href="payment.php?txnid=MTQ0NA==" style="font-size: 14px; color: #df2f2d;" onclick="return confirm('Are you sure you want to delete this transaction?');" class="fa fa-trash"></a>*/}
                        {/*</td>*/}
                      </tr>
                      <tr>
                        <td>Chat with Akesh Yadav</td>
                        <td>                    </td>
                        <td style={{color: 'red'}}>-393.33</td>
                        <td>25 June 2024 16:44 PM</td>
                        {/* <td>*/}
                        {/*    <a href="payment.php?txnid=MTQ0Mw==" style="font-size: 14px; color: #df2f2d;" onclick="return confirm('Are you sure you want to delete this transaction?');" class="fa fa-trash"></a>*/}
                        {/*</td>*/}
                      </tr>
                      <tr>
                        <td>Chat with Akesh Yadav</td>
                        <td>                    </td>
                        <td style={{color: 'red'}}>-90.00</td>
                        <td>25 June 2024 16:02 PM</td>
                        {/* <td>*/}
                        {/*    <a href="payment.php?txnid=MTQzNw==" style="font-size: 14px; color: #df2f2d;" onclick="return confirm('Are you sure you want to delete this transaction?');" class="fa fa-trash"></a>*/}
                        {/*</td>*/}
                      </tr>
                      <tr>
                        <td>Chat with Sukriti Sharma</td>
                        <td>                    </td>
                        <td style={{color: 'red'}}>-513.29</td>
                        <td>20 June 2024 13:16 PM</td>
                        {/* <td>*/}
                        {/*    <a href="payment.php?txnid=OTIz" style="font-size: 14px; color: #df2f2d;" onclick="return confirm('Are you sure you want to delete this transaction?');" class="fa fa-trash"></a>*/}
                        {/*</td>*/}
                      </tr>
                      <tr>
                        <td>Extra</td>
                        <td>                    </td>
                        <td style={{color: 'green'}}>+5,000.00</td>
                        <td>11 June 2024 15:32 PM</td>
                        {/* <td>*/}
                        {/*    <a href="payment.php?txnid=MzY5" style="font-size: 14px; color: #df2f2d;" onclick="return confirm('Are you sure you want to delete this transaction?');" class="fa fa-trash"></a>*/}
                        {/*</td>*/}
                      </tr>
                      <tr>
                        <td>Purchase via Razorpay</td>
                        <td>                      <a href="/invoice/generate.php?invoice=pay_OLPhYQ2ICCZVpG" target="_blank" className="btn btn-link">Invoice</a> 
                        </td>
                        <td style={{color: 'green'}}>+59,000.00</td>
                        <td>11 June 2024 15:32 PM</td>
                        {/* <td>*/}
                        {/*    <a href="payment.php?txnid=MzY4" style="font-size: 14px; color: #df2f2d;" onclick="return confirm('Are you sure you want to delete this transaction?');" class="fa fa-trash"></a>*/}
                        {/*</td>*/}
                      </tr>
                      <tr>
                        <td>Extra</td>
                        <td>                    </td>
                        <td style={{color: 'green'}}>+500.00</td>
                        <td>10 June 2024 13:14 PM</td>
                        {/* <td>*/}
                        {/*    <a href="payment.php?txnid=MzAw" style="font-size: 14px; color: #df2f2d;" onclick="return confirm('Are you sure you want to delete this transaction?');" class="fa fa-trash"></a>*/}
                        {/*</td>*/}
                      </tr>
                      <tr>
                        <td>Purchase via Razorpay</td>
                        <td>                      <a href="/invoice/generate.php?invoice=pay_OKypMzuRnMhPLv" target="_blank" className="btn btn-link">Invoice</a> 
                        </td>
                        <td style={{color: 'green'}}>+5,900.00</td>
                        <td>10 June 2024 13:14 PM</td>
                        {/* <td>*/}
                        {/*    <a href="payment.php?txnid=Mjk5" style="font-size: 14px; color: #df2f2d;" onclick="return confirm('Are you sure you want to delete this transaction?');" class="fa fa-trash"></a>*/}
                        {/*</td>*/}
                      </tr>
                      <tr>
                        <td>Chat with Acharya Pawan Sharma</td>
                        <td>                    </td>
                        <td style={{color: 'red'}}>-420.21</td>
                        <td>08 June 2024 17:32 PM</td>
                        {/* <td>*/}
                        {/*    <a href="payment.php?txnid=MjMx" style="font-size: 14px; color: #df2f2d;" onclick="return confirm('Are you sure you want to delete this transaction?');" class="fa fa-trash"></a>*/}
                        {/*</td>*/}
                      </tr>
                      <tr>
                        <td>Chat with Acharya Anand</td>
                        <td>                    </td>
                        <td style={{color: 'red'}}>-10,200.00</td>
                        <td>07 June 2024 17:53 PM</td>
                        {/* <td>*/}
                        {/*    <a href="payment.php?txnid=MTA5" style="font-size: 14px; color: #df2f2d;" onclick="return confirm('Are you sure you want to delete this transaction?');" class="fa fa-trash"></a>*/}
                        {/*</td>*/}
                      </tr>
                      <tr>
                        <td>Chat with Acharya Anand</td>
                        <td>                    </td>
                        <td style={{color: 'red'}}>-200.00</td>
                        <td>07 June 2024 16:28 PM</td>
                        {/* <td>*/}
                        {/*    <a href="payment.php?txnid=OTQ=" style="font-size: 14px; color: #df2f2d;" onclick="return confirm('Are you sure you want to delete this transaction?');" class="fa fa-trash"></a>*/}
                        {/*</td>*/}
                      </tr>
                      <tr>
                        <td>Call with Sukriti Sharma</td>
                        <td>                    </td>
                        <td style={{color: 'red'}}>-264.00</td>
                        <td>07 June 2024 14:22 PM</td>
                        {/* <td>*/}
                        {/*    <a href="payment.php?txnid=NjY=" style="font-size: 14px; color: #df2f2d;" onclick="return confirm('Are you sure you want to delete this transaction?');" class="fa fa-trash"></a>*/}
                        {/*</td>*/}
                      </tr>
                      <tr>
                        <td>Call with Sukriti Sharma</td>
                        <td>                    </td>
                        <td style={{color: 'red'}}>-552.00</td>
                        <td>07 June 2024 14:14 PM</td>
                        {/* <td>*/}
                        {/*    <a href="payment.php?txnid=NjI=" style="font-size: 14px; color: #df2f2d;" onclick="return confirm('Are you sure you want to delete this transaction?');" class="fa fa-trash"></a>*/}
                        {/*</td>*/}
                      </tr>
                      <tr>
                        <td>Call with Sukriti Sharma</td>
                        <td>                    </td>
                        <td style={{color: 'red'}}>-240.00</td>
                        <td>07 June 2024 13:13 PM</td>
                        {/* <td>*/}
                        {/*    <a href="payment.php?txnid=NjE=" style="font-size: 14px; color: #df2f2d;" onclick="return confirm('Are you sure you want to delete this transaction?');" class="fa fa-trash"></a>*/}
                        {/*</td>*/}
                      </tr>
                      <tr>
                        <td>Call with Sukriti Sharma</td>
                        <td>                    </td>
                        <td style={{color: 'red'}}>-960.00</td>
                        <td>07 June 2024 13:11 PM</td>
                        {/* <td>*/}
                        {/*    <a href="payment.php?txnid=NjA=" style="font-size: 14px; color: #df2f2d;" onclick="return confirm('Are you sure you want to delete this transaction?');" class="fa fa-trash"></a>*/}
                        {/*</td>*/}
                      </tr>
                      <tr>
                        <td>Call with Sukriti Sharma</td>
                        <td>                    </td>
                        <td style={{color: 'red'}}>-336.00</td>
                        <td>07 June 2024 13:10 PM</td>
                        {/* <td>*/}
                        {/*    <a href="payment.php?txnid=NTg=" style="font-size: 14px; color: #df2f2d;" onclick="return confirm('Are you sure you want to delete this transaction?');" class="fa fa-trash"></a>*/}
                        {/*</td>*/}
                      </tr>
                      <tr>
                        <td>Call with Sukriti Sharma</td>
                        <td>                    </td>
                        <td style={{color: 'red'}}>-24.00</td>
                        <td>07 June 2024 13:06 PM</td>
                        {/* <td>*/}
                        {/*    <a href="payment.php?txnid=NTQ=" style="font-size: 14px; color: #df2f2d;" onclick="return confirm('Are you sure you want to delete this transaction?');" class="fa fa-trash"></a>*/}
                        {/*</td>*/}
                      </tr>
                      <tr>
                        <td>Call with Sukriti Sharma</td>
                        <td>                    </td>
                        <td style={{color: 'red'}}>-960.00</td>
                        <td>07 June 2024 13:02 PM</td>
                        {/* <td>*/}
                        {/*    <a href="payment.php?txnid=NTM=" style="font-size: 14px; color: #df2f2d;" onclick="return confirm('Are you sure you want to delete this transaction?');" class="fa fa-trash"></a>*/}
                        {/*</td>*/}
                      </tr>
                      <tr>
                        <td>Chat with </td>
                        <td>                    </td>
                        <td style={{color: 'red'}}>-600.00</td>
                        <td>07 June 2024 12:21 PM</td>
                        {/* <td>*/}
                        {/*    <a href="payment.php?txnid=MzI=" style="font-size: 14px; color: #df2f2d;" onclick="return confirm('Are you sure you want to delete this transaction?');" class="fa fa-trash"></a>*/}
                        {/*</td>*/}
                      </tr>
                      <tr>
                        <td>Video Call with Sukriti Sharma</td>
                        <td>                    </td>
                        <td style={{color: 'red'}}>-240.00</td>
                        <td>07 June 2024 12:13 PM</td>
                        {/* <td>*/}
                        {/*    <a href="payment.php?txnid=MzA=" style="font-size: 14px; color: #df2f2d;" onclick="return confirm('Are you sure you want to delete this transaction?');" class="fa fa-trash"></a>*/}
                        {/*</td>*/}
                      </tr>
                    </tbody></table>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="pills-paylog" role="tabpanel" aria-labelledby="pills-paylog-tab">
              <div className="payment_log_table">
                <div className="btn_flx_payment">
                  <a href="wallet.php" className="rch_div">Recharge</a>
                  <a href="payment.php?userid=MzY=" onclick="return confirm('Are you sure you want to delete all transaction?');" className="dlt_div">Delete All</a>
                </div>
                <div className="payment_log_table_scroll">
                  <table className="table table-bordered table-striped table-sm">
                    <tbody><tr>
                        <th colSpan={2}>Datetime</th>
                        <th>Amount</th>
                        <th>Transaction ID</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                      <tr>
                        <td>Recharge</td>
                        <td>28 June 2024 11:12 AM</td>
                        <td>
                          <b className="amnt">25.00</b>
                          <div className="gst_div">(GST 4.50)</div>
                        </td>
                        <td>#</td>
                        <td><b className="fld">Failure</b></td>
                        <td>
                          <a href="payment.php?txn=MjAw" onclick="return confirm('Are you sure you want to delete this transaction?');" className="fa fa-trash" />
                        </td>
                      </tr>
                      <tr>
                        <td>Recharge</td>
                        <td>28 June 2024 11:12 AM</td>
                        <td>
                          <b className="amnt">25.00</b>
                          <div className="gst_div">(GST 4.50)</div>
                        </td>
                        <td>#</td>
                        <td><b className="fld">Failure</b></td>
                        <td>
                          <a href="payment.php?txn=MTk5" onclick="return confirm('Are you sure you want to delete this transaction?');" className="fa fa-trash" />
                        </td>
                      </tr>
                      <tr>
                        <td>Recharge</td>
                        <td>11 June 2024 15:31 PM</td>
                        <td>
                          <b className="amnt">50,000.00</b>
                          <div className="gst_div">(GST 9,000.00)</div>
                        </td>
                        <td>#pay_OLPhYQ2ICCZVpG</td>
                        <td><b style={{color: 'green'}}>Success</b></td>
                        <td>
                          <a href="payment.php?txn=NDE=" onclick="return confirm('Are you sure you want to delete this transaction?');" className="fa fa-trash" />
                        </td>
                      </tr>
                    </tbody></table>
                </div>
              </div>
            </div>
          </div>
          {/**/}
        </div>
        {/**/}
      </div>
    </div>
  </div>
</section>
<Footer/>
    </>
  )
}

export default Payment
