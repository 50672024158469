import React from 'react'
const Talkmodal = (props) => {
console.log(props);
return (
<>
{/* Call Request modal start */}
<div className="callrequest_deatils_popup_div_main">
  <div className="modal fade show" id="callrequest" tabIndex={-1} style={{ display: "block" }}>
  <div className="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title" id="staticBackdropLabel">Call Request</h1>
        <button type="button" className="fa fa-times"  onClick={props?.closeModal} aria-label="Close"></button>
      </div>
      <div className="modal-body">
        <div className="callrequest_detals_table_name">
          <div className="callrequest_img_box">
            {/* <img src={ singleAstrologerLiveSession[0]?.profile == null ? `https://healfate.com/astro_docs/No-image-found.jpg` : `https://healfate.com/astro_docs/${singleAstrologerLiveSession[0]?.profile}`} alt="" className='img-fluid'/> */}
            <h3>Rinku Singh</h3>
          </div>
          <p>Connect and Call with Astrologers Anytime, Anywhere. Download Our App Now! </p>
          <div className="callrequest_img_box_app">
            <img src="../images/android01.png" alt="" className='img-fluid'/>
            <img src="../images/appstore.png" alt="" className='img-fluid'/>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
{/* Call Request modal end */}
</>
)
}
export default Talkmodal