import React from 'react'
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../CssFiles/Feedback.css';

const Feedback = () => {
  return (
    <>
      {/* ========= */}
  <Header/>
    {/* ====== */}
      {/* feedback section start */}
      <section className="feedback_section">
  <div className="container">
     {/*title  */}
     <div className="feedback_title_div">
      <h1>Feedback To Talk Nd Heal</h1>
      <p>Are you a skilled and compassionate astrologer looking to make a positive impact on people's lives? Join our team at Talk nd Heal , where we believe in the transformative power of astrology to guide individuals towards self-discovery and well-being.</p>
    </div>
    {/* title */}
    <div className="row justify-content-center">
    {/* col start */}
    <div className="col-lg-8 col-md-8 col-sm-12 col-12">
       <div className="feedback_form_main_div">
       <h2>Feedback to Talk nd Heal </h2>
      <form className="row g-3 " method="POST" autoComplete="off" encType="multipart/form-data">
 
  <div className="col-md-12">
    <label htmlFor="name" className="form-label">Name <span style={{color: 'red'}}>*</span></label>
    <input type="text" name="name" className="form-control" id="name" placeholder="Name" required />
  </div>
  <div className="col-md-12">
    <label htmlFor="email" className="form-label">Email <span style={{color: 'red'}}>*</span></label>
    <input type="email" name="email" className="form-control" id="txtEmail" placeholder="Email" onkeyup="ValidateEmail();" required />
    <span id="lblError" style={{color: 'red'}} />
  </div>
  <div className="col-md-12">
    <label htmlFor="mobile" className="form-label">Mobile <span style={{color: 'red'}}>*</span></label>
    <input type="text" name="mobile" className="form-control" oninput="this.value = this.value.replace(/[^0-9.+]/g, '').replace(/(\..?)\../g, '$1');" maxLength={10} minLength={10} id="mobile" placeholder="Mobile" required />
  </div> 
  <div className="col-md-12">
    <label htmlFor="place" className="form-label">Textarea  <span style={{color: 'red'}}>*</span></label>
    <textarea className="form-control" name="message" rows={5} placeholder="Type something... (Max 300 character)" maxLength={300} required defaultValue={""} />
  </div>
  <div className="col-12">
    <div className="feedback_btn">
      <button type="submit" name="join" id="Button">Submit </button>
    </div>
  </div>
</form>

       </div>
    </div>
    {/* col end */}
    </div>
  </div>
</section>
<Footer/>
      {/* feedback section end */}
    </>
  )
}

export default Feedback
