import React, { useEffect, useState } from 'react'
import { getSingleAstrologerApi } from '../AllApi/AllApi';
import Cookies from "js-cookie";
import { decryptData } from '../components/Url/Url';

const TalkSuggetionModal = (props) => {
    console.log(props)
    const [singleAstrologerDataShow, setSingleAstrologerDataShow] = useState({});
    const[url,setUrl]= useState();
    async function singleAstrologerShowApi(id) {
        const astroId=id;
        const userid = decryptData(Cookies?.get("user"));
        const reSend = { astroId, userid };
    
        const res = await getSingleAstrologerApi(reSend);
        if (res.success == true) {
          setUrl(res?.url);
          setSingleAstrologerDataShow(res.data);
      
        
        
        }
      }
      useEffect(() => {
        singleAstrologerShowApi();
      }, []);
  return (
    <>
    <div className="callrequest_deatils_popup_div_main">
        <div className="modal fade show" id="login" tabIndex={-1} style={{ display: "block" }}>
        <div className="modal-dialog">
        <div className="modal-content">
        <div className="modal-header">
        <h5 className="modal-title" style={{ fontSize: "16px" }}>
        {props?.data} Request
        </h5>
        <button type="button" className="fa fa-close"  onClick={props?.closeModal} />
        </div>
        <div className="modal-body">
        <div className="d-flex gap-5 align-items-center justify-content-center  mb-2">
        <div className="">
        <div className="d-flex align-items-center justify-content-center">
        <img
        src={
        singleAstrologerDataShow?.profile == null
        ? `${url}/No-image-found.jpg`
        : `${url}/${singleAstrologerDataShow?.profile}`
        }
        style={{ width: "95px", height: "95px", borderRadius: "50%" }}
        ></img>
        </div>
        <label className="text-start text-black text-center" style={{fontSize:"18px"}}>{singleAstrologerDataShow.name}</label>
        </div>
        </div>
        <h3  className='text-center' style={{fontSize:"15px"}}>Connect and Call with Astrologers Anytime, Anywhere. Download Our App Now!</h3>
        <div className='d-flex gap-2 align-items-center justify-content-center'>
        <a href="https://play.google.com/store/apps/details?id=com.talkndheal.com&hl=en" target="_blank" rel="noopener noreferrer">
        <img src="https://healfate.com/assets/img/android01.png" style={{width: "140px", height: "40px"}} alt="Download from Play Store" />
        </a>
        <a href="https://apps.apple.com/us/app/immortal-varta/id6468341765" target="_blank" rel="noopener noreferrer">
        <img src="https://healfate.com/assets/img/appstore.png" style={{width:"140px",height:"40px"}} alt="Download from App Store" />
        </a>
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>
        </>
  )
}

export default TalkSuggetionModal