import React, { useContext, useEffect, useState } from "react";
import Sidenavbar from './Sidenavbar';
import Cookies from "js-cookie";
import { getUserDetailsApi } from "../../src/AllApi/AllApi";
import MyContext from "../Context/MyContext";
import Login2 from "../components/Authentication/Login2";
import { Link, Navigate, useNavigate } from "react-router-dom";
import WebShare from "../components/WebShare/WebShare";
import videoCallImage from "../components/assets/img/videoCall1.png";
import voiceCallImage from "../components/assets/img/audiocall.png";
import chatImage from "../components/assets/img/chat.png";
import { decrypt } from "../Utils/Utils";
import { LuWallet } from "react-icons/lu";
import { TiPencil } from "react-icons/ti";
import './Header2.css';
import { cookieDomain, decryptData, domainName, isAuthenticated, isUserId } from "./Url/Url";
import Login from "./Login/Login";
export let userId = ''; // Export the userId variable
const Header2 = () => {

const [userId, setUserId] = useState('');


const navigate = useNavigate()
const contents = [
{
text: "Videocall with astrologer",
image: videoCallImage,
link: "/video",
},
{
text: "Call with astrologer",
image: voiceCallImage,
link: "/call",
},
{
text: "Chat with astrologer",
image: chatImage,
link: "/chat",
},
];

const [currentIndex, setCurrentIndex] = useState(0);

useEffect(() => {
const intervalId = setInterval(() => {
setCurrentIndex((prevIndex) => (prevIndex + 1) % contents.length);
}, 3000);

return () => clearInterval(intervalId);
}, [contents.length]);

const formatAmount = (amount) => {
return new Intl.NumberFormat("en-IN").format(amount);
};

const [url, setUrl] = useState();
// const { openSearchBarData } = useContext(MyContext);
const [openSearchPopup, setOpenSearchPopup] = useState(false);
const [toggleSearch, setToggleSearch] = useState(false);
// const { updateMobileSearch } = useContext(MyContext);
// useEffect(() => {
// // updateMobileSearch(toggleSearch); // Update mobile search whenever searchState changes
// }, [toggleSearch]);
// function toggleSearchBar() {
// setToggleSearch(!toggleSearch);
// // updateMobileSearch(true)
// }

const [loginRes, setLoginRes] = useState("");

console.warn(loginRes)

const [isOpen, setIsOpen] = useState(false);
const [isOpenLoginModal, setOpenLoginModal] = useState(false);
function toggleOpenLoginModal() {
setOpenLoginModal(true);
}

const toggleDropdown = () => {
setDropdownOpen(!dropdownOpen);
};

const [dropdownOpen, setDropdownOpen] = useState(null);

// const toggleDropdown = (id) => {
//   setDropdownOpen(dropdownOpen === id ? null : id);
// };

const [openLessBalanceModel, setLessBalanceModel] = useState(false);
// const { updateUserDetails } = useContext(MyContext);

function openBalanceModel() {
setLessBalanceModel(true);
}
function closeBalanceModel() {
setLessBalanceModel(false);
}

const [showModal, setShowModal] = useState(false);

const toggleModal = () => {
setShowModal(!showModal);
};
async function userDetails(){

    const decToken= isAuthenticated  
    const userId=decryptData(isUserId)
    console.error(decToken)
    const  res= await getUserDetailsApi(decToken,userId)
    setLoginRes(res)
    
    }
    useEffect(()=>{
    userDetails()
    },[])

const handleShare = () => { };


function defaultlogOut() {
  
Cookies.remove("token", {
domain: cookieDomain, // Specify the domain of the cookie to remove

});
window.location.reload()
}



const locationUrl = window.location.pathname;
console.log('Full URL Pathname:', locationUrl);

const urlSegments = locationUrl.split('/');
console.log('URL Segments:', urlSegments);


const splitedastrologersDetails = urlSegments[1]; // Index 3 contains 'astrologersDetails'





function searchFunctionStart() {
// setOpenSearchBar(!openSearchBar)

setOpenSearchPopup(!openSearchPopup);
// openSearchBarData(openSearchPopup);
}



return (
<>


{/* logo navbar section start */}
<div className="header_wrapper_div_main">
<section className="logo_navbar2">
<div className="container">
<div className="row">
{/* col start */}
<div className="col-lg-12 col-12">
<div className="logo_navr_main_div2">

<div className="logo_div2">
<Link to="/">
<img
// src="https://healfate.com/assets/img/logo3.png"
src={require('../components/assets/img/talkndheal_logo.png')}
alt
className="img"
/>
</Link>
</div>

<div className="desktop_view">
<Link to={contents[currentIndex].link}>
<div
className="d-flex align-items-center"
style={{
padding: "3px 10px",
borderRadius: "15px",
color: "#804C35",
border: "1px solid #804C35",
}}
>
<img
src={contents[currentIndex].image}
alt={contents[currentIndex].text}
style={{ height: "13px", width: "13px" }}
/>
<p className="m-0 px-1" style={{ fontWeight: "600" }}>{contents[currentIndex].text}</p>
<img src={`${domainName}/images/ic_online.gif`} alt="" style={{ height: "12px", width: "12px" }}></img>
</div>
</Link>
</div>

<div className="logo_navlinks2 d-flex align-items-center justify-content-center">

<Link to="/">Home</Link>
<Link to="https://healfate.com/orderhistory.php">History</Link>

{isAuthenticated != null && (
<Link to="#" className="">
<div class="dropdown profile_dropdown">
<div
className="d-flex align-items-center"
id="dropdownMenuButton"
data-toggle="dropdown"
aria-haspopup="true"
aria-expanded="false"
>
<span>
  {" "}
  {loginRes?.data?.name}
</span>
<span>
  <i class="fs-6 bx bx-chevron-down"></i>
</span>
</div>
<div
class="dropdown-menu mt-2"
aria-labelledby="dropdownMenuButton"
style={{ zIndex: "2000", fontSize: "5px", width: "200px" }}
>
<ul>
  <li>
      <Link
          className="dropdown-item"
          to="/userprofile"
      >
          Profile
      </Link>
  </li>
  <li>
      <Link
          className="dropdown-item"
          to="/notifications"
      >
          Notification
      </Link>
  </li>
  <li>
      <Link
          className="dropdown-item"
          to="/payment"
      >
          Wallet Transactions
      </Link>
  </li>
  <li>
      <Link
          className="dropdown-item"
          to="/orderhistory"
      >
          Order History
      </Link>
  </li>             
  <li>
      <Link
          className="dropdown-item"
          to="/ticket"
      >
          Customer Support
      </Link>
  </li>
  <li>
      <Link
          className="dropdown-item"
          to="/blockedastro"
      >
          Reported Astrologers
      </Link>
  </li>
</ul>
</div>
</div>
</Link>
)}

{isAuthenticated == null ? (
    <div className="dropdown sign_login">
<Link to="#" className="dropdown-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" onClick={toggleOpenLoginModal}>Sign In</Link>
<ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
    <li><a class="dropdown-item" href="#">Login</a></li>
  </ul>
</div>

) : (
<Link to="" onClick={defaultlogOut}>Sign Out</Link>
)}
{isAuthenticated != null && (
<Link to="/wallet"
style={{
display: "flex",
gap: "4px",
alignItems: "center",
justifyContent:'center',
border: "1px solid #804C35",
padding: "1px 5px",
color: "#804C35",
borderRadius: "8px",
}}
>
<b className="navbar_wallet_img_box">
<LuWallet className="text-danger fs-5" />
</b>
₹ {formatAmount(loginRes?.wallet)} 
</Link>
)}

<Link to="#">

<div
className="d-flex h-100 align-items-center justify-content-center "
style={{ marginTop: "5px" }}
>
<WebShare />
</div>
</Link>
</div>

{/* <div className="sign_in_btn">
<a href="#" role="button" className="dropdown-toggle" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">Anand (7678256397)</a>
<ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
<li>
<a
className="dropdown-item"
href="https://healfate.com/user-profile"
>
Profile
</a>
</li>
<li>
<a
className="dropdown-item"
href="https://healfate.com/notifications"
>
Notification
</a>
</li>
<li>
<a className="dropdown-item" href="https://healfate.com/payment">
Wallet Transactions
</a>
</li>
<li>
<a
className="dropdown-item"
href="https://healfate.com/orderhistory"
>
Order History
</a>
</li>
<li>
<a className="dropdown-item" href="https://healfate.com/contact">
Customer Support
</a>
</li>
<li>
<a
className="dropdown-item"
href="https://healfate.com/blockedastro"
>
Reported Astrologers
</a>
</li>
<li>

<a className="dropdown-item" href="" onClick={defaultlogOut}>Sign Out</a>
</li>
</ul>
</div> */}


<div className="navbar_button_div">
{/* astrologer Details Page hide it  */}

<div className="navbar_button_mflx d-flex justify-content-between align-items-center">
<div className=" d-flex justify-content-between align-items-center gap-2 ">
{isAuthenticated != null && (
<Link to="/wallet"
style={{
  display: "flex",
  gap: "4px",
  alignItems: "center",
  justifyContent:'center',
  border: "1px solid #804c35",
  padding: "1px 3px",
  color: "df2f2d",
  borderRadius: "11px",
  fontSize: "16px",
}}
className=""
>
<b className="navbar_wallet_img_box">
  <LuWallet className="text-danger" />
  {/* <img src="https://healfate.com/assets/img/wallet.svg" height={50} width={70} alt /> */}
</b>
<span style={{ color: "#804c35" }}> ₹ </span>
<b
  className="navbar_amount_div"
  style={{ color: "#804c35" }}
>
  {formatAmount(loginRes?.wallet)}
</b>
</Link>
)}

{splitedastrologersDetails!='astrologersDetails' &&   <div className="" >
<i className="mobile_search_icon" aria-hidden="true">
{/* <img src="https://healfate.com/assets/img/search.svg" alt /> */}
<img
  src={require("../components/assets/img/search.png")}
  alt=""/>
</i>
</div>}
{/* <div className="webshare">
<WebShare />
</div> */}
<div className="barsHeader">
<button
className="fa fa-bars"
data-bs-toggle="offcanvas"
data-bs-target="#offcanvasWithBothOptions"
aria-controls="offcanvasWithBothOptions"
/>
</div>
</div>
</div>

{/* astrologer Details Page hide it  */}



</div>
</div>
</div>
{/* col end */}
</div>
</div>



</section>
{/* logo navbar section end */}
{/* navbar section */}
<section className="navbar_section_navbar">
<nav className="navbar navbar-expand-lg bg-light sticky-top">
<div className="container">
<div
className="collapse navbar-collapse align-items-center justify-content-center"
id="navbarNavDropdown"
>
<ul className="navbar-nav navbar-childs">
<li className="nav-item"><Link className="nav-link active" aria-current="page" to="/freeKundali">Kundli</Link></li>
<li className="nav-item">
<Link className="nav-link" to="/kundliMatching">Kundli Matching</Link></li>
{/* mega menu */}
<li class="nav-item dropdown">
<Link class="nav-link" to="#" role="button" data-bs-toggle="dropdown"aria-expanded="false">Horoscopes <span class="fa fa-angle-down"></span></Link>

<ul class="dropdown-menu">
<Link to="/horoscope/today-horoscope">Today's Horoscope</Link>
<Link to="/horoscope/tomorrow-horoscope">Tomorrow's Horoscope</Link>
<Link to="/horoscope/monthly-horoscope">Monthly Horoscope</Link>
</ul>
</li>
{/* mega menu end*/}
{/* <li className="nav-item">
<a className="nav-link" href="https://healfate.com/prashnaKundli">
Prashna Kundli
</a>
</li> */}
<li className="nav-item"><Link className="nav-link" to="/panchang">Panchang</Link></li>
<li className="nav-item"><Link className="nav-link" to="/chat">Chat with Astrologer
</Link></li>
<li className="nav-item"><Link className="nav-link" to="/call">Call with Astrologer
</Link></li>
<li className="nav-item"><Link className="nav-link" to="/video">Video call with Astrologer</Link></li>
<li className="nav-item"><Link className="nav-link" to="/joinAsastrologer">
Join As An Astrologer</Link></li>
<li className="nav-item"><Link className="nav-link" to="/samuhikpuja">Samuhik Puja
</Link></li>
</ul>
</div>
</div>
</nav>
</section>
</div>
{/* navbar section end */}
{/*  */}
<Sidenavbar/>
{/*  */}

{isOpenLoginModal && <Login closeModal={toggleOpenLoginModal} />}
</>
);
};

export default Header2;
