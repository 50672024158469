import React from 'react'
import { Link } from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel';
import { complimentaryastrology } from '../data/Owlcarouseloptions'
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../CssFiles/LalKitab.css';


const LalKitab = () => {
  return (
    <>
    {/* ========= */}
 <Header/>
    {/* ====== */}
<section className="lalkitab_section">
<div className="container">
{/* title */}
<div className="lalkitab_tittle_div">
<h1>Lal Kitab</h1>
<h2>Find your right one, through matchmaking</h2>
<hr />
</div>
{/* title end */}
{/* row start */}
<div className="row">
{/* col start */}
<div className="col-lg-12 col-md-12 col-sm-12 col-12">
<div className="lalkitab_txt_div">
<h3>Free Match Making - Kundli Milan & Gun Milan To Check Possibilities Of Marriage</h3>
<p>Kundli milan or kundali matching is an important consideration to make when you decide to get married. Kundli matching, also called Gun matching or Horoscope matching is the first step towards marriage when the parents decide to match the kundlis of the girl and the boy to ensure the couple is compatible. The gun milan exercise has been a part of India's culture for 1000s of years now and continues to be so.</p>
</div>
</div>
{/* col end */}
</div>
{/* row end */}

{/* row start */}
<div className="row justify-content-center">
{/* col start */}
<div className="col-lg-7 col-md-7 col-sm-12 col-12">
<form action="" method="POST" id="myForm" autofill="off" onsubmit="validateForm()">
<div className="lalkitabbirth_form_main_div">
{/* nested row start */}
<div className="row">
{/* nested col start */}
<div className="col-lg-12">
<div className="lalkitabform_title">
<h4>Get Your Report</h4>
<hr/>
</div>
</div>
{/* nested col end */}
{/* nested col start */}
<div className="col-lg-6 col-md-6 col-sm-12 col-12">
<label>Name</label>
<div className="input-group mb-3">
<select className="slct_form" name="title" id="inputGroupSelect01" onchange="updateGender()">
<option selected disabled>Select One</option>
<option value="Mr" selected>Mr.</option>
<option value="Ms">Ms. </option>
<option value="Mrs">Mrs. </option>
</select>
<input type="text" className="form-control" id="name" name="name" placeholder="Name" required />
</div>
</div>
{/* nested col end */}
{/* nested col start */}
<div className="col-lg-6 col-md-6 col-sm-12 col-12">
<label >Gender</label>
<select id="inputState" class="form-select" name="gender" required>
<option value="Male" selected>Male</option>
<option value="Female">Female</option>
</select>
</div>
{/* nested col end */}
{/* nested col start */}
<div className="col-md-6">
<label>Date Of Birth</label>
<div className="dob-inputs">
<select className="form-select" name="day" required>
<option disabled>Day</option>
<option value={1}>1</option>
<option value={2}>2</option>
<option value={3}>3</option>
<option value={4}>4</option>
<option value={5}>5</option>
<option value={6}>6</option>
<option value={7}>7</option>
<option value={8}>8</option>
<option value={9}>9</option>
<option value={10}>10</option>
<option value={11}>11</option>
<option value={12}>12</option>
<option value={13}>13</option>
<option value={14}>14</option>
<option value={15}>15</option>
<option value={16}>16</option>
<option value={17}>17</option>
<option value={18}>18</option>
<option value={19}>19</option>
<option value={20}>20</option>
<option value={21}>21</option>
<option value={22}>22</option>
<option value={23}>23</option>
<option value={24}>24</option>
<option value={25} selected>25</option>
<option value={26}>26</option>
<option value={27}>27</option>
<option value={28}>28</option>
<option value={29}>29</option>
<option value={30}>30</option>
<option value={31}>31</option>
</select>
<select className="form-select" name="month" required>
<option disabled>Month</option>
<option value={1}>Jan</option>
<option value={2}>Feb</option>
<option value={3}>Mar</option>
<option value={4}>Apr</option>
<option value={5}>May</option>
<option value={6} selected>Jun</option>
<option value={7}>Jul</option>
<option value={8}>Aug</option>
<option value={9}>Sep</option>
<option value={10}>Oct</option>
<option value={11}>Nov</option>
<option value={12}>Dec</option>
</select>
<select className="form-select" name="year" required>
<option disabled>Year</option>
<option value={1900}>1900</option>
<option value={1901}>1901</option>
<option value={1902}>1902</option>
<option value={1903}>1903</option>
<option value={1904}>1904</option>
<option value={1905}>1905</option>
<option value={1906}>1906</option>
<option value={1907}>1907</option>
<option value={1908}>1908</option>
<option value={1909}>1909</option>
<option value={1910}>1910</option>
<option value={1911}>1911</option>
<option value={1912}>1912</option>
<option value={1913}>1913</option>
<option value={1914}>1914</option>
<option value={1915}>1915</option>
<option value={1916}>1916</option>
<option value={1917}>1917</option>
<option value={1918}>1918</option>
<option value={1919}>1919</option>
<option value={1920}>1920</option>
<option value={1921}>1921</option>
<option value={1922}>1922</option>
<option value={1923}>1923</option>
<option value={1924}>1924</option>
<option value={1925}>1925</option>
<option value={1926}>1926</option>
<option value={1927}>1927</option>
<option value={1928}>1928</option>
<option value={1929}>1929</option>
<option value={1930}>1930</option>
<option value={1931}>1931</option>
<option value={1932}>1932</option>
<option value={1933}>1933</option>
<option value={1934}>1934</option>
<option value={1935}>1935</option>
<option value={1936}>1936</option>
<option value={1937}>1937</option>
<option value={1938}>1938</option>
<option value={1939}>1939</option>
<option value={1940}>1940</option>
<option value={1941}>1941</option>
<option value={1942}>1942</option>
<option value={1943}>1943</option>
<option value={1944}>1944</option>
<option value={1945}>1945</option>
<option value={1946}>1946</option>
<option value={1947}>1947</option>
<option value={1948}>1948</option>
<option value={1949}>1949</option>
<option value={1950}>1950</option>
<option value={1951}>1951</option>
<option value={1952}>1952</option>
<option value={1953}>1953</option>
<option value={1954}>1954</option>
<option value={1955}>1955</option>
<option value={1956}>1956</option>
<option value={1957}>1957</option>
<option value={1958}>1958</option>
<option value={1959}>1959</option>
<option value={1960}>1960</option>
<option value={1961}>1961</option>
<option value={1962}>1962</option>
<option value={1963}>1963</option>
<option value={1964}>1964</option>
<option value={1965}>1965</option>
<option value={1966}>1966</option>
<option value={1967}>1967</option>
<option value={1968}>1968</option>
<option value={1969}>1969</option>
<option value={1970}>1970</option>
<option value={1971}>1971</option>
<option value={1972}>1972</option>
<option value={1973}>1973</option>
<option value={1974}>1974</option>
<option value={1975}>1975</option>
<option value={1976}>1976</option>
<option value={1977}>1977</option>
<option value={1978}>1978</option>
<option value={1979}>1979</option>
<option value={1980}>1980</option>
<option value={1981}>1981</option>
<option value={1982}>1982</option>
<option value={1983}>1983</option>
<option value={1984}>1984</option>
<option value={1985}>1985</option>
<option value={1986}>1986</option>
<option value={1987}>1987</option>
<option value={1988}>1988</option>
<option value={1989}>1989</option>
<option value={1990}>1990</option>
<option value={1991}>1991</option>
<option value={1992}>1992</option>
<option value={1993}>1993</option>
<option value={1994}>1994</option>
<option value={1995}>1995</option>
<option value={1996}>1996</option>
<option value={1997}>1997</option>
<option value={1998}>1998</option>
<option value={1999}>1999</option>
<option value={2000}>2000</option>
<option value={2001}>2001</option>
<option value={2002}>2002</option>
<option value={2003}>2003</option>
<option value={2004}>2004</option>
<option value={2005}>2005</option>
<option value={2006}>2006</option>
<option value={2007}>2007</option>
<option value={2008}>2008</option>
<option value={2009}>2009</option>
<option value={2010}>2010</option>
<option value={2011}>2011</option>
<option value={2012}>2012</option>
<option value={2013}>2013</option>
<option value={2014}>2014</option>
<option value={2015}>2015</option>
<option value={2016}>2016</option>
<option value={2017}>2017</option>
<option value={2018}>2018</option>
<option value={2019}>2019</option>
<option value={2020}>2020</option>
<option value={2021}>2021</option>
<option value={2022}>2022</option>
<option value={2023}>2023</option>
<option value={2024} selected>2024</option>
</select>
</div>
</div>
{/* nested col end */}

{/* nested col start */}
<div class="col-md-6">
<label>Time Of Birth (24 Hour Format)</label>
<div class="tob-inputs">

<select  class="form-select" name="hours" required>
<option disabled>Hrs.</option>
         <option value="0" >0</option>
         <option value="1" >1</option>
         <option value="2" >2</option>
         <option value="3" >3</option>
         <option value="4" >4</option>
         <option value="5" >5</option>
         <option value="6" >6</option>
         <option value="7" >7</option>
         <option value="8" >8</option>
         <option value="9" >9</option>
         <option value="10" >10</option>
         <option value="11" >11</option>
         <option value="12" >12</option>
         <option value="13" selected>13</option>
         <option value="14" >14</option>
         <option value="15" >15</option>
         <option value="16" >16</option>
         <option value="17" >17</option>
         <option value="18" >18</option>
         <option value="19" >19</option>
         <option value="20" >20</option>
         <option value="21" >21</option>
         <option value="22" >22</option>
         <option value="23" >23</option>
         <option value="24" >24</option>
      </select>

<select  class="form-select" name="minutes" required>
<option disabled>Mins.</option>
         <option value="0"  >0</option>
         <option value="1"  >1</option>
         <option value="2"  >2</option>
         <option value="3"  >3</option>
         <option value="4"  >4</option>
         <option value="5"  >5</option>
         <option value="6"  >6</option>
         <option value="7" selected >7</option>
         <option value="8"  >8</option>
         <option value="9"  >9</option>
         <option value="10"  >10</option>
         <option value="11"  >11</option>
         <option value="12"  >12</option>
         <option value="13"  >13</option>
         <option value="14"  >14</option>
         <option value="15"  >15</option>
         <option value="16"  >16</option>
         <option value="17"  >17</option>
         <option value="18"  >18</option>
         <option value="19"  >19</option>
         <option value="20"  >20</option>
         <option value="21"  >21</option>
         <option value="22"  >22</option>
         <option value="23"  >23</option>
         <option value="24"  >24</option>
         <option value="25"  >25</option>
         <option value="26"  >26</option>
         <option value="27"  >27</option>
         <option value="28"  >28</option>
         <option value="29"  >29</option>
         <option value="30"  >30</option>
         <option value="31"  >31</option>
         <option value="32"  >32</option>
         <option value="33"  >33</option>
         <option value="34"  >34</option>
         <option value="35"  >35</option>
         <option value="36"  >36</option>
         <option value="37"  >37</option>
         <option value="38"  >38</option>
         <option value="39"  >39</option>
         <option value="40"  >40</option>
         <option value="41"  >41</option>
         <option value="42"  >42</option>
         <option value="43"  >43</option>
         <option value="44"  >44</option>
         <option value="45"  >45</option>
         <option value="46"  >46</option>
         <option value="47"  >47</option>
         <option value="48"  >48</option>
         <option value="49"  >49</option>
         <option value="50"  >50</option>
         <option value="51"  >51</option>
         <option value="52"  >52</option>
         <option value="53"  >53</option>
         <option value="54"  >54</option>
         <option value="55"  >55</option>
         <option value="56"  >56</option>
         <option value="57"  >57</option>
         <option value="58"  >58</option>
         <option value="59"  >59</option>
      </select>

<select  class="form-select" name="seconds" required>
<option disabled>Secs.</option>
         <option value="0"  >0</option>
         <option value="1"  >1</option>
         <option value="2"  >2</option>
         <option value="3"  >3</option>
         <option value="4"  >4</option>
         <option value="5"  >5</option>
         <option value="6"  >6</option>
         <option value="7"  >7</option>
         <option value="8"  >8</option>
         <option value="9"  >9</option>
         <option value="10"  >10</option>
         <option value="11"  >11</option>
         <option value="12"  >12</option>
         <option value="13"  >13</option>
         <option value="14"  >14</option>
         <option value="15"  >15</option>
         <option value="16"  >16</option>
         <option value="17"  >17</option>
         <option value="18"  >18</option>
         <option value="19"  >19</option>
         <option value="20"  >20</option>
         <option value="21"  >21</option>
         <option value="22"  >22</option>
         <option value="23"  >23</option>
         <option value="24"  >24</option>
         <option value="25"  >25</option>
         <option value="26"  >26</option>
         <option value="27"  >27</option>
         <option value="28"  >28</option>
         <option value="29"  >29</option>
         <option value="30"  >30</option>
         <option value="31"  >31</option>
         <option value="32"  >32</option>
         <option value="33"  >33</option>
         <option value="34"  >34</option>
         <option value="35"  >35</option>
         <option value="36"  >36</option>
         <option value="37"  >37</option>
         <option value="38"  >38</option>
         <option value="39"  >39</option>
         <option value="40"  >40</option>
         <option value="41"  >41</option>
         <option value="42"  >42</option>
         <option value="43"  >43</option>
         <option value="44"  >44</option>
         <option value="45"  >45</option>
         <option value="46"  >46</option>
         <option value="47"  >47</option>
         <option value="48"  >48</option>
         <option value="49"  >49</option>
         <option value="50"  >50</option>
         <option value="51"  >51</option>
         <option value="52"  >52</option>
         <option value="53"  >53</option>
         <option value="54"  >54</option>
         <option value="55" selected >55</option>
         <option value="56"  >56</option>
         <option value="57"  >57</option>
         <option value="58"  >58</option>
         <option value="59"  >59</option>
      </select>
</div>
</div>
{/* nested col end */}

{/* nested col start */}

<div className="col-md-12">
  <label>Place Of Birth</label>
  <div className="placeOfBirthInputDiv">
    <input type="text" className="form-control" placeholder="Place Of Birth *" name="PlaceOfBirth" id="placeOfBirth" required autoComplete="off" />
    <input type="hidden" name="city_id" id="city_id" defaultValue required />
    <div className="placeOfBirthList scroll-style" />
  </div>
</div>


  {/* nested col end */}

  {/* nested col start */}
  <div className="col-12">
  <div className="horo-btn">
    <button type="submit" name="generate">Generate Report</button>
  </div>
</div>


{/* nested col end */}
</div>
{/* nested row end */}
</div>
</form>
</div>
{/* col end */}
</div>
{/* row end */}

{/* row start */}
<div className="row">
  {/* col start */}
  <div className="col-lg-12 col-md-12 col-sm-12 col-12">
  <div className="lalkitab_txt_div">
<h4>Lal Kitab : Kundli Gun Milan</h4>
<h5>A friend or a foe?</h5>
<p>Every human being who walks this earth brings with themselves their own set of energies. And each of these energy fields is governed by certain planets and zodiac signs. As a whole different person, the energy field that you entertain ought to be completely different from others. Or to simplify, your energies might not be compatible with others, and this what makes you and any other person you meet, different. Thus, here is when Kundli matching, also called kundli milan or horoscope matching or kundali gun milan, comes to your rescue. Kundli matching, as an ancient science, allows us to witness if the energies we entail match or complement with the energies of our partner or the one we plan to tie the knot with. To explain, Kundli Milan (match making) gives us an insight into how compatible the two people are with/for each other, as compatibility is one of the most important traits that keeps two people together in the long run. Horoscope matching, also known as Kundli matching in Vedic astrology, considers both; the position of the planets at the time of your birth and their current positions to find how compatible two people are for each other. Kundli matching is necessary to find if the position of the planets is likely or unlikely. For example, if Rahu is poised negatively (Rahu Mahadasha) in one’s Rashi, it is not the best time for them to get married. Similarly, the Kundli matching process can help find if the girl or the boy is Mangalik or not? And in case one of them is, your kundli helps in highlighting how one’s Managalik dosha will affect the other person.</p>
<h6>Kundli Milan - A Friend Or A Foe?</h6>

<p>However, the world, in recent times, has grown up to develop a very wrong perception about Kundli matching (match making). Couples, especially the ones who dream of a love marriage, find Kundli matching as a hurdle in their relationship. Many of them are scared of the questions that would arise if their kundlis don't match and thus find ways to skip the gun milan ritual. However, this perception of the couples about kundli matching is nothing but merely wrong facts aka half baked knowledge that have been forced into their minds. Honestly speaking, astrology never works as a bone of contention for your relationship. Instead, if you ever talk to an astrologer and ask them about your relationship, they will only introduce you to ways using which you can better your relationship. Our astrologers over the years have bumped into multiple instances where the couple really wanted to get married but their kundli was not matching. In such situations, our astrologers, being understanding enough, relied on sharing the best advice around how such couples shall support each other after marriage to harness a sound relationship even if their kundlis don’t match. Astrologers also shared with them the remedies that they can practice to make up for the lack of compatibility. And today these couples tell us how it has all worked fine for them.</p>
</div>
  </div>
  {/* col end */}

  {/* col start */}
  <div className="col-lg-12 col-md-12 col-sm-12 col-12">
    <div className="lalkitab_faq_txt_div">
      <h3>Horoscope Matching - FAQs </h3>
      <hr />

      <b>Q 1. What Is Janam Kundli?</b>
      <p>Kundli is a chart prepared in astrology depending on the precise date, place, and time of birth of an individual. It figures out the placement of all the planets and signs, along with the Sun and Moon at the time of your birth. Along with all this, it also shows the other astrological aspects and relevant information about a newborn individual. With all these details, astrologers calculate your ascendant status as well as your rising sign of the native. Also, it provides a diligent insight into how a person would become, how would you do in your life—future, and how your life’s scenario is in the present.</p>

      <b>Q 2. What Is Dasamsa Or D10 Chart In Kundli Reading? </b>
      <p>A sign is separated into ten uniform parts to create the D10 chart in astrology. The Dasamsa chart is prepared to depend upon the 10th distribution of the zodiac sign. If the Kundli analysis is done using the Dasamsa chart, it lets you look into your professional accomplishments and success with utmost detail.</p>

      <b>Q 3. What Is Dasha In Kundli Analysis?</b>
      <p>Dasha is a vital phase of a planet in the life of the native. And according to astrology, there are 43 different types of Dasha systems. When the planet is powerful or settled in its exaltation sign, the Dasha is known as the Poorna Dasha. However, if the planet is powerless or in poor condition, the Dasha is recognized as the Rikta Dasha.</p>

      <b>Q 4. How To Know Mahadasha In Kundli?</b>
      <p>There is a specific procedure in Vedic astrology to find out the Mahadasha period. According to it, 3 Nakshatras are proportioned out to each planet, which makes the number of Nakshatras count to 27 for all the nine planets. So, the Mahadasha of any planet depends on the position of the Moon in a particular Nakshatra.</p>

      <b>Q 5. What Are Yogas In Kundli Reading?</b>
      <p>Yogas are the positive/negative influences of the planets that are present in the horoscope or birth chart of an individual. It could be both auspicious as well as inauspicious. The inauspicious ones are known as the Dosha and generally impact the life of the person in a negative manner.</p>

      <b>Q 6. What Are The Signs Of Mangal Dosh?</b>
      <p>Signs of Mangal Dosh, also known as Manglik Dosha, are believed to include certain astrological conditions where the planet Mars (Mangal) has a malefic influence. Individuals with Mangal Dosh are often advised to consider matching their Kundlis with someone else having the same dosha or perform remedies to mitigate its effects for a successful marriage.</p>
    </div>
  </div>
  {/* col end */}
</div>
{/* row end */}
</div>
</section>
{/*  */}

{/*  */}
<section className="kundli_astro_slider_sec">
  <div className="container">
    {/*  */}
    <div className="kundli_as_slider_hd">
      <h2> Complimentary astrology services</h2>
      <hr />
    </div>
    {/*  */}
    <div className="row">
      <div className="col-lg-12">
        {/*  */}
          <div className="complimentary_astrology_service">
          <OwlCarousel className="owl-theme" {...complimentaryastrology}>

{/* item div start */}
 {/*  */}

            <div className="item">
            <Link to="">
              <div className="kundli_main_div_sld">
                <div className="kundli_img_box_astro">
                  <img src="../images/ser4.png" alt />
                </div>
                <h5>Today's Horoscope</h5>
                <p> Unsure about how your day will unfold? Get free Aries horoscope online prediction from top astrologer. Read your Aries Zodiac Sign Horoscope today! </p>
              </div>
              </Link>
            </div>
          
          {/*  */}
          {/*  */}
         
            <div className="item">
            <Link to="">
              <div className="kundli_main_div_sld">
                <div className="kundli_img_box_astro">
                  <img src="../images/ser1.png" alt />
                </div>
                <h5>Free Kundli</h5>
                <p>  Generate your free online kundli report from Talk nd heal. Our Kundli software can help you predict the future for yourself by reading the birth chart. </p>
              </div>
              </Link>
            </div>
         
          {/*  */}
          {/*  */}
          
            <div className="item">
            <Link to="">
              <div className="kundli_main_div_sld">
                <div className="kundli_img_box_astro">
                  <img src="../images/ser2.png" alt />
                </div>
                <h5>Kundli Matching</h5>
                <p> Check Love Compatibility and Marriage Prediction online at Talk nd heal. Get the best Horoscope and kundli matching predictions today! </p>
              </div>
              </Link>
            </div>
         
          {/*  */}
          {/*  */}
        
            <div className="item">
            <Link to="">
              <div className="kundli_main_div_sld">
                <div className="kundli_img_box_astro">
                  <img src="../images/cta4.png" alt />
                </div>
                <h5>Today Panchang</h5>
                <p> Panchang enlists a record of auspicious dates and times for any auspicious task such as marriage, celebration, puja, starting any business etc. </p>
              </div>
              </Link>
            </div>
         
          {/*  */}
{/* item div end */}

</OwlCarousel>
</div>  
       
      </div>
    </div>
  </div>
</section>
{/*  */}
<Footer/>
    </>
  )
}

export default LalKitab
