import CryptoJS from 'crypto-js';
import Cookies from 'js-cookie';



// export const  cookieDomain = '.healfate.com'; 
export const cookieDomain = '';
// ,

//   "proxy": "https://healfate.com/custom_api"
// export const domainName=`https://varta.healfate.com/`;
// export const Domain_Url=`https://varta.healfate.com/`;

export const domainName = `http://localhost:3001` 

export const Domain_Url=`http://localhost:3001`



// Secret pass key for encryption/decryption
const SECRET_KEY = 'reacttalkndhealhealfate';

// Function to encrypt data
export const encryptData = (data) => {
  try {
    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
    return encrypted;
  } catch (error) {
    console.error('Encryption failed', error);
    return null;
  }
};

// Function to set encrypted data in a cookie
export const setEncryptedCookie = (key, data) => {
  try {
    const encryptedData = encryptData(data);
    if (encryptedData) {
      Cookies.set(key, encryptedData, { expires: 7 }); // Cookie expires in 7 days
    } else {
      console.error('Failed to encrypt data');
    }
  } catch (error) {
    console.error('Failed to set cookie', error);
  }
};





 // Function to decrypt data
export const decryptData = (encryptedData) => {
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
    const decrypted = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decrypted;
  } catch (error) {
    console.error('Decryption failed', error);
    return null;
  }
};

// Function to get and decrypt data from a cookie
export const getDecryptedCookie = (key) => {
  try {
    const encryptedData = Cookies.get(key);
    if (encryptedData) {
      return decryptData(encryptedData);
    }
    return null;
  } catch (error) {
    console.error('Failed to get or decrypt cookie', error);
    return null;
  }
};


  export const isAuthenticated=Cookies?.get('token')
  export const isUserId=Cookies?.get('user')