import React from 'react'
import { Link } from 'react-router-dom'
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../CssFiles/Userprofile.css';

const Userprofile = () => {
  return (
    <>
     {/* ========= */}
  <Header/>
    {/* ====== */}
      {/* userprofile section start */}
      <section className="userprofile_section">
        <div className="container">
            {/* row start */}
            <div className="row">
                {/* col start */}
                <div className="col-lg-4 col-md-5 col-sm-12 col-12">
                    <div className="userprofile_img_box">
                        <img src="../images/userimg01.png" alt="" className='img-fluid'/>
                    </div>
                </div>
                {/* col end */}

                  {/* col start */}
                  <div className="col-lg-8 col-md-7 col-sm-12 col-12">
                    <div className="userprofile_details_main_div">
                        {/*  */}
                        <div className="user_profile_name_div">
                            <h1>Rinku Singh</h1>
                            <Link to="">Edit</Link>
                        </div>
                        {/*  */}
                        {/* user profile dt table start */}
                       <div className="userprofile_table_main_div">
                       <table class="table table-bordered table-striped">    
                            <tr>
                                <th>Mobile Number:</th>
                                <td>+91 9634491321 </td>
                            </tr>
                             <tr>
                                <th>Email:</th>
                                <td> </td>
                            </tr>
                             <tr>
                                <th>date of birth:</th>
                                <td>1/1/1900</td>
                            </tr>
                             <tr>
                                <th>time of birth:</th>
                                <td>0:0:0</td>
                            </tr>
                             <tr>
                                <th>place of birth:</th>
                                <td>up</td>
                            </tr>
                             <tr>
                                <th>gender:</th>
                                <td>male</td>
                            </tr>
                             <tr>
                                <th>Marital Status:</th>
                                <td>Single</td>
                            </tr>
                             <tr>
                                <th>Occupation:</th>
                                <td>developer</td>
                            </tr>
                        </table>
                       
                       <p>talk to our astrologers now !!!</p>
                       </div>
                        {/* user profile dt table end */}

                        <div class="user_btn_div">
                        <Link to="" class="call"><i class="fas fa-phone"></i> Call</Link>
                        <Link to="" class="chat"><i class="fas fa-message"></i> Chat</Link>
                        <Link to="" class="call"><i class="fas fa-mobile"></i> Video </Link>
                     </div>
                    </div>
                    {/**/}
<div className="col-lg-6 col-md-12 col-sm-12 col-12">
  <div className="user_profile_table">
    <h3>My Following</h3>
    <a href="https://varta.healfate.com/astrologersDetails/10010">
    </a><div className="profile-user_m_div_f"><a href="https://varta.healfate.com/astrologersDetails/10010">
        <div className="pro_file_icon_img_user">
          <img src="https://healfate.com/astro_docs//7301image (18).png" className="img-fluid" />
          <div className="profle_name_div">
            <h4>Acharya Shashi</h4>
            <span>Yoga mudras,  Pranayama,  Dietician,  Va</span> 
          </div>
        </div>
      </a><div className="pro_file_dots"><a href="https://varta.healfate.com/astrologersDetails/10010">
        </a><li className="dropdown"><a href="https://varta.healfate.com/astrologersDetails/10010">
          </a><a className="fa fa-ellipsis-v" href="#" data-bs-toggle="dropdown" />
          <ul className="dropdown-menu">
            <li><a className="dropdown-item" href="user-profile?followid=NjQ=">Unfollow</a></li> 
          </ul>
        </li>
      </div>
    </div>
  </div>
</div>
{/**/}

                  </div>
                {/* col end */}

               
            </div>
            {/* row end */}
        </div>
      </section>
      {/* userprofile section end */}
<Footer/>
    </>
  )
}

export default Userprofile
