import React from 'react'
import { Link } from 'react-router-dom'
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../CssFiles/Ticket.css';

const Ticket = () => {
  return (
    <>
     {/* ========= */}
  <Header/>
    {/* ====== */}
      {/* ticket section start */}
      <section className="ticket_section">
  <div className="container">
    {/*  */}
    <div className="ticket_title_div">
      <h2>My Tickets</h2>
    </div>
    {/*  */}
    <div className="row">
      {/* col start  */}
      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
        {/* main div start */}
        <div className="ticket_tab-main_div">
          {/* nav tabs start */}
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button className="nav-link active" id="all-tab" data-bs-toggle="tab" data-bs-target="#all-tab-pane" type="button" role="tab" aria-controls="all-tab-pane" aria-selected="true">All</button>
            </li>
            <li className="nav-item" role="presentation">
              <button className="nav-link" id="open-tab" data-bs-toggle="tab" data-bs-target="#open-tab-pane" type="button" role="tab" aria-controls="open-tab-pane" aria-selected="false">Open</button>
            </li>
            <li className="nav-item" role="presentation">
              <button className="nav-link" id="ans-tab" data-bs-toggle="tab" data-bs-target="#ans-tab-pane" type="button" role="tab" aria-controls="ans-tab-pane" aria-selected="false">Answered</button>
            </li>
            <li className="nav-item" role="presentation">
              <button className="nav-link" id="close-tab" data-bs-toggle="tab" data-bs-target="#close-tab-pane" type="button" role="tab" aria-controls="close-tab-pane" aria-selected="false">Closed</button>
            </li>
            <li className="nav-item" role="presentation">
              <Link to="/generateticket" className="nav-link">Create Ticket</Link>
            </li>
          </ul>
          {/* nav tabs end */}
          <div className="tab-content" id="myTabContent">
            {/* all start tab */}
            <div className="tab-pane fade show active" id="all-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex={0}>
              <div className="table_div_tabs">
                <table className="table table-striped table-bordered">
                  <tbody><tr>
                      <th>S.No.</th>
                      <th>Subject</th>
                      <th>Created</th>
                      <th>Updated</th>
                      <th>Status</th>
                      <th>Re-Open</th>
                    </tr>
                    <tr><td colSpan={6}>Data not found</td>                 
                    </tr></tbody></table>
              </div>
            </div>
            {/* all end tab */}
            {/* open start tab */}
            <div className="tab-pane fade" id="open-tab-pane" role="tabpanel" aria-labelledby="open-tab" tabIndex={0}>
              <div className="table_div_tabs">
                <table className="table table-striped table-bordered">
                  <tbody><tr>
                      <th>S.No.</th>
                      <th>Subject</th>
                      <th>Created</th>
                      <th>Updated</th>
                      <th>Status</th>
                      <th>Re-Open</th>
                    </tr>
                    <tr><td colSpan={6}>Data not found</td> 
                    </tr></tbody></table>
              </div>
            </div>
            {/* open end tab */}
            {/* answered start tab */}
            <div className="tab-pane fade" id="ans-tab-pane" role="tabpanel" aria-labelledby="ans-tab" tabIndex={0}>
              <div className="table_div_tabs">
                <table className="table table-striped table-bordered">
                  <tbody><tr>
                      <th>S.No.</th>
                      <th>Subject</th>
                      <th>Created</th>
                      <th>Updated</th>
                      <th>Status</th>
                      <th>Re-Open</th>
                    </tr>
                    <tr><td colSpan={6}>Data not found</td>              </tr></tbody></table>
              </div>
            </div>
            {/* answered end tab */}
            {/* close start tab */}
            <div className="tab-pane fade" id="close-tab-pane" role="tabpanel" aria-labelledby="close-tab" tabIndex={0}>
              <div className="table_div_tabs">
                <table className="table table-striped table-bordered">
                  <tbody><tr>
                      <th>S.No.</th>
                      <th>Subject</th>
                      <th>Created</th>
                      <th>Updated</th>
                      <th>Status</th>
                      <th>Re-Open</th>
                    </tr>
                    <tr><td colSpan={6}>Data not found</td>              </tr></tbody></table>
              </div>
            </div>
            {/* close end tab */}
          </div>
        </div>
        {/* main div end tab */}
      </div>
      {/* col end */}
    </div>
  </div>
</section>
<Footer/>
      {/* ticket section end */}
    </>
  )
}

export default Ticket
