import React, { useState, useEffect, useContext } from 'react';
import './Header.css';
import { Link } from 'react-router-dom';
import Sidenavbar from './Sidenavbar';
import Searchcomponent from './Searchcomponent';
import { allServicesApi } from '../data/AllapiData';
import Login from './Login/Login';
import { decryptData, Domain_Url, getDecryptedCookie, isAuthenticated, isUserId } from './Url/Url';
import MyContext from '../Context/MyContext';
import { getUserDetailsApi } from '../AllApi/AllApi';
import { decrypt } from '../Utils/Utils';
import Cookies from 'js-cookie';


const Header = () => {
const [isOpenLoginModal, setIsOpenLoginModal] = useState(false);
const [userDetailsData, setUserDetailsData] = useState(false);
console.warn(userDetailsData)


async function userDetails() {
  try {
    // Get the token and user ID from cookies or storage
    const decToken = getDecryptedCookie('token'); // Adjust to your method
    const userId = getDecryptedCookie('user'); // Adjust to your method

    console.error('Decrypted Token:', decToken);
    console.error('Decrypted User ID:', userId);

    // Validate that both token and userId are defined
    if (!decToken || !userId) {
      throw new Error('Token or User ID is missing');
    }

    // Fetch user details
    const res = await getUserDetailsApi(decToken, userId);
    setUserDetailsData(res);
  } catch (error) {
    console.error('Error in userDetails function:', error.message);
  }
}

useEffect(() => {
  userDetails();
}, []);




function  handleLogOut(){
  Cookies?.remove('token')
  window.location?.reload()



}
const  [allServicesData,setAllServicesData]=useState()
async function fetchAllServices(){
const res=await allServicesApi()
if(res?.success==true)
{ setAllServicesData(res?.data)


}
}
useEffect(()=>{
fetchAllServices()
},[])

function toggleOpenLoginModal() {
setIsOpenLoginModal(!isOpenLoginModal);
}

// top bar chat, call, video 
const texts = [
{ text: "Call With Astrologer", link: "/call" },
{ text: "Chat With Astrologer", link: "/chat" },
{ text: "Videocall With Astrologer", link: "/video" }
];
function modifySpaceToDash(name) {
// Replace spaces with hyphen
return name.replace(/\s+/g, '-');
}
const [index, setIndex] = useState(0);

useEffect(() => {
const intervalId = setInterval(() => {
setIndex((prevIndex) => (prevIndex + 1) % texts.length);
}, 2000); // Change text every 2 seconds

return () => clearInterval(intervalId); // Cleanup on unmount
}, []);


const [showButton, setshowButton] = useState(false);
const webScroll = () =>{
if(window.scrollY > 100){
setshowButton(true);
}

else{
setshowButton(false);
}
};

useEffect(() => {
window.addEventListener('scroll', webScroll);
return () => {
window.removeEventListener('scroll', webScroll);
};
}, []);
// scroll website then button show end


return (
<>
{/*  */}
<div className="alert_msg_main_div">
<div className="alert alert-warning alert-dismissible fade show" role="alert">
<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" />
<div className="msg_alert_div">
<strong>TalkndHeal App</strong>
<Link to="">Download Now</Link>
</div>   
</div>
</div>
{/*  */}


{/* top bar section start */}
<section className="top_bar_section">
<div className="container">
<div className="row">
{/* col start */}
<div className="col-lg-12 col-12">
<div className="top_left_flexdiv">
{/*  */}
<div className="top_left_links">
<Link to="/freekundali" title="Free Kundali Software & Kundali Matching">Kundli</Link>
{/*<a to="yearlyHoroscope">Horoscope 2024</a>*/}
<Link to="/horoscope/today-horoscope">Today's Horoscope</Link>
<Link to="/panchang">Panchang</Link>
</div>
{/*  */}
{/*  */}
<div className="top_middle_chat_box">

<Link to={texts[index].link}>
<span className="fa fa-comments" />
<span id="content-container">
<b id="content"> {texts[index].text} </b>
</span>
<img src={`${Domain_Url}/images/ic_online.gif`} alt="Online Status" className="img-fluid" />
</Link>

<span className="wallet_div">
<Link to="#">
<span className="wallet_img_box">
<img src={`${Domain_Url}/images/wallet-2.png`} alt />
<span> ₹ </span>
</span>
<span className="amount_div" >53,299</span>
</Link>
</span>



<span className="share_btn"><Link className="fa fa-share" /></span>
</div>
{/*  */}

<div className="top_signin_btn_div">
{!isAuthenticated? <Link to=""className='' onClick={toggleOpenLoginModal}>SIGN IN/ SIGN UP</Link>:
<>
<Link  to="#" role="button" id="dropdownMenuLink" class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"> {userDetailsData?.data?.name   }</Link>
<ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
<li><Link class="dropdown-item" to="/userprofile">Profile</Link></li>
<li><Link class="dropdown-item" to="/notifications">Notification</Link></li>
<li><Link class="dropdown-item" to="/payment">Wallet Transactions</Link></li>
<li><Link class="dropdown-item" to="/orderhistory">Order History</Link></li>
<li><Link class="dropdown-item" to="/ticket">Customer Support</Link></li>
<li><Link class="dropdown-item" to="/blockedastro">Reported Astrologers</Link></li> 
<li onClick={handleLogOut}><Link class="dropdown-item">Sign Out</Link></li>
</ul>
</>}
</div>
{/*  */}
</div>
</div>
{/* col end */}
</div>
</div>
</section>
{/* top bar end */}
{/* logo navbar section start */}
<div className="header_wrapper_div_main">
<section className="logo_navbar">
<div className="container">
<div className="row">
{/* col start */}
<div className="col-lg-12 col-12">
<div className="logo_navr_main_div">
{/*  */}
<div className="logo_div">
<Link to="/"><img src={`${Domain_Url}/logo/logo.png`}  alt className="img-fluid" /></Link>
</div>
{/*  */}

{/*  */}
<div className="logo_navlinks">



<Link to="/services/astrology">Astrology</Link>
<Link to="/services/tarot">Tarot</Link>
<Link to="/services/psychologist">Psychologist</Link>
<Link to="/services/kp-system">KP System</Link>
<Link to="/services/lalKitab">Lal Kitab</Link>
<Link to="/services/palmistry">Palmistry</Link>
<Link to="/services/vastu">Vastu</Link>
<Link to="/services/dietician">Dietician</Link>
<Link to="/services/pranayama">Pranayama</Link>
<Link to="/services/yogamudras">Yoga mudras</Link>
<Link to="/services/sun-rays-healing">Sun Rays Healing</Link>
<Link to="/services/sound-and-words-healing">Sound and Words Healing</Link>
<Link to="/services/crystal-healing">Crystal Healing</Link>
<Link to="/services/acupressure-healing">Acupressure Healing</Link>
<Link to="/services/magnet-healing">Magnet Healing</Link>
<Link to="/services/nature-healing">Nature Healing</Link>
<Link to="/services/metal-healing">Metal Healing</Link>


{/* // top bar chat, call, video end
//     
//       
// Healing 
//  Healing Healing Healing Healing
// scroll website then button show */}

</div>
<div className="sign_in_btn" style={{ display: showButton ? 'block' : 'none' }}>
{/* <Link to="" data-bs-toggle="modal" data-bs-target="#login">SIGN IN/ SIGN UP</Link> */}
{/* <Link  to="#" role="button" id="dropdownMenuLink" class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" onClick={toggleOpenLoginModal}> SIGN IN/ SIGN UP</Link> */}
<Link  class="dropdown-toggle"  > SIGN IN/ SIGN UP</Link>
<ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
<li><Link class="dropdown-item" to="/userprofile">Profile</Link></li>
<li><Link class="dropdown-item" to="/notifications">Notification</Link></li>
<li><Link class="dropdown-item" to="/payment">Wallet Transactions</Link></li>
<li><Link class="dropdown-item" to="/orderhistory">Order History</Link></li>
<li><Link class="dropdown-item" to="/ticket">Customer Support</Link></li>
<li><Link class="dropdown-item" to="/blockedastro">Reported Astrologers</Link></li> 
<li><Link class="dropdown-item" to="#" >Sign Out</Link></li>
</ul>
</div>
{/*  */}
<div className="navbar_button_div">
<span className="navbar_wallet_div">
<Link to="/wallet">
<span className="navbar_wallet_img_box">
<img src="../images/wallet.svg" alt />
<span> ₹ </span>
</span>
<span className="navbar_amount_div">5k</span>
</Link>
</span>

<i className="mobile_search_icon" aria-hidden="true"> <img src="../images/search.svg" alt="search" /></i>
<button className="fa fa-bars" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions" aria-controls="offcanvasWithBothOptions" />
{/*  */}

</div>

</div>
</div>
{/* col end */}
</div>
</div>
{/*  */}

</section>
{/* logo navbar section end */}
{/* navbar section */}
<section className="navbar_section_navbar">
<nav className="navbar navbar-expand-lg bg-light">
<div className="container">
<div className="collapse navbar-collapse" id="navbarNavDropdown">
<ul className="navbar-nav navbar-childs">
<li className="nav-item"><Link className="nav-link active" aria-current="page" to="/">Home</Link></li>
<li className="nav-item"><Link className="nav-link" to="/freeKundali">Kundli</Link></li>
<li className="nav-item"><Link className="nav-link" to="/kundliMatching">Kundli Matching</Link></li>
{/* mega menu */}
<li className="nav-item dropdown">
<Link className="nav-link" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">Horoscopes <span className="fa fa-angle-down" /></Link>
<ul className="dropdown-menu">
<Link to="/horoscope/today-horoscope">Today's Horoscope</Link>
<Link to="/horoscope/tomorrow-horoscope">Tomorrow's Horoscope</Link>
<Link to="/horoscope/monthly-horoscope">Monthly Horoscope</Link>
</ul>
</li>
{/* mega menu end*/}
<li className="nav-item"><Link className="nav-link" to="/panchang">Panchang</Link></li>
<li className="nav-item"><Link className="nav-link" to="/chat">Chat with Astrologer</Link></li>
<li className="nav-item"><Link className="nav-link" to="/call">Call with Astrologer</Link></li>
<li className="nav-item"><Link className="nav-link" to="/video">Video call with Astrologer</Link></li>
<li className="nav-item"><Link className="nav-link" to="/joinAsastrologer">Join As An Astrologer</Link></li>
<li className="nav-item"><Link className="nav-link" to="/samuhikpuja">Samuhik Puja</Link></li>
</ul>
</div>
</div>
</nav>
{/*  */}
<div className="serachicon_div">
<div className="list_main_div">
<Searchcomponent />
<div class="suggestion_list">
<ul>
<li><Link to="">Dietician</Link></li>
<li><Link to="">Dietician</Link></li>
<li><Link to="">Dietician</Link></li>
<li><Link to="">Dietician</Link></li>
<li><Link to="">Dietician</Link></li>
<li><Link to="">Dietician</Link></li>
<li><Link to="">Dietician</Link></li>
<li><Link to="">Dietician</Link></li>
<li><Link to="">Dietician</Link></li>
<li><Link to="">Dietician</Link></li>
<li><Link to="">Dietician</Link></li>
<li><Link to="">Dietician</Link></li>
<li><Link to="">Dietician</Link></li>
<li><Link to="">Dietician</Link></li>
<li><Link to="">Dietician</Link></li>
<li><Link to="">Dietician</Link></li>
<li><Link to="">Dietician</Link></li>
<li><Link to="">Dietician</Link></li>
<li><Link to="">Dietician</Link></li>
</ul>
</div>
</div>
</div>

{/*  */}
</section>
</div>
{/* navbar section end */}


{/*  */}
<Sidenavbar/>
{/*  */}

{isOpenLoginModal && <Login closeModal={toggleOpenLoginModal} />}
</>
)
}

export default Header



